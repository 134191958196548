// For UI
export const paths = {
  globalListPath: "/company-database",
  globalSettingPath: "/setting",
  afterSignUpAndSignInPath: "/company-database",
  signInPath: "/signin",
  authConfirmationCompletePath: "/auth_confirmation_complete",
  thanksForRegistrationPath: "/thanks_for_registration",
  superAdminPath: "/super_admin",
  productsPath: "/products",
  teamsPath: "/teams",
  generativeContents: "/generative_contents",
}

// For API
export const apiPaths = {
  generativeContentResponsePath: "/generative_content_responses",
  generativeContentResponseImagesPath: "/generative_content_response_images",
  promptsPath: "/prompts",
  categoriesPath: "/categories",
  userVariableInputsPath: "/user_variable_inputs",
  instagramAccountMediaPath: "/instagram_account_medias",
  instagramAccountMediaInsightPath: "/instagram_account_media_insights",
  instagramAccountMediaStoryInsightPath:
    "/instagram_account_media_story_insights",
}

export const getTeamApiPath = (teamId: string) => {
  return `${paths.teamsPath}/${teamId}`
}

export const putTeamApiPath = (teamId: string) => {
  return `${paths.teamsPath}/${teamId}`
}

export const putProductApiPath = (productId: string) => {
  return `${paths.productsPath}/${productId}`
}

export const deleteProductApiPath = (productId: string) => {
  return `${paths.productsPath}/${productId}`
}

export const updatePasswordApiPath = "auth/user/users/update_password"

export const updateProfileApiPath = (profileId: string) => {
  return `profiles/${profileId}`
}

export const deleteGenerativeContentResponse = (id: string) => {
  return `${apiPaths.generativeContentResponsePath}/${id}`
}

export const deleteGenerativeContentResponseImages = (id: string) => {
  return `${apiPaths.generativeContentResponseImagesPath}/${id}`
}

export const updateGenerativeContentResponse = (id: string) => {
  return `${apiPaths.generativeContentResponsePath}/${id}`
}
