import { useNavigate, useLocation } from "react-router-dom"
import { useCallback, useEffect } from "react"
import { getCurrentUser } from "@/api/auth"
import { useCurrentUser } from "@/hooks/useCurrentUser"
import { UserType } from "@/types/UserType"
import { isLoginPage } from "@/utils/auth"
import { useSetRecoilState } from "recoil"
import { loadingAtom } from "@/atoms/LoadingAtom"
import { currentUserAtom } from "@/atoms"

interface Props {
  isSignedIn: boolean
  onCheck: (checked: boolean, currentUser?: UserType) => void
}

export const CheckLogin = (props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { reload } = useCurrentUser()
  const setIsLoading = useSetRecoilState(loadingAtom)
  const setCurrentUser = useSetRecoilState(currentUserAtom)

  const handleCheck = useCallback(
    (checked: boolean, userInfo?: UserType) => {
      props.onCheck(checked, userInfo)
    },
    [props]
  )

  const redirectToLoginPage = useCallback(
    (callback?: string) => {
      navigate("/signin")
      handleCheck(false)
    },
    [handleCheck, navigate]
  )

  const redirectToFirstPage = useCallback(async () => {
    navigate("company-database")
  }, [navigate])

  const succeeded = useCallback(
    (currentUser: UserType) => {
      if (isLoginPage(location.pathname)) {
        redirectToFirstPage()
        handleCheck(false, currentUser)
      } else {
        handleCheck(true, currentUser)
      }
    },
    [handleCheck, redirectToFirstPage, location]
  )

  const check = useCallback(async () => {
    setIsLoading(true)
    const callback = isLoginPage(location.pathname)
      ? undefined
      : window.location.href

    await getCurrentUser()
      .then((response) => {
        setCurrentUser(response.data.user)
        succeeded(response.data.currentUser)
      })
      .catch((_error) => {
        redirectToLoginPage(callback)
      })

    setIsLoading(false)
  }, [
    redirectToLoginPage,
    location.pathname,
    setCurrentUser,
    setIsLoading,
    succeeded,
  ])

  useEffect(() => {
    check()
  }, [reload, check])

  return null
}
