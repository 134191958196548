import { Alert, css } from "@mui/material"
import { useContext } from "react"
import { AlertEnum } from "@/utils/helpers/enums"
import AlertContext from "./AlertProvider"

const GlobalAlert = () => {
  const alert = useContext(AlertContext)

  switch (alert?.alert) {
    case AlertEnum.SUCCESS:
      return (
        <div css={styles.container}>
          <Alert severity="success" color="info" css={styles.alert}>
            {alert?.alertText}
          </Alert>
        </div>
      )
    case AlertEnum.ERROR:
      return (
        <div css={styles.container}>
          <Alert severity="error" color="error" css={styles.alert}>
            {alert?.alertText}
          </Alert>
        </div>
      )
    case AlertEnum.NONE:
      return null
    default:
      throw new Error(`unexpected alert enum name: ${alert?.alert}`)
  }
}

export default GlobalAlert

const styles = {
  container: css({
    position: "relative",
    zIndex: 9999,
  }),
  alert: css({
    position: "fixed",
    right: 20,
    top: 100,
  }),
}
