import React from "react"
import { InstagramAccountInsightType } from "@/types/InstagramAccountInsightType"
import { InstagramAccountFollowerDemographicsType } from "@/types/InstagramAccountFollowerDemographicsType"
import { LineChartDataSets } from "@/utils/helpers/dashboard"
import { WeeklyHourlyOnlineFollowersDataType } from "@/utils/helpers/dashboard"
import { SelectablePeriodType } from "@/utils/helpers/dashboard"
import { Card, Grid, css } from "@mui/material"
import client from "@/api/client"
import { useEffect, useState } from "react"
import { HEADER_HEIGHT } from "@/components/GlobalHeader"
import { PageMainTitle } from "@/components/typography/PageMainTitle"
import { DashboardDateRangePicker } from "@/components/DashboardDateRangePicker"
import { DashboardInsightCards } from "@/components/DashboardInsightCards"
import { DashboardLineChart } from "@/components/DashboardLineChart"
import { DashboardHourlyOnlineFollowersGridCells } from "@/components/DashboardHourlyOnlineFollowersGridCells"
import { DashboardBarChart } from "@/components/DashboardBarChart"
import { DashboardPieChart } from "@/components/DashboardPieChart"
import { DashboardHashTags } from "@/components/DashboardHashTags"
import moment from "moment"
import { CircularProgress } from "@mui/material"
import { HashtagType } from "@/types/HashtagType"

const DashboardContainer: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [latestInstagramAccountInsight, setLatestInstagramAccountInsight] =
    useState<InstagramAccountInsightType | null>(null)

  const [
    latestInstagramAccountFollowerDemographic,
    setLatestInstagramAccountFollowerDemographic,
  ] = useState<InstagramAccountFollowerDemographicsType | null>(null)

  const [weeklyHourlyOnlineFollowersData, setWeeklyHourlyOnlineFollowersData] =
    useState<WeeklyHourlyOnlineFollowersDataType | null>(null)

  const [frequentlyUsedHashtags, setFrequentlyUsedHashtags] = useState<
    HashtagType[]
  >([])

  const [lineChartDataSets, setLineChartDataSets] = useState<LineChartDataSets>(
    {
      followers: [],
      reach: [],
      likes: [],
      saves: [],
      profileViews: [],
      urlClicks: [],
    }
  )

  const [lineChartLabels, setLineChartLabels] = useState<string[]>([])
  const [selectablePeriod, setSelectablePeriod] =
    useState<SelectablePeriodType>({
      oldestTargetOn: null,
      latestTargetOn: null,
    })
  const [startDate, setStartDate] = useState<moment.Moment | null>(null)
  const [endDate, setEndDate] = useState<moment.Moment | null>(null)

  useEffect(() => {
    if (!startDate && !endDate) {
      setIsLoading(true)
    }
    const params = startDate && endDate ? { startDate, endDate } : {}
    client
      .get("/dashboards", { params })
      .then((response) => {
        if (response.status === 200) {
          setSelectablePeriod(response.data.selectablePeriod)
          if (!startDate && !endDate) {
            setStartDate(
              response.data.initialPeriod.startDate
                ? moment(response.data.initialPeriod.startDate)
                : null
            )
            setEndDate(
              response.data.initialPeriod.endDate
                ? moment(response.data.initialPeriod.endDate)
                : null
            )
          }
          setLatestInstagramAccountInsight(response.data.latestInsight)
          setLatestInstagramAccountFollowerDemographic(
            response.data.latestFollowerDemographics
          )
          setWeeklyHourlyOnlineFollowersData(
            response.data.latestWeeklyHourlyOnlineFollowers
          )
          setFrequentlyUsedHashtags(response.data.frequentlyUsedHashtags)
          updateLineChartDataSets(response.data.insightHistories)
          setIsLoading(false)
        }
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [startDate, endDate])

  const updateLineChartDataSets = (
    insightHistories: InstagramAccountInsightType[]
  ) => {
    const formattedData = {
      followers: insightHistories.map((item) => item.followersCount),
      reach: insightHistories.map((item) => item.reachCount),
      likes: insightHistories.map((item) => item.likeCount),
      saves: insightHistories.map((item) => item.saveCount),
      profileViews: insightHistories.map((item) => item.profileViewsCount),
      urlClicks: insightHistories.map((item) => item.urlClicksCount),
    }

    const labels = insightHistories.map((item) => {
      const date = new Date(item.targetOn)
      return `${date.getMonth() + 1}/${date.getDate()}`
    })

    setLineChartDataSets(formattedData)
    setLineChartLabels(labels)
  }

  const isLineChartHasData = Object.values(lineChartDataSets).some(
    (dataset) => dataset.length > 0
  )

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    )
  }
  return (
    <>
      <div css={styles.pageTitleContainer}>
        <div css={styles.pageTitle}>
          <PageMainTitle>ダッシュボード</PageMainTitle>
        </div>
      </div>
      {latestInstagramAccountInsight && (
        <>
          <div css={styles.pageSubTitleContainer}>
            <div css={styles.pageSubTitle}>
              <DashboardDateRangePicker
                selectablePeriod={selectablePeriod}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
          </div>
        </>
      )}
      <div css={styles.pageBodyContainer}>
        {latestInstagramAccountInsight ? (
          <>
            <DashboardInsightCards
              latestInstagramAccountInsight={latestInstagramAccountInsight}
            />
            {isLineChartHasData && weeklyHourlyOnlineFollowersData && (
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Card css={styles.lineChartCard}>
                    <DashboardLineChart
                      chartDataSets={lineChartDataSets}
                      chartLabels={lineChartLabels}
                    />
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card css={styles.heatMapCard}>
                    <DashboardHourlyOnlineFollowersGridCells
                      weeklyHourlyOnlineFollowersData={
                        weeklyHourlyOnlineFollowersData
                      }
                    />
                  </Card>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={3}>
              {latestInstagramAccountFollowerDemographic && (
                <>
                  <Grid item xs={4}>
                    <Card css={styles.barChartCard}>
                      <DashboardBarChart
                        latestInstagramAccountFollowerDemographic={
                          latestInstagramAccountFollowerDemographic
                        }
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card css={styles.pieChartCard}>
                      <DashboardPieChart
                        latestInstagramAccountFollowerDemographic={
                          latestInstagramAccountFollowerDemographic
                        }
                      />
                    </Card>
                  </Grid>
                </>
              )}
              <Grid item xs={5}>
                <Card css={styles.hashTagsCard}>
                  <DashboardHashTags hashtags={frequentlyUsedHashtags} />
                </Card>
              </Grid>
            </Grid>
          </>
        ) : (
          <p
            style={{
              paddingTop: "100px",
              textAlign: "center",
            }}
          >
            Facebookを連携することで表示されます。
          </p>
        )}
      </div>
    </>
  )
}

const styles = {
  pageTitleContainer: css({
    position: "relative",
    borderBottom: "1px solid var(--color-border-primary)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 20px",
    height: HEADER_HEIGHT,
  }),
  pageTitle: css({
    overflow: "visible",
  }),
  pageSubTitleContainer: css({
    paddingTop: "10px",
    paddingRight: "20px",
    marginRight: "auto",
    textAlign: "right",
  }),
  pageSubTitle: css({
    overflow: "visible",
  }),
  pageBodyContainer: css({
    height: `calc(100%)`,
    padding: "15px 30px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  }),
  lineChartCard: css({
    height: "320px",
    padding: "30px",
    boxShadow: "none",
    border: "1px solid #D4DFE6",
    borderRadius: "16px",
  }),
  heatMapCard: css({
    height: "320px",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingRight: "20px",
    paddingLeft: "5px",
    boxShadow: "none",
    border: "1px solid #D4DFE6",
    borderRadius: "16px",
  }),
  barChartCard: css({
    height: "320px",
    padding: "20px",
    boxShadow: "none",
    border: "1px solid #D4DFE6",
    borderRadius: "16px",
  }),
  pieChartCard: css({
    height: "320px",
    padding: "20px",
    boxShadow: "none",
    border: "1px solid #D4DFE6",
    borderRadius: "16px",
  }),
  hashTagsCard: css({
    height: "320px",
    padding: "20px",
    boxShadow: "none",
    border: "1px solid #D4DFE6",
    borderRadius: "16px",
  }),
}

export default DashboardContainer
