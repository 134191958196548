import React, { useContext, useState } from "react"
import Cookies from "js-cookie"
import { paths } from "@/utils/paths"
import {
  TextField,
  Button,
  css,
  Box,
  Typography,
  Grid,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material"
import { CustomStepper } from "@/components/CustomStepper"
import { SocialSignInButton } from "@/components/SocialSignInButton"
import { signUp } from "@/api/auth"
import { SignUpParams } from "interfaces/index"
import { useNavigate } from "react-router-dom"
import AlertContext from "./AlertProvider"
import { PageMainTitle } from "@/components/typography/PageMainTitle"
import { MediumLabel } from "@/components/typography/MediumLabel"
import { MediumLabelWithRequired } from "./typography/MediumLabelWithRequired"
import { industries } from "@/utils/helpers/industries"
import { occupations } from "@/utils/helpers/occupations"

const SignUpContainer: React.FC = () => {
  const [givenName, setGivenName] = useState<string>("")
  const [familyName, setFamilyName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [gender, setGender] = useState<string>("")
  const [tel, setTel] = useState<string>("")
  const [birthdayDay, setBirthdayDay] = useState<string>("")
  const [birthdayMonth, setBirthdayMonth] = useState<string>("")
  const [birthdayYear, setBirthdayYear] = useState<string>("")
  const [companyName, setCompanyName] = useState<string>("")
  const [industryId, setIndustryId] = useState<string>("")
  const [occupationId, setOccupationId] = useState<string>("")
  const [agree, setAgree] = useState(false)

  const days = Array.from({ length: 31 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  )
  const months = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  )
  const years = Array.from({ length: 100 }, (_, i) =>
    (new Date().getFullYear() - i).toString()
  )

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const navigate = useNavigate()
  const alert = useContext(AlertContext)

  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsSubmitting(true)

    // @note .replace(/\s/g, "") 空白削除
    const params: SignUpParams = {
      givenName: givenName.replace(/\s/g, ""),
      familyName: familyName.replace(/\s/g, ""),
      email: email.replace(/\s/g, ""),
      tel: tel.replace(/\s/g, ""),
      gender: gender.replace(/\s/g, ""),
      companyName: companyName.replace(/\s/g, ""),
      industryId: String(industryId).replace(/\s/g, ""),
      occupationId: String(occupationId).replace(/\s/g, ""),
      password: password.replace(/\s/g, ""),
      birthdayDay: birthdayDay.replace(/\s/g, ""),
      birthdayMonth: birthdayMonth.replace(/\s/g, ""),
      birthdayYear: birthdayYear.replace(/\s/g, ""),
      confirm_success_url: `${process.env.REACT_APP_WEB_HOST_NAME}${paths.authConfirmationCompletePath}`,
      userRoleKind: "admin",
    }

    await signUp(params)
      .then((response) => {
        Cookies.set("access_token", response.headers["access-token"])
        Cookies.set("client", response.headers["client"])
        Cookies.set("uid", response.headers["uid"])

        navigate(paths.thanksForRegistrationPath)

        alert?.success("仮登録が完了しました")
      })
      .catch((error) => {
        alert?.error(error.response.data.errors.message)
      })

    setIsSubmitting(false)
  }

  return (
    <div css={styles.container}>
      <form noValidate autoComplete="off" css={styles.form}>
        <div css={styles.header}>
          <PageMainTitle>サインアップ</PageMainTitle>
        </div>
        {activeStep !== 0 && <CustomStepper activeStep={activeStep - 1} />}
        {activeStep === 0 && (
          <>
            <div css={styles.formContent}>
              <MediumLabel>メールアドレス</MediumLabel>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={email}
                placeholder="example@email.com"
                margin="dense"
                onChange={(event) => setEmail(event.target.value)}
              />
              <MediumLabel>パスワード</MediumLabel>
              <TextField
                variant="outlined"
                type="password"
                required
                fullWidth
                value={password}
                placeholder="パスワード(6文字以上)"
                margin="dense"
                autoComplete="current-password"
                onChange={(event) => setPassword(event.target.value)}
              />
              <div css={styles.passwordRules}>
                パスワードは英数字と記号6文字以上が使用できます
              </div>
              <Button
                variant="contained"
                size="large"
                fullWidth
                disabled={!email || !password ? true : false}
                css={styles.submitBtn}
                onClick={handleNext}
                sx={{ backgroundColor: "var(--color-primary)" }}
              >
                次へ
              </Button>
              <Box textAlign="center" css={styles.box}>
                <Typography variant="body2">
                  すでにアカウントをお持ちの方は
                  <a href="/signin" css={styles.link}>
                    ログイン
                  </a>
                </Typography>
              </Box>
              <Divider>
                <div css={styles.driverContent}>または</div>
              </Divider>
              <SocialSignInButton provider="facebook" state="signUp" />
            </div>
          </>
        )}
        {activeStep === 1 && (
          <>
            <div css={styles.formContent}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <MediumLabelWithRequired>姓</MediumLabelWithRequired>
                  <TextField
                    css={styles.textField}
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={familyName}
                    placeholder="山田"
                    margin="dense"
                    onChange={(event) => setFamilyName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MediumLabelWithRequired>名</MediumLabelWithRequired>
                  <TextField
                    css={styles.textField}
                    variant="outlined"
                    required
                    fullWidth
                    defaultValue={givenName}
                    placeholder="太郎"
                    margin="dense"
                    onChange={(event) => setGivenName(event.target.value)}
                  />
                </Grid>
              </Grid>
              <MediumLabelWithRequired>生年月日</MediumLabelWithRequired>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>年</InputLabel>
                    <Select
                      value={birthdayYear}
                      onChange={(e) => setBirthdayYear(e.target.value)}
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>月</InputLabel>
                    <Select
                      value={birthdayMonth}
                      onChange={(e) => setBirthdayMonth(e.target.value)}
                    >
                      {months.map((month) => (
                        <MenuItem key={month} value={month}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>日</InputLabel>
                    <Select
                      value={birthdayDay}
                      onChange={(e) => setBirthdayDay(e.target.value)}
                    >
                      {days.map((day) => (
                        <MenuItem key={day} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <MediumLabelWithRequired>性別</MediumLabelWithRequired>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">性別</InputLabel>
                <Select
                  value={gender}
                  label="性別"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value={"male"}>男性</MenuItem>
                  <MenuItem value={"female"}>女性</MenuItem>
                </Select>
              </FormControl>
              <MediumLabelWithRequired>電話番号</MediumLabelWithRequired>
              <TextField
                css={styles.textField}
                variant="outlined"
                required
                fullWidth
                value={tel}
                placeholder="09000000000"
                margin="dense"
                onChange={(event) => setTel(event.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  css={styles.submitBtn}
                  onClick={handleBack}
                  sx={{ backgroundColor: "var(--color-primary)" }}
                >
                  戻る
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={
                    !givenName ||
                    !familyName ||
                    !birthdayYear ||
                    !birthdayMonth ||
                    !birthdayYear ||
                    !gender ||
                    !tel
                      ? true
                      : false
                  }
                  css={styles.submitBtn}
                  onClick={handleNext}
                  sx={{ backgroundColor: "var(--color-primary)" }}
                >
                  次へ
                </Button>
              </Box>
            </div>
          </>
        )}
        {activeStep === 2 && (
          <>
            <div css={styles.formContent}>
              <MediumLabelWithRequired>会社名</MediumLabelWithRequired>
              <TextField
                css={styles.textField}
                variant="outlined"
                required
                fullWidth
                value={companyName}
                placeholder="株式会社BuzzConnection"
                margin="dense"
                onChange={(event) => setCompanyName(event.target.value)}
              />
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <MediumLabelWithRequired>業種</MediumLabelWithRequired>
                  <FormControl fullWidth>
                    <InputLabel id="industry-label">業種を選択</InputLabel>
                    <Select
                      value={industryId}
                      onChange={(e) => setIndustryId(e.target.value)}
                    >
                      {industries.map((industry) => (
                        <MenuItem key={industry.id} value={industry.id}>
                          {industry.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MediumLabelWithRequired>職種</MediumLabelWithRequired>
                  <FormControl fullWidth>
                    <InputLabel id="industry-label">職種を選択</InputLabel>
                    <Select
                      value={occupationId}
                      onChange={(e) => setOccupationId(e.target.value)}
                    >
                      {occupations.map((occupation) => (
                        <MenuItem key={occupation.id} value={occupation.id}>
                          {occupation.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <FormControlLabel
                css={styles.checkboxContainer}
                control={
                  <Checkbox
                    checked={agree}
                    onChange={(event) => setAgree(event.target.checked)}
                    style={{ color: agree ? "#1c98c5" : "" }} // 追加
                  />
                }
                label={
                  <React.Fragment>
                    <Link
                      href="https://buzzconne.jp/termsofservice"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      利用規約に
                    </Link>
                    <span>同意する</span>
                  </React.Fragment>
                }
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  css={styles.submitBtn}
                  onClick={handleBack}
                  sx={{ backgroundColor: "var(--color-primary)" }}
                >
                  戻る
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  disabled={
                    isSubmitting ||
                    !companyName ||
                    !industryId ||
                    !occupationId ||
                    !agree
                      ? true
                      : false
                  }
                  css={styles.submitBtn}
                  onClick={handleSubmit}
                  sx={{ backgroundColor: "var(--color-primary)" }}
                >
                  アカウントを作成
                </Button>
              </Box>
            </div>
          </>
        )}
      </form>
      <Box css={styles.footer}>
        <Typography variant="body2">
          <a
            href="https://buzzconne.jp/termsofservice"
            target="_blank"
            rel="noopener noreferrer"
          >
            利用規約
          </a>
          {"　/　"}
          <a
            href="https://buzzconne.jp/privacypolicy"
            target="_blank"
            rel="noopener noreferrer"
          >
            プライバシーポリシー
          </a>
          {"　/　"}
          <a
            href="https://buzzconne.jp/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            お問い合わせ
          </a>
          <span>　BuzzConnection Inc.</span>
        </Typography>
      </Box>
    </div>
  )
}
export default SignUpContainer

const styles = {
  container: css({
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  form: css({
    padding: 16,
    maxWidth: 500,
    width: "100%",
    boxSizing: "border-box",
  }),
  submitBtn: css({
    fontSize: 16,
    marginTop: 16,
    flexGrow: 1,
    textTransform: "none",
  }),
  textField: css({
    marginTop: 0,
  }),
  header: css({
    textAlign: "center",
    marginBottom: 14,
  }),
  formContent: css({
    "& *": {
      marginBottom: 6,
    },
  }),
  box: css({
    marginTop: "2rem",
  }),
  passwordRules: css({ color: "#6D777A", fontSize: 12 }),
  link: css({
    textDecoration: "underline",
    color: "var(--color-link)",
    cursor: "pointer",
  }),
  driverContent: css({
    fontSize: 11,
    marginTop: 15,
    color: "#6D777A",
  }),
  socialAuthenticationContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: 20,
  }),
  socialAuthenticationButton: css({
    fontSize: 14,
    color: "#101111",
    fontWeight: "bold",
    border: "1px solid #6D777A",
    flexGrow: 1,
    textTransform: "none",
  }),
  socialLogo: css({ marginBottom: 0 }),
  socialSignInButton: css({
    justifyContent: "flex-start",
    textTransform: "none",
    // Apply additional styles for the button if needed
  }),
  checkboxContainer: css({
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      marginBottom: 0,
    },
  }),
  footer: css({
    width: "100%",
    textAlign: "center",
    color: "#6D777A",
    "& a": {
      color: "#6D777A",
      display: "inline",
      textDecoration: "none",
    },
    position: "relative",
    marginTop: "auto",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  }),
}
