import {
  Button,
  css,
  Dialog,
  DialogContent,
  TextField,
  FormControl,
  Select,
  IconButton,
  Typography,
  // Link,
  SelectChangeEvent,
} from "@mui/material"
import { StyledMenuItem } from "@/components/StyledMenuItem"
import { CustomTooltip } from "@/components/CustomTooltip"
// import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { LlmInteractionListItemEditDialogLlmReply } from "@/components/LlmInteractionListItemEditDialogLlmReply"
import { HEADER_HEIGHT } from "@/components/GlobalHeader"
import { PageMainTitle } from "@/components/typography/PageMainTitle"
import LlmInteractionListItemEditDialogItemTypeCards from "@/components/LlmInteractionListItemEditDialogItemTypeCards"
import { PageSubTitle } from "@/components/typography/PageSubTitle"
import { LargeLabel } from "@/components/typography/LargeLabel"
import { MediumLabel } from "@/components/typography/MediumLabel"
import { MediumLabelWithRequired } from "./typography/MediumLabelWithRequired"
import { paths } from "@/utils/paths"
import { apiPaths } from "@/utils/paths"
import { deleteGenerativeContentResponse } from "@/utils/paths"
import { deleteGenerativeContentResponseImages } from "@/utils/paths"
import { updateGenerativeContentResponse } from "@/utils/paths"
import client from "@/api/client"
import AlertContext from "@/components/AlertProvider"
import { GenerativeContentType } from "@/types/GenerativeContentType"
import { GenerativeContentResponseType } from "@/types/GenerativeContentResponseType"
import { ProductType } from "@/types/ProductType"
import { UserVariableInputType } from "@/types/UserVariableInputType"
import { UnselectedInputLabel } from "@/components/UnselectedInputLabel"
import SaveStatusLabel from "@/components/SaveStatusLabel"
import EditIcon from "@mui/icons-material/Edit"
import _ from "lodash"
import LlmImageFootnote from "@/components/LlmImageFootnote"
import { LlmInteractionListItemEditDialogImageIntensitySlider } from "@/components/LlmInteractionListItemEditDialogImageIntensitySlider"
import { useDropzone, FileWithPath } from "react-dropzone"
import LlmNoDataImage from "@/images/llm_no_data.png"
import { PromptType } from "@/types/PromptType"
import { CategoryType } from "@/types/CategoryType"
import { getUpdatedAtTime } from "@/utils/time"

interface Props {
  isOpen: boolean
  onRequestClose: () => void
  generativeContent?: GenerativeContentType
}

interface FormState {
  [key: string]: string | string[]
}

export const LlmInteractionListItemEditDialog = ({
  isOpen,
  onRequestClose,
  generativeContent,
}: Props) => {
  const alert = useContext(AlertContext)
  const [saveStatus, setSaveStatus] = useState("saved")
  const [isInitialRender, setIsInitialRender] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isTitleEditing, setIsTitleEditing] = useState(false)
  const [localGenerativeContent, setLocalGenerativeContent] =
    useState<GenerativeContentType | null>(generativeContent || null)
  const [generativeContentResponses, setGenerativeContentResponses] = useState(
    localGenerativeContent?.generativeContentResponses || []
  )
  const [updatedAtTime, setUpdatedAtTime] = useState(
    localGenerativeContent?.updatedAtTime || ""
  )
  const completedGenerativeContentResponses = generativeContentResponses?.every(
    (generativeContentResponse) => generativeContentResponse.response !== null
  )
  const [itemType, setItemType] = useState("posts")
  const [postPrompt, setPostPrompt] = useState<PromptType | null>(null)
  const [categories, setCategories] = useState<CategoryType[]>([])
  const [products, setProducts] = useState<ProductType[]>([])
  const [title, setTitle] = useState(localGenerativeContent?.title || "")
  const [selectedPromptId, setSelectedPromptId] = useState(
    localGenerativeContent?.promptId || ""
  )
  const [selectedProductId, setSelectedProductId] = useState(
    localGenerativeContent?.productId || ""
  )

  const [referenceImage, setReferenceImage] = useState(
    localGenerativeContent?.referenceImage || ""
  )
  const [referenceImageIntensity, setReferenceImageIntensity] = useState(
    localGenerativeContent?.referenceImageIntensity || 50
  )
  const [imagesPrompt, setImagesPrompt] = useState(
    localGenerativeContent?.imagesPrompt || ""
  )
  const [imagesNegativePrompt, setImagesNegativePrompt] = useState(
    localGenerativeContent?.imagesNegativePrompt || ""
  )

  const handleSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      setIsLoading(true)

      if (!localGenerativeContent?.id) return

      const formData = new FormData()

      formData.append("generativeContentId", localGenerativeContent.id)
      formData.append("title", title)
      formData.append("promptId", selectedPromptId)
      formData.append("productId", selectedProductId)
      formData.append("referenceImage", referenceImage)
      formData.append("imagesPrompt", imagesPrompt)
      formData.append("imagesNegativePrompt", imagesNegativePrompt)
      formData.append("itemType", itemType)
      formData.append(
        "referenceImageIntensity",
        referenceImageIntensity.toString()
      )

      await client
        .post(`${apiPaths.generativeContentResponsePath}`, formData)
        .then((response) => {
          setLocalGenerativeContent(response.data.generativeContent)
          setGenerativeContentResponses([
            response.data.generativeContentResponse,
            ...generativeContentResponses,
          ])
          alert?.success("コンテンツ生成を開始しました")
        })

      setIsLoading(false)
    },
    [
      alert,
      generativeContentResponses,
      referenceImage,
      referenceImageIntensity,
      title,
      imagesPrompt,
      imagesNegativePrompt,
      localGenerativeContent,
      itemType,
      selectedPromptId,
      selectedProductId,
    ]
  )

  const handleDraftSubmit = useCallback(async () => {
    if (isLoading) return
    if (!generativeContent?.id) return
    setSaveStatus("saving")
    const formData = new FormData()

    formData.append("generativeContentId", generativeContent.id)
    formData.append("title", title)
    formData.append("promptId", selectedPromptId)
    formData.append("productId", selectedProductId)
    formData.append("referenceImage", referenceImage)
    formData.append("imagesPrompt", imagesPrompt)
    formData.append("imagesNegativePrompt", imagesNegativePrompt)
    formData.append("itemType", itemType)
    formData.append(
      "referenceImageIntensity",
      referenceImageIntensity.toString()
    )

    await client
      .put(`${paths.generativeContents}/${generativeContent?.id}`, formData)
      .then((response) => {
        setSaveStatus("saved")
        setLocalGenerativeContent(response.data.generativeContent)
      })
      .catch(() => {
        setSaveStatus("failed")
      })
  }, [
    generativeContent?.id,
    isLoading,
    title,
    imagesPrompt,
    imagesNegativePrompt,
    itemType,
    selectedPromptId,
    selectedProductId,
    referenceImage,
    referenceImageIntensity,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleDraftSubmit = useCallback(
    _.debounce(() => handleDraftSubmit(), 500),
    [handleDraftSubmit]
  )

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false)
    } else if (!isLoading) {
      debouncedHandleDraftSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, debouncedHandleDraftSubmit])

  const handleDeleteGenerativeContentResponse = useCallback(
    async (id: string) => {
      const filteredGenerativeContentResponses =
        generativeContentResponses.filter((response) => response.id !== id)

      client.delete(deleteGenerativeContentResponse(id)).then(() => {
        setGenerativeContentResponses(filteredGenerativeContentResponses)
        alert?.success("削除しました")
      })
    },
    [alert, generativeContentResponses]
  )

  const handleDeleteGenerativeContentResponseImages = useCallback(
    async (id: string) => {
      const updatedGenerativeContentResponses = generativeContentResponses
        .map((response) => {
          if (response.id === id && response.itemType === "images") {
            return null
          } else if (response.id === id) {
            return { ...response, responseImages: [], itemType: "posts" }
          }
          return response
        })
        .filter(
          (response) => response !== null
        ) as GenerativeContentResponseType[]

      client.delete(deleteGenerativeContentResponseImages(id)).then(() => {
        setGenerativeContentResponses(updatedGenerativeContentResponses)
        alert?.success("削除しました")
      })
    },
    [alert, generativeContentResponses]
  )

  const handleEditGenerativeContentResponse = useCallback(
    async (id: string, editedResponse: string) => {
      const formData = new FormData()
      formData.append("editedResponse", editedResponse)

      client
        .put(updateGenerativeContentResponse(id), formData)
        .then(() => {
          setGenerativeContentResponses((prevResponses) =>
            prevResponses.map((responseItem) =>
              responseItem.id === id
                ? { ...responseItem, response: editedResponse }
                : responseItem
            )
          )
          alert?.success("更新しました。")
        })
        .catch(() => {
          alert?.error("更新に失敗しました。")
        })
    },
    [alert]
  )

  useEffect(() => {
    if (!generativeContent?.id || completedGenerativeContentResponses) return

    const getGenerativeContent = async () => {
      await client
        .get(`${paths.generativeContents}/${generativeContent.id}`)
        .then((response) => {
          setGenerativeContentResponses(
            response.data.generativeContent.generativeContentResponses
          )
        })
    }
    const intervalId = setInterval(getGenerativeContent, 5000)

    return () => clearInterval(intervalId)
  }, [
    completedGenerativeContentResponses,
    generativeContent,
    localGenerativeContent,
  ])

  useEffect(() => {
    const getProducts = async () => {
      await client
        .get(paths.productsPath)
        .then((response) => {
          setProducts(response.data.products.products)
        })
        .catch((error) => {
          alert?.error(error.response.data.errors.message)
        })
    }

    getProducts()
  }, [alert])

  const [parentCategoryId, setParentCategoryId] = useState(
    localGenerativeContent?.parentCategoryId || ""
  )
  const [childCategoryId, setChildCategoryId] = useState(
    localGenerativeContent?.childCategoryId || ""
  )

  const [formState, setFormState] = useState<FormState>({})

  useEffect(() => {
    if (!localGenerativeContent?.id || !postPrompt?.id) return
    const initialFormState: FormState = {}
    const getUserVariableInputs = async () => {
      await client
        .get(apiPaths.userVariableInputsPath, {
          params: {
            generativeContentId: localGenerativeContent.id,
            promptId: postPrompt.id,
          },
        })
        .then((response) => {
          response.data.userVariableInputs.forEach(
            (input: UserVariableInputType) => {
              initialFormState[input.variableInputId] = input.userInputValue
            }
          )
          setFormState(initialFormState)
        })
    }
    getUserVariableInputs()
  }, [childCategoryId, localGenerativeContent?.id, postPrompt?.id])

  const handleParentChange = (event: SelectChangeEvent) => {
    setParentCategoryId(event.target.value as string)
    setPostPrompt(null)
  }

  const handleChildChange = (event: SelectChangeEvent) => {
    setChildCategoryId(event.target.value as string)
  }

  const selectedParentCategory = categories.find(
    (category) => category.id === parentCategoryId
  )
  const childrenCategories = selectedParentCategory?.childrenCategories ?? []

  useEffect(() => {
    const getCategories = async () => {
      await client
        .get(apiPaths.categoriesPath)
        .then((response) => {
          setCategories(response.data.categories.categories)
        })
        .catch((error) => {
          alert?.error(error.response.data.errors.message)
        })
    }

    getCategories()
  }, [alert])

  useEffect(() => {
    if (!childCategoryId || !localGenerativeContent?.id) return
    const getPostPrompts = async () => {
      await client
        .get(apiPaths.promptsPath, {
          params: {
            childCategoryId,
            generativeContentId: localGenerativeContent.id,
          },
        })
        .then((response) => {
          setPostPrompt(response.data.prompt)
          setSelectedPromptId(response.data.prompt.id)
          setUpdatedAtTime(getUpdatedAtTime())
        })
        .catch((error) => {
          alert?.error(error.response.data.errors.message)
        })
    }

    getPostPrompts()
  }, [localGenerativeContent?.id, childCategoryId, alert])

  const handleDynamicFormsDraftSubmit = useCallback(
    async (variableInputId: string, userInputValue: string) => {
      if (isLoading) return
      if (!localGenerativeContent?.id || !postPrompt?.id) return
      if (!variableInputId) return
      setSaveStatus("saving")

      const formData = new FormData()

      formData.append("generativeContentId", localGenerativeContent.id)
      formData.append("promptId", postPrompt.id)
      formData.append("userInputValue", userInputValue)
      formData.append("variable_input_id", variableInputId)

      await client
        .post(apiPaths.userVariableInputsPath, formData)
        .then(() => {
          setSaveStatus("saved")
          setUpdatedAtTime(getUpdatedAtTime())
        })
        .catch(() => {
          setSaveStatus("failed")
        })
    },
    [localGenerativeContent?.id, postPrompt?.id, isLoading]
  )

  const handleDynamicFormsInputChange = (id: string, value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      [id]: value,
    }))
    handleDynamicFormsDraftSubmit(id, value)
  }

  const handleDynamicFormsMultipleSelectChange = (
    id: string,
    value: string[]
  ) => {
    setFormState((prevState) => ({
      ...prevState,
      [id]: value,
    }))
    handleDynamicFormsDraftSubmit(id, value.join(","))
  }

  const isAllRequiredInputsCompleted = useCallback(() => {
    if (itemType === "images") return true
    if (!postPrompt) return false
    return postPrompt?.variableInputs.every((input) => {
      const value = formState[input.id]
      if (!input.isRequired) {
        return true
      }
      if (typeof value === "string" || value === undefined) {
        return value?.trim().length > 0
      } else if (Array.isArray(value)) {
        return value.length > 0
      }
      return false
    })
  }, [postPrompt, formState, itemType])

  const REFERENCE_IMAGE_MAX_SIZE = 4 * 1024 * 1024

  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    acceptedFiles.forEach((file: FileWithPath) => {
      if (file.type !== "image/png") {
        alert?.error("PNG画像のみアップロード可能です")
        return
      }
      if (file.size > REFERENCE_IMAGE_MAX_SIZE) {
        alert?.error("画像は4MB以下にしてください")
        return
      }
      const reader = new FileReader()

      reader.onabort = () => console.log("file reading was aborted")
      reader.onerror = () => console.log("file reading has failed")
      reader.onload = () => {
        // Do whatever you want with the file contents
        const base64String = reader.result as string
        setReferenceImage(base64String)
      }
      reader.readAsDataURL(file)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const materialUiStyles = {
    dialog: {
      sx: {
        position: "fixed",
        top: HEADER_HEIGHT,
        right: 0,
        m: 0,
        width: "100%",
        height: `calc(100% - ${HEADER_HEIGHT}px)`,
        boxShadow: "none",
      },
    },
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onRequestClose}
      PaperProps={materialUiStyles.dialog}
      fullScreen
      hideBackdrop={true}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div css={styles.pageTitleWithBackButtonContainer}>
          <div css={styles.backButton} onClick={onRequestClose}>
            ＜
          </div>
          <div css={styles.pageTitle}>
            {isTitleEditing ? (
              <input
                type="text"
                defaultValue={localGenerativeContent?.title}
                onChange={(event) => setTitle(event.target.value)}
                onBlur={() => setIsTitleEditing(false)}
                autoFocus
                css={styles.titleInput}
              />
            ) : (
              <PageMainTitle>{localGenerativeContent?.title}</PageMainTitle>
            )}
            <EditIcon
              onClick={() => setIsTitleEditing(true)}
              css={styles.titleEditIcon}
            />
          </div>
          <div css={styles.saveStatusContainer}>
            <SaveStatusLabel saveStatus={saveStatus} />
            <div css={styles.updatedAtText}>最終保存 {updatedAtTime}</div>
          </div>
        </div>
        <div css={styles.pageBodyContainer}>
          <div css={styles.leftColumn}>
            <div css={styles.formContent}>
              <LlmInteractionListItemEditDialogItemTypeCards
                setItemType={setItemType}
                itemType={itemType}
              />
              {itemType !== "images" && (
                <>
                  <div style={{ marginBottom: "20px" }}>
                    <div css={styles.pageSubTitle}>
                      <PageSubTitle>投稿設定</PageSubTitle>
                    </div>
                    <div>
                      <div css={styles.largeLabelContainer}>
                        <LargeLabel>基本設定</LargeLabel>
                      </div>
                      <div style={{ paddingBottom: "10px" }}>
                        <div css={styles.label}>
                          <MediumLabel>カテゴリー</MediumLabel>
                        </div>
                        <FormControl fullWidth>
                          {parentCategoryId === "" ? (
                            <UnselectedInputLabel />
                          ) : null}
                          <Select
                            value={parentCategoryId}
                            label=""
                            onChange={handleParentChange}
                            css={styles.selectBox}
                          >
                            {categories.map((category) => (
                              <StyledMenuItem
                                key={category.id}
                                value={category.id}
                              >
                                {category.name}
                              </StyledMenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ paddingBottom: "10px" }}>
                        <div css={styles.label}>
                          <MediumLabel>投稿タイプ</MediumLabel>
                        </div>
                        <FormControl fullWidth>
                          {childCategoryId === "" ? (
                            <UnselectedInputLabel />
                          ) : null}
                          <Select
                            value={childCategoryId}
                            label=""
                            onChange={handleChildChange}
                            disabled={childrenCategories.length === 0}
                            css={styles.selectBox}
                          >
                            {childrenCategories.map((category) => (
                              <StyledMenuItem
                                key={category.id}
                                value={category.id}
                              >
                                {category.name}
                              </StyledMenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div css={styles.label}>
                        <MediumLabel>商品情報</MediumLabel>
                      </div>
                      <div>
                        <FormControl fullWidth>
                          {selectedProductId === "" ? (
                            <UnselectedInputLabel />
                          ) : null}
                          <Select
                            defaultValue={localGenerativeContent?.productId}
                            onChange={(event) => {
                              setSelectedProductId(event.target.value)
                            }}
                            css={styles.selectBox}
                          >
                            {products &&
                              products.map((product) => (
                                <StyledMenuItem
                                  key={product.id}
                                  value={product.id}
                                >
                                  {product.name}
                                </StyledMenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div css={styles.largeLabelContainer}>
                      <LargeLabel>ペルソナ設定</LargeLabel>
                    </div>
                    {postPrompt?.variableInputs.map((input) => (
                      <div key={input.id} style={{ paddingBottom: "10px" }}>
                        <div css={styles.label}>
                          {input.isRequired ? (
                            <MediumLabelWithRequired>
                              {input.displayName}
                            </MediumLabelWithRequired>
                          ) : (
                            <MediumLabel>{input.displayName}</MediumLabel>
                          )}
                        </div>
                        {(() => {
                          switch (input.inputType) {
                            case "string":
                              return (
                                <TextField
                                  variant="outlined"
                                  value={(formState[input.id] as string) || ""}
                                  required={input.isRequired}
                                  fullWidth
                                  margin="dense"
                                  multiline
                                  css={styles.textField}
                                  placeholder={input.placeholder || ""}
                                  InputProps={{
                                    style: multilineTextFieldInputPropsStyle,
                                  }}
                                  onChange={(e) =>
                                    handleDynamicFormsInputChange(
                                      input.id,
                                      e.target.value
                                    )
                                  }
                                />
                              )
                            case "text":
                              return (
                                <TextField
                                  variant="outlined"
                                  value={(formState[input.id] as string) || ""}
                                  required={input.isRequired}
                                  fullWidth
                                  margin="dense"
                                  multiline
                                  rows={5}
                                  css={styles.textField}
                                  placeholder={input.placeholder || ""}
                                  InputProps={{
                                    style: multilineTextFieldInputPropsStyle,
                                  }}
                                  onChange={(e) =>
                                    handleDynamicFormsInputChange(
                                      input.id,
                                      e.target.value
                                    )
                                  }
                                />
                              )
                            case "single_select":
                              return (
                                <FormControl fullWidth>
                                  <Select
                                    required={input.isRequired}
                                    css={styles.selectBox}
                                    onChange={(event) =>
                                      handleDynamicFormsInputChange(
                                        input.id,
                                        event.target.value as string
                                      )
                                    }
                                    value={
                                      (formState[input.id] as string) || ""
                                    }
                                  >
                                    {input.options?.map(
                                      (option: string, index) => (
                                        <StyledMenuItem
                                          key={index}
                                          value={option}
                                        >
                                          {option}
                                        </StyledMenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              )
                            case "multi_select":
                              return (
                                <FormControl fullWidth>
                                  <Select
                                    required={input.isRequired}
                                    css={styles.selectBox}
                                    multiple
                                    onChange={(event) =>
                                      handleDynamicFormsMultipleSelectChange(
                                        input.id,
                                        event.target.value as string[]
                                      )
                                    }
                                    value={
                                      (formState[input.id] as string[]) || []
                                    }
                                  >
                                    {input.options?.map((option, index) => (
                                      <StyledMenuItem
                                        key={index}
                                        value={option}
                                      >
                                        {option}
                                      </StyledMenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )
                            default:
                              return null
                          }
                        })()}
                      </div>
                    ))}
                  </div>
                </>
              )}
              {itemType !== "posts" && (
                <>
                  <div css={styles.pageSubTitle}>
                    <PageSubTitle>画像設定</PageSubTitle>
                  </div>
                  <div css={styles.labelWithTooltip}>
                    <MediumLabel>プロンプト</MediumLabel>
                    <CustomTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            color="inherit"
                            css={styles.tooltipTypography}
                          >
                            生成したい画像のイメージなどを入力してください。
                          </Typography>
                          {/* <Link
                            href="https://buzzconne.jp/"
                            target="_blank"
                            rel="noopener noreferrer"
                            css={styles.tooltipLink}
                          >
                            プロンプトの書き方のヒント
                            <OpenInNewIcon
                              css={styles.tooltipIcon}
                              fontSize="small"
                            />
                          </Link> */}
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <HelpOutlineIcon
                          css={styles.tooltipIcon}
                          fontSize="small"
                        />
                      </IconButton>
                    </CustomTooltip>
                  </div>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    multiline
                    rows={5}
                    css={styles.textField}
                    defaultValue={localGenerativeContent?.imagesPrompt}
                    placeholder="生成したい画像のイメージなどを入力してください。"
                    margin="dense"
                    onChange={(event) => {
                      setImagesPrompt(event.target.value)
                    }}
                    InputProps={{
                      style: multilineTextFieldInputPropsStyle,
                    }}
                  />
                  <div css={styles.labelWithTooltip}>
                    <MediumLabel>ネガティブプロンプト</MediumLabel>
                    <CustomTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            color="inherit"
                            css={styles.tooltipTypography}
                          >
                            生成したい画像のイメージなどを入力してください。
                          </Typography>
                          {/* <Link
                            href="https://buzzconne.jp/"
                            target="_blank"
                            rel="noopener noreferrer"
                            css={styles.tooltipLink}
                          >
                            プロンプトの書き方のヒント
                            <OpenInNewIcon
                              css={styles.tooltipIcon}
                              fontSize="small"
                            />
                          </Link> */}
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <HelpOutlineIcon
                          css={styles.tooltipIcon}
                          fontSize="small"
                        />
                      </IconButton>
                    </CustomTooltip>
                  </div>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    multiline
                    rows={5}
                    css={styles.textField}
                    defaultValue={localGenerativeContent?.imagesNegativePrompt}
                    placeholder="特定のトピックや避けたい内容を入力してください。"
                    margin="dense"
                    onChange={(event) => {
                      setImagesNegativePrompt(event.target.value)
                    }}
                    InputProps={{
                      style: multilineTextFieldInputPropsStyle,
                    }}
                  />
                  <div css={styles.labelWithTooltip}>
                    <MediumLabel>参考画像</MediumLabel>
                    <CustomTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            color="inherit"
                            css={styles.tooltipTypography}
                          >
                            画像生成の参考にする画像を設定できます。
                          </Typography>
                          <Typography
                            color="inherit"
                            css={styles.tooltipTypography}
                          >
                            強度スライダーでプロンプトへの影響力を調整できます。
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <HelpOutlineIcon
                          css={styles.tooltipIcon}
                          fontSize="small"
                        />
                      </IconButton>
                    </CustomTooltip>
                  </div>
                  {referenceImage === "" ? (
                    <>
                      <LlmImageFootnote />
                      <div {...getRootProps()} css={styles.uploadBoxStyle}>
                        <input {...getInputProps()} />
                        <CloudUploadIcon css={styles.cloudUploadIcon} />
                        <p css={styles.uploadBoxTextStyle}>
                          画像をアップロードしてください
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div css={styles.imagePreviewContainer}>
                        <div css={styles.imageContainer}>
                          <img
                            css={styles.image}
                            className="image"
                            src={referenceImage}
                            alt=""
                          />
                          <Button
                            css={styles.closeButton}
                            data-close-button
                            onClick={(_event) => setReferenceImage("")}
                          >
                            <HighlightOffIcon css={styles.highlightOffIcon} />
                          </Button>
                        </div>
                      </div>
                      <LlmInteractionListItemEditDialogImageIntensitySlider
                        intensity={referenceImageIntensity}
                        setIntensity={setReferenceImageIntensity}
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <div css={styles.formButtonContainer}>
              <div css={styles.formButton}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                  disabled={
                    isLoading ||
                    saveStatus !== "saved" ||
                    !postPrompt ||
                    !isAllRequiredInputsCompleted()
                  }
                  sx={{
                    backgroundColor: "var(--color-primary)",
                    width: "200px",
                    height: "40px",
                  }}
                >
                  生成
                </Button>
              </div>
            </div>
          </div>
          <div css={styles.rightColumn}>
            <div css={styles.llmReplyContainer}>
              {generativeContentResponses &&
              generativeContentResponses.length > 0 ? (
                generativeContentResponses.map((generativeContentResponse) => {
                  return (
                    <LlmInteractionListItemEditDialogLlmReply
                      key={generativeContentResponse.id}
                      generativeContentResponse={generativeContentResponse}
                      onDelete={handleDeleteGenerativeContentResponse}
                      onDeleteImages={
                        handleDeleteGenerativeContentResponseImages
                      }
                      onEdit={handleEditGenerativeContentResponse}
                    />
                  )
                })
              ) : (
                <div css={styles.noDataImageContainer}>
                  <img src={LlmNoDataImage} css={styles.noDataImage} alt="" />
                  <LargeLabel>
                    生成されたコンテンツが、ここに表示されます
                  </LargeLabel>
                </div>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const multilineTextFieldInputPropsStyle = {
  marginTop: "0px",
  fontSize: "12px",
  minHeight: "30px",
  lineHeight: "30px",
  padding: "2px 14px",
}

const styles = {
  pageTitleWithBackButtonContainer: css({
    position: "relative",
    borderBottom: "1px solid var(--color-border-primary)",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px 20px",
    height: HEADER_HEIGHT,
  }),
  backButton: css({
    fontWeight: "bold",
    paddingRight: "10px",
    cursor: "pointer",
  }),
  pageTitle: css({
    overflow: "visible",
    display: "flex",
    alignItems: "center",
  }),
  pageSubTitle: css({
    borderBottom: "1px solid var(--color-border-primary)",
    margin: "0px -30px",
    padding: "0px 30px",
  }),
  largeLabelContainer: css({
    marginTop: "20px",
    marginBottom: "20px",
  }),
  titleInput: css({
    border: "none",
    borderBottom: "2px solid var(--color-border-primary)",
    fontWeight: "bold",
    fontSize: "var(--font-size-heading-large)",
    color: "#101111",
    outline: "none",
    backgroundColor: "transparent",
  }),
  titleEditIcon: css({
    paddingLeft: "5px",
    color: "var(--color-title-edit-icon)",
    fontSize: "20px",
  }),
  pageBodyContainer: css({
    display: "flex",
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
  }),
  leftColumn: css({
    flex: 4.2,
    height: "100%",
  }),
  formContent: css({
    padding: "20px 30px",
    overflowY: "auto",
    borderBottom: "1px solid var(--color-border-primary)",
    height: "90%",
  }),
  selectBox: css({
    height: "33px",
    fontSize: "12px",
  }),
  formButtonContainer: css({
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "10%",
  }),
  formButton: css({
    textAlign: "right",
    padding: "0 50px",
    flexGrow: 1,
  }),
  rightColumn: css({
    flex: 5.8,
    height: "100%",
    backgroundColor: "#eff4f6",
  }),
  llmReplyContainer: css({
    overflowY: "auto",
    height: "100%",
    padding: "20px 30px",
  }),
  saveStatusContainer: css({
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  }),
  updatedAtText: css({
    fontSize: "var(--font-size-body-small)",
    color: "var(--color-border-gray)",
  }),
  label: css({
    paddingBottom: "5px",
  }),
  labelWithTooltip: css({
    paddingTop: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  }),
  tooltipIcon: css({
    color: "var(--color-primary)",
    paddingBottom: "0px",
  }),
  tooltipLink: css({
    paddingTop: "5px",
    fontSize: "var(--font-size-body-xsmall)",
    color: "var(--color-primary)",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  }),
  tooltipTypography: css({
    fontSize: "var(--font-size-body-small)",
  }),
  textField: css({
    marginTop: "0px",
  }),
  uploadBoxStyle: css({
    border: "1px dashed var(--color-border-gray)",
    borderRadius: "8px",
    padding: "20px 10px",
    textAlign: "center",
    cursor: "pointer",
  }),
  uploadBoxTextStyle: css({
    margin: 0,
    color: "var(--color-border-gray)",
  }),
  cloudUploadIcon: css({
    color: "var(--color-border-gray)",
  }),
  imagePreviewContainer: css({
    textAlign: "center",
  }),
  imageContainer: css({
    position: "relative",
    display: "inline-block",
    margin: "10px",
    "&:hover": {
      "& .image": {
        filter: "brightness(0.4)",
      },
      "& [data-close-button]": {
        display: "flex",
      },
    },
  }),
  image: css({
    display: "block",
    width: "150px",
    height: "auto",
    border: "1px solid var(--color-border-primary)",
  }),
  noDataImageContainer: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "100px",
    gap: "30px",
  }),
  noDataImage: css({
    width: "280px",
    height: "280px",
  }),
  closeButton: css({
    position: "absolute",
    top: "0",
    right: "-10px",
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  }),
  highlightOffIcon: css({
    color: "var(--color-high-light-off-icon)",
  }),
}
