import applyCaseMiddleware from "axios-case-converter"
import axios from "axios"
import Cookies from "js-cookie"

const options = {
  ignoreHeaders: true,
}

// TODO:baseURLを本番環境にデプロイする際に次のように変更する: `${process.env.REACT_PUBLIC_API_HOST_NAME}${process.env.REACT_PUBLIC_API_PATH}`
const client = applyCaseMiddleware(
  axios.create({
    baseURL: `${process.env.REACT_APP_API_HOST_NAME}/${process.env.REACT_APP_API_PATH}/`,
  }),
  options
)

client.interceptors.request.use(
  (config) => {
    config.headers["access-token"] = Cookies.get("access_token") as string
    config.headers["client"] = Cookies.get("client") as string
    config.headers["uid"] = Cookies.get("uid") as string
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default client
