import React, { useState, useEffect, useCallback } from "react"
import client from "@/api/client"
import { formatTimestamp } from "@/utils/helpers/postList"
import {
  Modal,
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material"
import { InstagramAccountMediumType } from "@/types/InstagramAccountMediumType"
import { InstagramAccountMediaInsightType } from "@/types/InstagramAccountMediaInsightType"
import { SelectablePeriodType } from "@/utils/helpers/dashboard"
import { LineChartDataSets } from "@/utils/helpers/postList"
import moment from "moment"
import { apiPaths } from "@/utils/paths"
import { DashboardDateRangePicker } from "@/components/DashboardDateRangePicker"
import { InstagramAccountMediaInsightsLineChart } from "@/components/InstagramAccountMediaInsightsLineChart"
import InstagramIcon from "@mui/icons-material/Instagram"
import { css } from "@emotion/react"
import { getMetricCards } from "@/utils/helpers/postList"

interface InstagramAccountMediaModalProps {
  open: boolean
  onClose: () => void
  media: InstagramAccountMediumType
  isInitialVelocityAnalysis: boolean
}

const InstagramAccountMediaModal: React.FC<InstagramAccountMediaModalProps> = ({
  open,
  onClose,
  media,
  isInitialVelocityAnalysis,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedMetric, setSelectedMetric] = useState("reachCounts")
  const handleMetricClick = (metric: string) => {
    setSelectedMetric(metric)
  }
  const metricCards = getMetricCards(media)

  const [selectablePeriod, setSelectablePeriod] =
    useState<SelectablePeriodType>({
      oldestTargetOn: null,
      latestTargetOn: null,
    })
  const [startDate, setStartDate] = useState<moment.Moment | null>(null)
  const [endDate, setEndDate] = useState<moment.Moment | null>(null)

  const [lineChartLabels, setLineChartLabels] = useState<string[]>([])
  const [lineChartDataSets, setLineChartDataSets] = useState<LineChartDataSets>(
    {
      reachCounts: [],
      playCounts: [],
      likeCounts: [],
      saveCounts: [],
      shareCounts: [],
      engagementCounts: [],
      engagementRates: [],
      commentCounts: [],
      impressionCounts: [],
    }
  )

  const updateLineChartDataSets = useCallback(
    (mediaInsightHistories: InstagramAccountMediaInsightType[]) => {
      const formattedData = {
        reachCounts: mediaInsightHistories.map((item) => item.reachCount),
        playCounts: mediaInsightHistories.map((item) => item.playCount),
        likeCounts: mediaInsightHistories.map((item) => item.likeCount),
        saveCounts: mediaInsightHistories.map((item) => item.saveCount),
        shareCounts: mediaInsightHistories.map((item) => item.shareCount),
        engagementCounts: mediaInsightHistories.map(
          (item) => item.engagementCount
        ),
        engagementRates: mediaInsightHistories.map(
          (item) => item.engagementRate
        ),
        commentCounts: mediaInsightHistories.map((item) => item.commentCount),
        impressionCounts: mediaInsightHistories.map(
          (item) => item.impressionCount
        ),
      }

      const labels = mediaInsightHistories.map((item) => {
        const date = new Date(item.createdAt)
        if (isInitialVelocityAnalysis) {
          const hours = date.getHours().toString().padStart(2, "0")
          const minutes = date.getMinutes().toString().padStart(2, "0")
          return `${hours}:${minutes}`
        } else {
          return `${date.getMonth() + 1}/${date.getDate()}`
        }
      })

      setLineChartDataSets(formattedData)
      setLineChartLabels(labels)
    },
    [isInitialVelocityAnalysis, setLineChartDataSets, setLineChartLabels]
  )

  useEffect(() => {
    if (!open) {
      return
    }
    setIsLoading(true)
    const params =
      startDate && endDate
        ? {
            startDate,
            endDate,
            instagramAccountMediumId: media.id,
            isInitialVelocityAnalysis: isInitialVelocityAnalysis,
          }
        : {
            instagramAccountMediumId: media.id,
            isInitialVelocityAnalysis: isInitialVelocityAnalysis,
          }
    client
      .get(apiPaths.instagramAccountMediaInsightPath, { params })
      .then((response) => {
        if (response.status === 200) {
          setSelectablePeriod(response.data?.selectablePeriod)
          if (!startDate && !endDate) {
            setStartDate(
              response.data.initialPeriod?.startDate
                ? moment(response.data.initialPeriod.startDate)
                : null
            )
            setEndDate(
              response.data.initialPeriod?.endDate
                ? moment(response.data.initialPeriod.endDate)
                : null
            )
          }
          updateLineChartDataSets(response.data.mediaInsightHistories)
          setIsLoading(false)
        }
      })
      .catch(() => {})
  }, [
    startDate,
    endDate,
    media.id,
    open,
    isInitialVelocityAnalysis,
    updateLineChartDataSets,
  ])

  const hasImage = media.thumbnailImageUrl

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      disableAutoFocus={true}
      css={styles.modal}
    >
      <Box
        sx={{
          width: "64%",
          height: "78%",
          bgcolor: "background.paper",
          boxShadow: 24,
          padding: "16px 32px 0px",
        }}
      >
        <Grid container spacing={2} css={styles.gridContainer}>
          <Grid item xs={3.5} css={styles.leftGridContainer}>
            {hasImage && (
              <div css={styles.imageContainer}>
                {media.thumbnailImageUrl.endsWith(".mp4") ? (
                  <video
                    src={media.thumbnailImageUrl}
                    css={styles.image}
                    controls
                  />
                ) : (
                  <img
                    src={media.thumbnailImageUrl}
                    alt="thumbnail"
                    css={styles.image}
                  />
                )}
                <div css={styles.mediaMetadata}>
                  <div css={styles.formatTimestamp}>
                    <span>
                      {formatTimestamp(media.timestamp).split(" ")[0]}
                    </span>
                    <span css={styles.space}></span>
                    <span>
                      {formatTimestamp(media.timestamp).split(" ")[1]}
                    </span>
                  </div>
                </div>
                <a
                  href={media.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton css={styles.IconButton}>
                    <InstagramIcon css={styles.Icon} />
                  </IconButton>
                </a>
              </div>
            )}
            {!hasImage && (
              <>
                <div css={styles.noImageMediaMetadata}>
                  <div css={styles.noImageFormatTimestamp}>
                    <span>
                      {formatTimestamp(media.timestamp).split(" ")[0]}
                    </span>
                    <span css={styles.space}></span>
                    <span>
                      {formatTimestamp(media.timestamp).split(" ")[1]}
                    </span>
                  </div>
                  <a
                    href={media.permalink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconButton css={styles.noImageIconButton}>
                      <InstagramIcon css={styles.noImageIcon} />
                    </IconButton>
                  </a>
                </div>
              </>
            )}
            <div css={styles.captionContainer}>
              <div css={styles.caption}>{media.caption}</div>
            </div>
          </Grid>
          <Grid item xs={8.5} css={styles.rightGridContainer}>
            <Grid container css={styles.gridContainer}>
              {isInitialVelocityAnalysis ? (
                <span css={styles.space}></span>
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{ height: "10%" }}
                  css={styles.dateRangePickerContainer}
                >
                  <DashboardDateRangePicker
                    selectablePeriod={selectablePeriod}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{
                  height: "35%",
                  display: "flex",
                  flexWrap: "wrap",
                  paddingTop: "10px",
                }}
              >
                {metricCards?.map((card, idx) => (
                  <Grid item xs={3}>
                    <Box
                      key={idx}
                      css={styles.card}
                      className={
                        selectedMetric === card.value ? "selected" : ""
                      }
                      onClick={() => handleMetricClick(card.value)}
                    >
                      <Typography css={styles.label}>{card.label}</Typography>
                      <Typography css={styles.metrics}>
                        {card.metrics}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} sx={{ height: "55%", paddingTop: "10px" }}>
                {lineChartDataSets &&
                lineChartDataSets[selectedMetric] &&
                lineChartDataSets[selectedMetric].filter(
                  (item) => item !== null
                ).length > 0 ? (
                  <InstagramAccountMediaInsightsLineChart
                    chartDataSets={lineChartDataSets}
                    chartLabels={lineChartLabels}
                    selectedMetric={selectedMetric}
                    selectedMetricLabel={selectedMetric}
                  />
                ) : isLoading ? (
                  <Box css={styles.circularProgressIconBox}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Box css={styles.noDataBox}>
                    <Typography css={styles.noDataText}>No Data</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

const styles = {
  modal: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
    zIndex: 1,
  }),
  gridContainer: css({
    height: "100%",
  }),
  leftGridContainer: css({
    height: "100%",
    paddingRight: "16px",
    position: "relative",
    overflow: "auto",
  }),
  mediaMetadata: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px",
    position: "absolute",
    top: "5px",
    left: "5px",
    right: "10px",
  }),
  formatTimestamp: css({
    fontSize: "var(--font-size-label-small)",
    background: "#1C98C5",
    color: "white",
    width: "fit-content",
    borderRadius: "30px",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingRight: "10px",
    paddingLeft: "10px",
  }),
  IconButton: css({
    width: "30px",
    height: "30px",
    position: "absolute",
    bottom: "10px",
    right: "5px",
    background: "#1C98C5",
  }),
  Icon: css({
    width: "25px",
    height: "25px",
    color: "white",
  }),
  captionContainer: css({
    paddingTop: "10px",
  }),
  caption: css({
    whiteSpace: "pre-wrap",
    fontSize: "var(--font-size-body-medium)",
    fontWeight: 550,
    color: "#6D777A",
    overflowY: "auto",
    maxHeight: "100%",
  }),
  imageContainer: css({
    position: "relative",
  }),
  image: css({
    width: "100%",
    height: "auto",
  }),
  rightGridContainer: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  }),
  dateRangePickerContainer: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  }),
  card: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FAFAFA",
    cursor: "pointer",
    borderTop: "4px solid transparent",
    "&:hover": {
      backgroundColor: "#f4f4f4",
    },
    "&.selected": {
      backgroundColor: "white",
      borderTopColor: "#1C98C5",
    },
  }),
  label: css({
    fontSize: "var(--font-size-label-large)",
    fontWeight: "bold",
    color: "#6D777A",
  }),
  metrics: css({
    fontSize: "32px",
    fontWeight: "bold",
    color: "#1C98C5",
  }),
  circularProgressIconBox: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  }),
  noDataBox: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    backgroundColor: "#f4f4f4",
    borderRadius: "8px",
  }),
  noDataText: css({
    fontSize: "20px",
    color: "#6D777A",
  }),
  space: css({
    margin: "0 10px",
  }),
  noImageMediaMetadata: css({
    height: "10%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px",
  }),
  noImageFormatTimestamp: css({
    fontSize: "var(--font-size-label-small)",
    background: "#1C98C5",
    color: "white",
    width: "fit-content",
    borderRadius: "30px",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingRight: "10px",
    paddingLeft: "10px",
  }),
  noImageIconButton: css({ width: "30px", height: "30px" }),
  noImageIcon: css({ width: "25px", height: "25px" }),
}

export default InstagramAccountMediaModal
