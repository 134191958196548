export const industries = [
  { id: 1, name: "IT・通信" },
  { id: 2, name: "インターネット・広告・メディア" },
  { id: 3, name: "メーカー(機会・電気)" },
  { id: 4, name: "メーカー(素材・化学・食品・化粧品)" },
  { id: 5, name: "商社" },
  { id: 6, name: "医薬品・医療機器・ライフサイエンス・医療系サービス" },
  { id: 7, name: "金融 " },
  { id: 8, name: "建築・プラント・不動産" },
  {
    id: 9,
    name: "コンサルティング・専門事務所・監査法人・税理士法人・リサーチ",
  },
  { id: 10, name: "人材紹介・アウトソーシング・コールセンター" },
  { id: 11, name: "小売" },
  { id: 12, name: "運輸・物流" },
  { id: 13, name: "外食" },
  { id: 14, name: "エネルギー(電力・ガス・石油・新エネルギー)" },
  { id: 15, name: "旅行・宿泊・レジャー" },
  { id: 16, name: "警備・清掃" },
  { id: 17, name: "理容・美容・エステ" },
  { id: 18, name: "教育" },
  { id: 19, name: "農林水産・鉱業" },
  { id: 20, name: "公共・官公庁・学校・研究施設" },
  { id: 21, name: "冠婚葬祭" },
  { id: 22, name: "その他" },
]
