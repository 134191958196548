interface GlobalHeaderListItem {
  title: string
  link: string
}

export const GlobalHeaderItems: GlobalHeaderListItem[] = [
  {
    title: "ダッシュボード",
    link: "/dashboard",
  },
  {
    title: "初速分析",
    link: "/initial-velocity-analysis/feeds",
  },
  {
    title: "投稿一覧",
    link: "/post-list/feeds",
  },
  {
    title: "コンテンツ",
    link: "/llm-interaction",
  },
  {
    title: "自社データベース",
    link: "/company-database",
  },
  {
    title: "設定",
    link: "/setting",
  },
]
