import { Box, Step, StepLabel, Stepper, Typography } from "@mui/material"

interface Props {
  activeStep: number
}

export const CustomStepper = ({ activeStep }: Props) => {
  const steps = ["基本情報", "会社情報"]

  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: "25px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {steps.map((label, index) => (
          <Typography
            key={label}
            variant="caption"
            component="div"
            sx={{
              textAlign: "center",
              width: "100%",
              fontWeight:
                activeStep === index ? "var(--color-primary)" : "normal",
              color:
                activeStep === index
                  ? "var(--color-primary)"
                  : "text.secondary",
            }}
          >
            {label}
          </Typography>
        ))}
      </Box>

      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                style: {
                  color: activeStep === index ? "#1c98c5" : "#9e9e9e",
                },
              }}
            />
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
