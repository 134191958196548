import { ReactNode } from "react"
import { CurrentUserProvider } from "@/components/CurrentUserProvider"
import { UserType } from "@/types/UserType"
import { AlertProvider } from "./AlertProvider"

interface Props {
  currentUser?: UserType
  children: ReactNode
}

export const AppProvider = ({ currentUser, children }: Props) => {
  return (
    <CurrentUserProvider currentUser={currentUser}>
      <AlertProvider>{children}</AlertProvider>
    </CurrentUserProvider>
  )
}
