import React, { useState, useContext } from "react"
import { MediumLabelWithRequired } from "@/components/typography/MediumLabelWithRequired"
import { css } from "@emotion/react"
import { updatePasswordApiPath } from "@/utils/paths"
import AlertContext from "./AlertProvider"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { TextField, Button, Grid } from "@mui/material"
import PasswordRules from "@/components/PasswordRules"
import client from "@/api/client"

const SettingUpdatePassword = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const alert = useContext(AlertContext)
  const [currentPassword, setCurrentPassword] = useState<string>("")
  const [newPassword, setNewPassword] = useState<string>("")
  const [newPasswordConfirmation, setNewPasswordConfirmation] =
    useState<string>("")

  const usePasswordVisibility = () => {
    const [visible, setVisible] = useState<boolean>(false)
    const toggleVisibility = () => setVisible(!visible)
    return {
      passwordVisible: visible,
      toggleVisibility,
    }
  }

  const {
    passwordVisible: showCurrentPassword,
    toggleVisibility: toggleCurrentPasswordVisibility,
  } = usePasswordVisibility()

  const {
    passwordVisible: showNewPassword,
    toggleVisibility: toggleNewPasswordVisibility,
  } = usePasswordVisibility()

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsSubmitting(true)
    alert?.success(`パスワードを更新しています。しばらくお待ちください。`)

    const formData = new FormData()

    formData.append("currentPassword", currentPassword.replace(/\s/g, ""))
    formData.append("password", newPassword.replace(/\s/g, ""))
    formData.append(
      "passwordConfirmation",
      newPasswordConfirmation.replace(/\s/g, "")
    )

    await client
      .put(updatePasswordApiPath, formData)
      .then((response) => {
        alert?.success(`パスワードを更新しました。`)
      })
      .catch((error) => {
        alert?.error(`現在のパスワード、または新しいパスワードが一致しません。`)
      })

    setIsSubmitting(false)
    handleClearTextField()
  }

  const handleClearTextField = () => {
    setCurrentPassword("")
    setNewPassword("")
    setNewPasswordConfirmation("")
  }

  return (
    <>
      <div css={styles.container}>
        <div css={styles.currentPasswordContainer}>
          <div css={styles.textFieldLabel}>
            <MediumLabelWithRequired>現在のパスワード</MediumLabelWithRequired>
          </div>
          <FormControl css={styles.formControl} variant="outlined" fullWidth>
            <TextField
              size="small"
              type={showCurrentPassword ? "text" : "password"}
              label=""
              InputLabelProps={{ shrink: false }}
              placeholder="現在のパスワードを入力"
              value={currentPassword}
              onChange={(event) => setCurrentPassword(event.target.value)}
              css={styles.textField}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleCurrentPasswordVisibility}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: textFieldInputPropsStyle,
              }}
            />
          </FormControl>
          <PasswordRules />
        </div>
        <div css={styles.newPasswordContainer}>
          <div css={styles.textFieldLabel}>
            <MediumLabelWithRequired>新しいパスワード</MediumLabelWithRequired>
          </div>
          <FormControl css={styles.formControl} variant="outlined" fullWidth>
            <TextField
              size="small"
              type={showNewPassword ? "text" : "password"}
              label=""
              InputLabelProps={{ shrink: false }}
              placeholder="新しいパスワードを入力"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              css={styles.textField}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleNewPasswordVisibility}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: textFieldInputPropsStyle,
              }}
            />
          </FormControl>
          <PasswordRules />
        </div>
        <div css={styles.newPasswordConfirmationContainer}>
          <div css={styles.textFieldLabel}>
            <MediumLabelWithRequired>
              新しいパスワード(確認用)
            </MediumLabelWithRequired>
          </div>
          <FormControl css={styles.formControl} variant="outlined" fullWidth>
            <TextField
              size="small"
              type="password"
              label=""
              InputLabelProps={{ shrink: false }}
              placeholder="新しいパスワードを入力(確認用)"
              value={newPasswordConfirmation}
              onChange={(event) =>
                setNewPasswordConfirmation(event.target.value)
              }
              css={styles.textField}
              autoComplete="off"
              InputProps={{
                style: textFieldInputPropsStyle,
              }}
            />
          </FormControl>
          <PasswordRules />
        </div>
        <div css={styles.buttonsContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                size="large"
                onClick={handleSubmit}
                disabled={isSubmitting}
                fullWidth
                sx={{
                  backgroundColor: "var(--color-primary)",
                  height: "40px",
                }}
              >
                保存
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                size="large"
                onClick={handleClearTextField}
                disabled={isSubmitting}
                fullWidth
                sx={{
                  height: "40px",
                }}
              >
                キャンセル
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

const textFieldInputPropsStyle = {
  height: "43px",
  fontSize: "12px",
}

const styles = {
  container: css({
    width: "40%",
    padding: "20px 30px",
  }),
  currentPasswordContainer: css({
    marginBottom: 15,
  }),
  newPasswordContainer: css({
    marginBottom: 15,
  }),
  newPasswordConfirmationContainer: css({
    marginBottom: 30,
  }),
  formControl: css({
    marginBottom: 5,
  }),
  textFieldLabel: css({
    marginBottom: 5,
  }),
  textField: css({
    "& .MuiInputBase-input::placeholder": {
      color: "var(--color-text-placeholder)",
      fontSize: "var(--font-size-label-small)",
      opacity: 1,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--color-border-primary)",
    },
  }),
  buttonsContainer: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
}

export default SettingUpdatePassword
