import { InputLabel } from "@mui/material"
import { css } from "@emotion/react"
import { PropsWithChildren, ReactNode } from "react"

interface Props {
  children: ReactNode
}

export const SettingUnselectedInputLabel = ({
  children,
}: PropsWithChildren<Props>) => {
  return (
    <InputLabel
      disableAnimation
      shrink={false}
      focused={false}
      id="item_type_label"
      css={styles.inputLabel}
    >
      {children}
    </InputLabel>
  )
}

const styles = {
  inputLabel: css({
    fontSize: "var(--font-size-body-small)",
    transform: "translate(0%, 80%)",
    marginLeft: "15px",
  }),
}
