import { MenuItem } from "@mui/material"
import { styled } from "@mui/material/styles"

export const StyledMenuItem = styled(MenuItem)(() => ({
  "&.Mui-selected": {
    backgroundColor: "var(--color-white)",
    "&:after": {
      content: '"✓"',
      color: "var(--color-primary)",
      position: "absolute",
      right: "12px",
    },
  },
  "&:hover": {
    backgroundColor: "var(--color-primary)",
    color: "var(--color-white)",
    "&.Mui-selected": {
      color: "inherit",
    },
  },
}))
