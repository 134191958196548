import { useEffect, useState } from "react"
import { GenerativeContentType } from "@/types/GenerativeContentType"
import { useNavigate, useParams } from "react-router-dom"
import client from "@/api/client"
import { paths } from "@/utils/paths"
import { LlmInteractionListItemEditDialog } from "@/components/LlmInteractionListItemEditDialog"

const LlmInteractionDetailPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [generativeContent, setGenerativeContent] =
    useState<GenerativeContentType>()
  const closeDetailPage = () => {
    navigate(`/llm-interaction`)
  }

  useEffect(() => {
    const getGenerativeContent = async () => {
      await client.get(`${paths.generativeContents}/${id}`).then((response) => {
        setGenerativeContent(response.data.generativeContent)
        setIsDialogOpen(true)
      })
    }
    getGenerativeContent()
  }, [id])

  return generativeContent ? (
    <LlmInteractionListItemEditDialog
      isOpen={isDialogOpen}
      onRequestClose={closeDetailPage}
      generativeContent={generativeContent}
    />
  ) : null
}

export default LlmInteractionDetailPage
