import React, { useState } from "react"
import moment from "moment"
import { DateRangePicker } from "react-dates"
import { SelectablePeriodType } from "@/utils/helpers/dashboard"
import { css } from "@emotion/react"

import "moment/locale/ja"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "@/styles/dashboard/react_dates_overrides.css"

interface Props {
  selectablePeriod: SelectablePeriodType
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  setStartDate: (date: moment.Moment | null) => void
  setEndDate: (date: moment.Moment | null) => void
}

export const DashboardDateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  selectablePeriod,
}: Props) => {
  const [focusedInput, setFocusedInput] = useState<
    "startDate" | "endDate" | null
  >(null)

  const isOutsideRange = (day: moment.Moment) => {
    const startOfDay = day.startOf("day")
    return (
      startOfDay.isAfter(
        moment(selectablePeriod.latestTargetOn).startOf("day")
      ) ||
      startOfDay.isBefore(
        moment(selectablePeriod.oldestTargetOn).startOf("day")
      )
    )
  }

  const selectRange = (range: "week" | "month" | "quarter") => {
    const end = moment(selectablePeriod.latestTargetOn)
    let start = end.clone().subtract(1, "week")

    switch (range) {
      case "week":
        start = end.clone().subtract(1, "week")
        break
      case "month":
        start = end.clone().subtract(1, "month")
        break
      case "quarter":
        start = end.clone().subtract(3, "month")
        break
      default:
        break
    }

    if (start.isBefore(moment(selectablePeriod.oldestTargetOn))) {
      start = moment(selectablePeriod.oldestTargetOn)
    }

    setStartDate(start)
    setEndDate(end)
    setFocusedInput("endDate")
  }

  const handleRangeSelect = (
    event: React.MouseEvent<HTMLButtonElement>,
    range: "week" | "month" | "quarter"
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setFocusedInput("endDate")
    selectRange(range)
  }

  const renderButtons = () => (
    <div css={styles.rangeButtonContainer}>
      <button
        onClick={(event) => handleRangeSelect(event, "week")}
        css={styles.rangeButton}
      >
        過去1週間
      </button>
      <button
        onClick={(event) => handleRangeSelect(event, "month")}
        css={styles.rangeButton}
      >
        過去1ヶ月
      </button>
      <button
        onClick={(event) => handleRangeSelect(event, "quarter")}
        css={styles.rangeButton}
      >
        過去3ヶ月
      </button>
    </div>
  )

  return (
    <>
      <DateRangePicker
        startDate={startDate}
        startDateId="startDateId"
        endDate={endDate}
        endDateId="endDateId"
        minimumNights={0}
        isOutsideRange={isOutsideRange}
        hideKeyboardShortcutsPanel={true} // キーボードショートカットを非表示
        showDefaultInputIcon={true} //カレンダーアイコンを表示
        noBorder={true} // 枠線を非表示
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        numberOfMonths={2}
        anchorDirection={"right"}
        keepOpenOnDateSelect={true}
        onDatesChange={(selectedDates) => {
          setStartDate(selectedDates.startDate)
          setEndDate(selectedDates.endDate)
        }}
        appendToBody={true}
        renderCalendarInfo={renderButtons}
      />
    </>
  )
}

const styles = {
  rangeButtonContainer: css({
    display: "flex",
    justifyContent: "space-around",
    paddingBottom: 10,
  }),
  rangeButton: css({
    background: "none",
    color: "currentColor",
    border: "none",
    padding: 0,
    font: "inherit",
    cursor: "pointer",
    outline: "inherit",
  }),
}
