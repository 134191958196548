import React from "react"
import { Box, Slider, Typography, css } from "@mui/material"

interface Props {
  intensity: number
  setIntensity: (value: number) => void
}

export const LlmInteractionListItemEditDialogImageIntensitySlider: React.FC<
  Props
> = ({ intensity, setIntensity }) => {
  const handleSliderChange = (
    event: Event,
    newValue: number | number[]
  ): void => {
    setIntensity(newValue as number)
  }

  return (
    <Box css={styles.box}>
      <Typography id="input-slider" css={styles.typography}>
        強度
      </Typography>
      <Slider
        aria-labelledby="input-slider"
        value={intensity}
        onChange={handleSliderChange}
        min={0}
        max={100}
        sx={{
          mx: 2,
          flexGrow: 1,
          color: "#1C98C5",
          height: "8px",
          "& .MuiSlider-thumb": {
            height: "24px",
            width: "24px",
          },
          "& .MuiSlider-track": {
            height: "8px",
          },
          "& .MuiSlider-rail": {
            height: "8px",
          },
        }}
      />
    </Box>
  )
}

const styles = {
  box: css({
    width: "300px",
    margin: "20px auto",
    display: "flex",
    alignItems: "center",
  }),
  typography: css({
    width: "auto",
    fontSize: "var(--font-size-label-medium)",
    fontWeight: "bold",
    flexShrink: 0,
  }),
}
