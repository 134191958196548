import { Pie } from "react-chartjs-2"
import { TooltipItem } from "chart.js"
import "chart.js/auto"
import { css } from "@emotion/react"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { InstagramAccountFollowerDemographicsType } from "@/types/InstagramAccountFollowerDemographicsType"
import { convertToPercentage } from "@/utils/helpers/dashboard"

interface Props {
  latestInstagramAccountFollowerDemographic: InstagramAccountFollowerDemographicsType
}

export const DashboardPieChart = ({
  latestInstagramAccountFollowerDemographic,
}: Props) => {
  const percentageData = convertToPercentage([
    latestInstagramAccountFollowerDemographic.maleCount,
    latestInstagramAccountFollowerDemographic.femaleCount,
    latestInstagramAccountFollowerDemographic.genderUnknownCount,
  ])
  const data = {
    labels: ["男性", "女性", "その他"],
    datasets: [
      {
        label: "性別比率",
        data: percentageData,
        backgroundColor: ["#1C98C5", "#F0585D", "#AAB3B8"],
        borderWidth: 0,
      },
    ],
  }

  // 円グラフのオプション
  const options = {
    responsive: true,
    plugins: {
      plugins: [ChartDataLabels],
      legend: {
        display: true,
        position: "bottom" as const, // as constを使用して型エラーを解決
      },
      tooltip: {
        callbacks: {
          label: function (context: TooltipItem<"pie">) {
            let label = context.label || ""
            if (label) {
              label += ": "
            }
            if (context.parsed !== null) {
              label += `${context.parsed}%` // parsedが数値なので%を追加
            }
            return label
          },
        },
      },
      datalabels: {
        color: "#fff",
        font: {
          weight: 500,
          size: 14,
        },
        display: true,
        formatter: (value: any, context: any) => {
          const label = context.chart.data.labels
            ? context.chart.data.labels[context.dataIndex]
            : ""
          return `${label}\n${value}%`
        },
      },
    },
  }
  return (
    <div css={styles.container}>
      <div css={styles.title}>フォロワー(性別)</div>
      <Pie data={data} options={options} plugins={[ChartDataLabels as any]} />
    </div>
  )
}

const styles = {
  container: css({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }),
  title: css({
    color: "#6D777A",
    fontSize: "var(--font-size-label-large)",
    fontWeight: "bold",
    paddingBottom: "3px",
  }),
}
