import { css } from "@emotion/react"
import { PropsWithChildren, ReactNode } from "react"

interface Props {
  children: ReactNode
}

export const LargeLabel = ({ children }: PropsWithChildren<Props>) => {
  return <div css={styles.container}>{children}</div>
}

const styles = {
  container: css({
    fontWeight: "bold",
    fontSize: "var(--font-size-label-large)",
    color: "#101111",
  }),
}
