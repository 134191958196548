import { useCallback, useContext, useEffect, useState } from "react"
import client from "@/api/client"
import AlertContext from "@/components/AlertProvider"
import { MediumLabel } from "@/components/typography/MediumLabel"
import TextFieldWithCounter from "@/components/TextFieldWithCounter"
import { Box, css } from "@mui/material"
import { TeamType } from "@/types/TeamType"
import { putTeamApiPath } from "@/utils/paths"
import _ from "lodash"

type Props = {
  team: TeamType
}

type TeamPutPropsType = {
  id: string
  companyOverview?: string
  serviseDiscription?: string
}

const CompanyDatabaseCompanyInformation = ({ team }: Props) => {
  const alert = useContext(AlertContext)
  const [teamDetail, setTeamDetail] = useState<TeamPutPropsType>({
    id: team.id,
  })
  const [isInitialRender, setIsInitialRender] = useState(true)

  const handleSubmit = useCallback(async () => {
    const formData = new FormData()
    formData.append("data", JSON.stringify(teamDetail))

    await client
      .put(putTeamApiPath(team.id), formData)
      .then((response) => {
        alert?.success(`自社情報を更新しました`)
      })
      .catch((error) => {
        alert?.error(`500字以内で入力してください`)
      })
  }, [alert, team.id, teamDetail])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    _.debounce(() => handleSubmit(), 1000),
    [handleSubmit]
  )

  const handleChange = useCallback(
    (hash: { [key: string]: string | string[] }) => {
      setTeamDetail({
        ...teamDetail,
        ...hash,
      })
    },
    [teamDetail]
  )

  useEffect(() => {
    if (isInitialRender) {
      // 初回のレンダリング時に更新しないようにする処理
      setIsInitialRender(false)
    } else {
      debouncedSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamDetail])

  return (
    <>
      <div css={styles.companyInformationContainer}>
        <Box mb={2}>
          <div css={styles.companyInformation}>
            <MediumLabel>会社概要</MediumLabel>
            <TextFieldWithCounter
              initialValue={team.companyOverview || ""}
              onChange={(value) => handleChange({ companyOverview: value })}
            />
          </div>
        </Box>
        <div css={styles.companyInformation}>
          <MediumLabel>サービス内容</MediumLabel>
          <TextFieldWithCounter
            initialValue={team.serviseDiscription || ""}
            onChange={(value) => handleChange({ serviseDiscription: value })}
          />
        </div>
      </div>
    </>
  )
}

const styles = {
  companyInformationContainer: css({
    paddingTop: 20,
    paddingBottom: 10,
    position: "relative",
  }),
  companyInformation: css({
    width: "95%",
    margin: "0 auto",
    overflow: "visible",
  }),
  searchArea: css({
    margin: "30px 0",
  }),
  nameLabel: css({
    minWidth: 120,
  }),
  pagination: css({
    borderTop: "1px solid var(--color-gray-lightest)",
  }),
  noData: css({
    textAlign: "center",
  }),
  loading: css({
    textAlign: "center",
  }),
  tags: css({
    display: "flex",
    gap: 10,
    marginTop: 10,
    flexWrap: "wrap",
  }),
  tagsItem: css({
    border: "1px solid var(--color-darkgray-lightest)",
    padding: "5px 15px",
    borderRadius: 30,
    fontSize: "var(--font-size-xsmall-1)",
    cursor: "pointer",
    backgroundColor: "var(--color-primary)",
    color: "var(--color-white)",
  }),
}

export default CompanyDatabaseCompanyInformation
