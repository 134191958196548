import { css, TableCell, TableRow, IconButton } from "@mui/material"
import { useCallback, useContext, useState } from "react"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { ProductType } from "@/types/ProductType"
import { CompanyDatabaseListItemEditDialog } from "@/components/CompanyDatabaseListItemEditDialog"
import { useNavigate } from "react-router-dom"
import client from "@/api/client"
import { deleteProductApiPath } from "@/utils/paths"
import AlertContext from "@/components/AlertProvider"
import NoDataImage from "@/images/llm_no_data.png"
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal"

interface Props {
  product: ProductType
  newProductId: string
}

const CompanyDatabaseListItem = ({
  product: initialcompanyProduct,
  newProductId,
}: Props) => {
  const alert = useContext(AlertContext)
  const [product, setProduct] = useState<ProductType>(initialcompanyProduct)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(
    product.id === newProductId
  )
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const handleDialogModalClose = useCallback(() => {
    setIsDialogOpen(false)
  }, [])
  const displayImage = product.productImages[0]
  const navigate = useNavigate()

  const handleDelete = useCallback(async () => {
    await client
      .delete(deleteProductApiPath(product.id))
      .then((response) => {
        setIsDeleteModalOpen(false)
        navigate(0)
        alert?.success(`${product.name}を削除しました`)
      })
      .catch((error) => {
        alert?.error(`${product.name}を削除できませんでした`)
      })
  }, [alert, product.name, product.id, navigate])

  return (
    <>
      <TableRow
        key={product.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        onClick={() => setIsDialogOpen(true)}
        css={styles.tableRow}
      >
        <TableCell
          align="left"
          padding="none"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 30,
            paddingTop: 6,
            paddingBottom: 6,
          }}
          css={styles.formattedTableCell}
        >
          <img
            src={displayImage ? displayImage.imageUrl : NoDataImage}
            alt=""
            style={{
              flexShrink: 0,
              marginRight: "10px",
              width: "60px",
              height: "60px",
              border: "1px solid #D4DFE6",
            }}
          />
          <span
            style={{
              fontSize: "var(--font-size-body-large)",
              fontWeight: "var(--font-weight-medium)",
              color: "var(--color-text-black)",
            }}
          >
            {product.name.length > 30
              ? `${product.name.substring(0, 30)}...`
              : product.name}
          </span>
        </TableCell>
        <TableCell
          align="left"
          padding="none"
          style={{ paddingLeft: 30, fontSize: "var(--font-size-body-small)" }}
          css={styles.formattedTableCell}
        >
          {product.description &&
          (product.description.length > 100 ||
            product.description.split("\n").length > 3)
            ? `${product.description
                .split("\n")
                .slice(0, 3)
                .join("\n")
                .substring(0, 100)}...`
            : product.description}
        </TableCell>
        <TableCell
          align="left"
          padding="none"
          style={{ paddingLeft: 30, fontSize: "var(--font-size-body-medium)" }}
          css={styles.formattedTableCell}
        >
          {product.updatedAt}
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={(event) => {
              event.stopPropagation()
              setIsDeleteModalOpen(true)
            }}
            style={{ marginLeft: "10px" }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <CompanyDatabaseListItemEditDialog
        product={product}
        isOpen={isDialogOpen}
        onChange={setProduct}
        onRequestClose={handleDialogModalClose}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}

const styles = {
  tableRow: css({
    cursor: "pointer",
  }),
  formattedTableCell: css({
    whiteSpace: "pre-wrap",
    minWidth: 300,
    height: "76px",
  }),
}

export default CompanyDatabaseListItem
