import { useCallback, useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  css,
  Box,
  Button,
} from "@mui/material"
import LlmInteractionListItem from "@/components/LlmInteractionListItem"
import WidgetsIcon from "@mui/icons-material/Widgets"
import { PageMainTitle } from "@/components/typography/PageMainTitle"
import { HEADER_HEIGHT } from "@/components/GlobalHeader"
import LlmNoDataImage from "@/images/llm_no_data.png"
import { LargeLabel } from "@/components/typography/LargeLabel"
import client from "@/api/client"
import { paths } from "@/utils/paths"
import { GenerativeContentType } from "@/types/GenerativeContentType"
import AlertContext from "@/components/AlertProvider"

const LlmInteractionListContainer: React.FC = () => {
  const alert = useContext(AlertContext)
  const [isLoading] = useState(false)
  const navigate = useNavigate()
  const [generativeContents, setGenerativeContents] = useState<
    GenerativeContentType[]
  >([])

  const handleDraftContentCreate = useCallback(async () => {
    const formData = new FormData()
    formData.append("item_type", "posts")
    formData.append("title", "新規コンテンツ")

    client.post(paths.generativeContents, formData).then((response) => {
      setGenerativeContents((prevContents) => [
        ...prevContents,
        response.data.generativeContent,
      ])
      navigate(`/llm-interaction/${response.data.generativeContent.id}`)
    })
  }, [navigate])

  const handleDeleteGenerativeContent = useCallback(
    async (id: string) => {
      const filteredGenerativeContents = generativeContents.filter(
        (response) => response.id !== id
      )
      client.delete(`${paths.generativeContents}/${id}`).then(() => {
        setGenerativeContents(filteredGenerativeContents)
        alert?.success("削除しました")
      })
    },
    [alert, generativeContents]
  )

  useEffect(() => {
    const getGenerativeContents = async () => {
      await client.get(paths.generativeContents).then((response) => {
        setGenerativeContents(response.data.generativeContents)
      })
    }

    getGenerativeContents()
  }, [])

  return (
    <>
      <div css={styles.pageTitleContainer}>
        <div css={styles.pageTitle}>
          <PageMainTitle>コンテンツ</PageMainTitle>
        </div>
        <Button
          variant="contained"
          onClick={handleDraftContentCreate}
          css={styles.button}
        >
          ＋ 新規作成
        </Button>
      </div>
      <div css={styles.pageBodyContainer}>
        <div>
          <Box mt={2}>
            {generativeContents ? (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          css={styles.nameLabel}
                          padding="none"
                          style={{
                            paddingLeft: 30,
                            width: "35%",
                            color: "var(--color-border-gray)",
                          }}
                        >
                          タイトル
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="none"
                          style={{
                            width: "25%",
                            color: "var(--color-border-gray)",
                            paddingLeft: 30,
                          }}
                          css={css`
                            &.MuiTableCell-root {
                              border-left: 1px solid rgba(224, 224, 224, 1);
                            }
                          `}
                        >
                          メタデータ
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="none"
                          style={{
                            width: "20%",
                            color: "var(--color-border-gray)",
                            paddingLeft: 30,
                          }}
                          css={css`
                            &.MuiTableCell-root {
                              border-left: 1px solid rgba(224, 224, 224, 1);
                            }
                          `}
                        >
                          更新日
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {generativeContents.length > 0 && (
                      <TableBody>
                        {generativeContents.map((generativeContent) => {
                          return (
                            <>
                              <LlmInteractionListItem
                                key={generativeContent.id}
                                generativeContent={generativeContent}
                                onDelete={handleDeleteGenerativeContent}
                              />
                            </>
                          )
                        })}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                {generativeContents.length === 0 && (
                  <div css={styles.noDataImageContainer}>
                    <img src={LlmNoDataImage} css={styles.noDataImage} alt="" />
                    <LargeLabel>コンテンツがありません</LargeLabel>
                  </div>
                )}
              </>
            ) : (
              <>
                {isLoading ? (
                  <div css={styles.loading}>
                    <WidgetsIcon fontSize="large" />
                    <p>Loading...</p>
                  </div>
                ) : (
                  <div css={styles.noDataImageContainer}>
                    <img src={LlmNoDataImage} css={styles.noDataImage} alt="" />
                    <LargeLabel>コンテンツがありません</LargeLabel>
                  </div>
                )}
              </>
            )}
          </Box>
        </div>
      </div>
    </>
  )
}

const styles = {
  pageTitleContainer: css({
    position: "relative",
    borderBottom: "1px solid var(--color-border-primary)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 30px",
    height: HEADER_HEIGHT,
  }),
  pageTitle: css({
    overflow: "visible",
  }),
  pageBodyContainer: css({
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_HEIGHT}px)`,
    overflowY: "auto",
  }),
  button: css({
    paddingX: 7,
    backgroundColor: "var(--color-button-primary)",
    borderRadius: 5,
    color: "var(--color-white))",
    fontWeight: "bold",
    fontSize: "var(--font-size-label-medium)",
  }),
  nameLabel: css({
    minWidth: 120,
  }),
  noDataImageContainer: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "100px",
    gap: "30px",
  }),
  noDataImage: css({
    width: "280px",
    height: "280px",
  }),
  loading: css({
    textAlign: "center",
  }),
}

export default LlmInteractionListContainer
