import { InputLabel } from "@mui/material"
import { css } from "@emotion/react"

export const UnselectedInputLabel = () => {
  return (
    <InputLabel
      disableAnimation
      shrink={false}
      focused={false}
      id="item_type_label"
      css={styles.inputLabel}
    >
      未選択
    </InputLabel>
  )
}

const styles = {
  inputLabel: css({
    fontSize: "var(--font-size-body-small)",
    transform: "translate(30%, 50%)",
  }),
}
