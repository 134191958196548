import client from "@/api/client"

import { SignUpParams, SignInParams } from "@/interfaces/index"

export const signUp = (params: SignUpParams) => {
  return client.post("auth", params)
}

export const signIn = (params: SignInParams) => {
  return client.post("auth/sign_in", params)
}

export const signOut = () => {
  return client.delete("auth/sign_out")
}

export const getCurrentUser = () => {
  return client.get("auth/user/users")
}
