import React, { useState, useEffect } from "react"
import TextField from "@mui/material/TextField"
import { css } from "@emotion/react"

interface TextFieldWithCounterProps {
  initialValue: string
  onChange?: (text: string) => void
}

const TextFieldWithCounter: React.FC<TextFieldWithCounterProps> = ({
  initialValue,
  onChange,
}) => {
  const [text, setText] = useState(initialValue)
  useEffect(() => {
    setText(initialValue)
  }, [initialValue])
  const textLength = text.length
  const isOverLimit = textLength > 500
  const textColor = isOverLimit ? "red" : "inherit"
  const counterColor = isOverLimit ? "red" : "var(--color-border-counter)"
  const borderColor = isOverLimit ? "red" : "var(--color-border-primary)"

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value
    setText(newText)
    if (onChange) {
      onChange(newText)
    }
  }

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
      `}
    >
      <TextField
        defaultValue={initialValue}
        onChange={handleTextChange}
        rows={6}
        multiline
        fullWidth
        variant="outlined"
        placeholder="500文字以内でサービス内容について説明文を入れてください。
箇条書きでも構いません。"
        inputProps={{
          style: {
            fontSize: "12px",
            color: textColor,
            marginBottom: "30px",
          },
        }}
        css={css`
          & .MuiOutlinedInput-root {
            fieldset {
              border-color: ${borderColor};
            }
            &:hover fieldset {
              border-color: ${borderColor};
            }
            &.Mui-focused fieldset {
              border-color: ${borderColor};
            }
          }
        `}
      />
      <div
        css={css`
          position: absolute;
          bottom: 10px; // TextField内の下からの位置
          right: 20px; // TextField内の右からの位置
          color: ${counterColor};
        `}
      >
        {textLength}/500
      </div>
    </div>
  )
}

export default TextFieldWithCounter
