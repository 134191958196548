import { Grid, Card, css } from "@mui/material"
import PostAddIcon from "@mui/icons-material/PostAdd"
import ImageIcon from "@mui/icons-material/Image"

interface Props {
  setItemType: (itemType: string) => void
  itemType: string
}

const LlmInteractionListItemEditDialogItemTypeCards = ({
  setItemType,
  itemType,
}: Props) => {
  const isActive = (type: string) => itemType === type

  return (
    <>
      <div css={styles.container}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Card
              // onClick={() => setItemType("posts_and_images")}
              css={
                isActive("posts_and_images")
                  ? styles.activeItemCard
                  : styles.itemCard
              }
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                padding={1}
                spacing={0}
              >
                <Grid item>
                  <PostAddIcon
                    css={
                      isActive("posts_and_images")
                        ? styles.activeItemTypeIcon
                        : styles.itemTypeIcon
                    }
                  />
                  <ImageIcon
                    css={
                      isActive("posts_and_images")
                        ? styles.activeItemTypeIcon
                        : styles.itemTypeIcon
                    }
                  />
                </Grid>
                <Grid item>
                  <div
                    css={
                      isActive("posts_and_images")
                        ? styles.activeItemTypeText
                        : styles.itemTypeText
                    }
                  >
                    投稿生成+画像生成
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              onClick={() => setItemType("posts")}
              css={isActive("posts") ? styles.activeItemCard : styles.itemCard}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                padding={1}
                spacing={0}
              >
                <Grid item>
                  <PostAddIcon
                    css={
                      isActive("posts")
                        ? styles.activeItemTypeIcon
                        : styles.itemTypeIcon
                    }
                  />
                </Grid>
                <Grid item>
                  <div
                    css={
                      isActive("posts")
                        ? styles.activeItemTypeText
                        : styles.itemTypeText
                    }
                  >
                    投稿生成
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card
              // onClick={() => setItemType("images")}
              css={isActive("images") ? styles.activeItemCard : styles.itemCard}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                padding={1}
                spacing={0}
              >
                <Grid item>
                  <ImageIcon
                    css={
                      isActive("images")
                        ? styles.activeItemTypeIcon
                        : styles.itemTypeIcon
                    }
                  />
                </Grid>
                <Grid item>
                  <div
                    css={
                      isActive("images")
                        ? styles.activeItemTypeText
                        : styles.itemTypeText
                    }
                  >
                    画像生成
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const styles = {
  container: css({ marginBottom: "30px" }),
  itemTypeIcon: css({
    fontSize: 24,
    color: "#B4B4B4",
  }),
  itemTypeText: css({
    color: "#B4B4B4",
    fontSize: "var(--font-size-label-xsmall)",
    fontWeight: "var(--font-weight-medium)",
  }),
  itemCard: css({
    border: "1px solid #E0E0E0",
    backgroundColor: "#E0E0E0",
    borderRadius: "15px",
    height: "62px",
    cursor: "pointer",
  }),
  activeItemTypeIcon: css({
    fontSize: 24,
    color: "#FFFFFF",
  }),
  activeItemTypeText: css({
    color: "#FFFFFF",
    fontSize: "var(--font-size-label-xsmall)",
    fontWeight: "bold",
  }),
  activeItemCard: css({
    border: "1px solid #1C98C5",
    backgroundColor: "#1C98C5",
    borderRadius: "15px",
    height: "62px",
    cursor: "pointer",
  }),
}

export default LlmInteractionListItemEditDialogItemTypeCards
