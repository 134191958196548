export type LineChartDataSets = {
  followers: number[]
  reach: number[]
  likes: number[]
  saves: number[]
  profileViews: number[]
  urlClicks: number[]
}

export type WeeklyHourlyOnlineFollowersDataType = {
  monday: number[]
  tuesday: number[]
  wednesday: number[]
  thursday: number[]
  friday: number[]
  saturday: number[]
  sunday: number[]
}

export type SelectablePeriodType = {
  oldestTargetOn: Date | null
  latestTargetOn: Date | null
}

// @note 合計で100パーセントになるように配列を変換する
export const convertToPercentage = (data: number[]) => {
  const total = data.reduce((sum: number, current: number) => sum + current, 0)
  if (total === 0) return data.map(() => 0) // 全て0の場合は0%を返す

  const percentages = data.map((count: number) => (count / total) * 100)
  const roundedPercentages = percentages.map((percentage: number) =>
    Math.floor(percentage)
  )
  const sumOfRounded = roundedPercentages.reduce(
    (sum, current) => sum + current,
    0
  )

  // 誤差を修正
  let error = 100 - sumOfRounded
  const errorSign = Math.sign(error)
  const indices = percentages
    .map((_, index) => index)
    .sort((a, b) => errorSign * ((percentages[b] % 1) - (percentages[a] % 1))) // 小数部分でソート

  for (let i = 0; error !== 0 && i < indices.length; i++) {
    roundedPercentages[indices[i]] += errorSign
    error -= errorSign
  }

  return roundedPercentages
}
