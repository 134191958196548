import React, { useState } from "react"
import { Line } from "react-chartjs-2"
import { TooltipItem } from "chart.js"
import "chart.js/auto" // Chart.jsの自動登録に必要
import { Button, Menu, MenuItem, css } from "@mui/material"

interface Option {
  value: string
  label: string
}

interface DataSets {
  followers: number[]
  reach: number[]
  likes: number[]
  saves: number[]
  profileViews: number[]
  urlClicks: number[]
  [key: string]: number[]
}

interface Props {
  chartDataSets: DataSets
  chartLabels: string[]
}

const optionsList: Option[] = [
  { value: "followers", label: "フォロワー" },
  { value: "reach", label: "リーチ" },
  { value: "likes", label: "いいね" },
  { value: "saves", label: "保存" },
  { value: "profileViews", label: "プロフィールビュー" },
  { value: "urlClicks", label: "URLクリック" },
]

export const DashboardLineChart = ({ chartDataSets, chartLabels }: Props) => {
  const [selectedDataset, setSelectedDataset] = useState("followers")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (value: string) => {
    setSelectedDataset(value)
    handleClose()
  }

  const selectedOption = optionsList.find(
    (option) => option.value === selectedDataset
  )
  const datasetLabel = selectedOption
    ? selectedOption.label
    : "データセットなし"

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: datasetLabel,
        data: chartDataSets[selectedDataset],
        fill: true,
        backgroundColor: "#EFF4F6",
        borderColor: "#1C98C5",
        pointBackgroundColor: ["#1C98C5"],
      },
    ],
  }

  const filteredData = chartDataSets[selectedDataset].filter(
    (value) => value > 0
  )
  const minDataValue = Math.min(...filteredData)
  const maxDataValue = Math.max(...filteredData)
  const maxYAxisValue = Math.ceil(maxDataValue * 1.1)
  const minYAxisValue = Math.floor(minDataValue * 0.9)

  const calculateYAxisTicks = () => {
    let maxYValue = maxYAxisValue
    let minYValue = minYAxisValue
    let stepSize = Math.ceil(maxYValue * 0.1)
    return {
      beginAtZero: false,
      suggestedMax: maxYValue,
      suggestedMin: minYValue,
      stepSize: stepSize,
    }
  }

  const options = {
    responsive: true, // レスポンシブ対応
    maintainAspectRatio: false, // グラフのアスペクト比を維持しない
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          // ツールチップのタイトルをカスタマイズ
          title: function (context: TooltipItem<"line">[]) {
            // context[0].labelは、x軸のラベルです
            return `${context[0].label}`
          },
          // ツールチップの本文をカスタマイズ
          label: function (context: TooltipItem<"line">) {
            // context.parsed.yは、y軸の値
            const value = context.parsed.y
            return `${value}人`
          },
        },
        backgroundColor: "rgba(0,0,0,0.8)", // 背景色のカスタマイズ
        bodySpacing: 5, // 本文のスペーシング
      },
    },
    scales: {
      y: calculateYAxisTicks(),
    },
  }

  return (
    <>
      <div css={styles.container}>
        <Button onClick={handleClick} css={styles.datasetLabelButton}>
          {datasetLabel} ∨
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {optionsList.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => handleMenuItemClick(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        <Line data={data} options={options} />
      </div>
    </>
  )
}

const styles = {
  container: css({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }),
  datasetLabelButton: css({
    color: "#6D777A",
    fontSize: "var(--font-size-label-large)",
    fontWeight: "bold",
  }),
}
