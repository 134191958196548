import { Suspense } from "react"
import { useRecoilValue } from "recoil"
import { loadingAtom } from "@/atoms/LoadingAtom"
import GlobalHeader from "@/components/GlobalHeader"
import { Routes, Route } from "react-router-dom"
import SigninPage from "@/pages/signin/index"
import SignupPage from "@/pages/signup/index"
import AuthFacebookCallbackPage from "@/pages/authFacebookCallback/index"
import AuthConfirmationCompletePage from "@/pages/authConfirmationComplete/index"
import ThanksForRegistrationPage from "@/pages/thanksForRegistration/index"
import CompanyDatabasePage from "@/pages/companyDatabase"
import LlmInteractionPage from "@/pages/llmInteraction"
import LlmInteractionDetailPage from "@/pages/llmInteraction/detail"
import InitialVelocityAnalysisFeedsPage from "@/pages/initialVelocityAnalysis/feeds"
import InitialVelocityAnalysisReelsPage from "@/pages/initialVelocityAnalysis/reels"
import InitialVelocityAnalysisStoriesPage from "@/pages/initialVelocityAnalysis/stories"
import PostListFeedsPage from "@/pages/postList/feeds"
import PostListReelsPage from "@/pages/postList/reels"
import SettingPage from "@/pages/setting"
import DashboardPage from "@/pages/dashboard"

interface Props {
  isSignedIn: boolean
  isPublic: boolean
  setIsSignedIn: (isSignedIn: boolean) => void
}
export const AppBaseContainer = ({
  isSignedIn,
  isPublic,
  setIsSignedIn,
}: Props) => {
  const isLoading = useRecoilValue<boolean>(loadingAtom)
  return (
    <div>
      {isSignedIn && <GlobalHeader setIsSignedIn={setIsSignedIn} />}
      {isLoading ? (
        <></>
      ) : isSignedIn || isPublic ? (
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="signin" element={<SigninPage />} />
            <Route path="signup" element={<SignupPage />} />
            <Route path="auth_facebook_callback" element={<AuthFacebookCallbackPage />} />
            <Route
              path="thanks_for_registration"
              element={<ThanksForRegistrationPage />}
            />
            <Route
              path="auth_confirmation_complete"
              element={<AuthConfirmationCompletePage />}
            />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="/post-list" element={<PostListFeedsPage />}>
              <Route index element={<PostListFeedsPage />} />
              <Route path="feeds" element={<PostListFeedsPage />} />
              <Route path="reels" element={<PostListReelsPage />} />
            </Route>
            <Route
              path="/initial-velocity-analysis"
              element={<InitialVelocityAnalysisFeedsPage />}
            >
              <Route index element={<InitialVelocityAnalysisFeedsPage />} />
              <Route
                path="feeds"
                element={<InitialVelocityAnalysisFeedsPage />}
              />
              <Route
                path="reels"
                element={<InitialVelocityAnalysisReelsPage />}
              />
              <Route
                path="stories"
                element={<InitialVelocityAnalysisStoriesPage />}
              />
            </Route>
            <Route path="company-database" element={<CompanyDatabasePage />} />
            <Route path="llm-interaction" element={<LlmInteractionPage />} />
            <Route
              path="/llm-interaction/:id"
              element={<LlmInteractionDetailPage />}
            />
            <Route path="setting" element={<SettingPage />} />
          </Routes>
        </Suspense>
      ) : (
        <></>
      )}
    </div>
  )
}
