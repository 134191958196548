import { css } from "@emotion/react"

interface Props {
  text: string
}

export const EscapeText = ({ text }: Props) => {
  return (
    <>
      {text.split("\n").map((splittedText) => (
        <div key={splittedText} css={styles.margin}>
          {splittedText}
        </div>
      ))}
    </>
  )
}

const styles = {
  margin: css({
    margin: "10px 0",
    lineHeight: "1.5",
  }),
}
