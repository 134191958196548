import { useEffect, useState } from "react"
import client from "@/api/client"
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  css,
  Box,
  Grid,
} from "@mui/material"
import { DashboardDateRangePicker } from "@/components/DashboardDateRangePicker"
import PostListCollectionItem from "@/components/PostListCollectionItem"
import { SelectablePeriodType } from "@/utils/helpers/dashboard"
import { apiPaths } from "@/utils/paths"
import moment from "moment"
import { CircularProgress } from "@mui/material"
import { InstagramAccountMediumType } from "@/types/InstagramAccountMediumType"
import { FeedTableHeaderRowComponent } from "@/components/ui/PostList/feeds/FeedTableHeaderRowComponent"
import { ReelsTableHeaderRowComponent } from "@/components/ui/PostList/reels/ReelsTableHeaderRowComponent"
import { StoryTableHeaderRowComponent } from "@/components/ui/PostList/stories/StoryTableHeaderRowComponent"

interface Props {
  mediaProductType: string
  isInitialVelocityAnalysis: boolean
}

const PostListCollectionContainer = ({
  mediaProductType,
  isInitialVelocityAnalysis,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const [instagramAccountMedias, setInstagramAccountMedias] =
    useState<InstagramAccountMediumType[]>()
  const [selectablePeriod, setSelectablePeriod] =
    useState<SelectablePeriodType>({
      oldestTargetOn: null,
      latestTargetOn: null,
    })
  const [startDate, setStartDate] = useState<moment.Moment | null>(null)
  const [endDate, setEndDate] = useState<moment.Moment | null>(null)

  useEffect(() => {
    if (!startDate && !endDate) {
      setIsLoading(true)
    }
    const params =
      startDate && endDate
        ? { startDate, endDate, mediaProductType, isInitialVelocityAnalysis }
        : { mediaProductType, isInitialVelocityAnalysis }
    client
      .get(apiPaths.instagramAccountMediaPath, { params })
      .then((response) => {
        if (response.status === 200) {
          setSelectablePeriod(response.data.selectablePeriod)
          if (!startDate && !endDate) {
            setStartDate(
              response.data.initialPeriod.startDate
                ? moment(response.data.initialPeriod.startDate)
                : null
            )
            setEndDate(
              response.data.initialPeriod.endDate
                ? moment(response.data.initialPeriod.endDate)
                : null
            )
          }
          setInstagramAccountMedias(response.data.instagramAccountMedias)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        const errorMessages: Record<string, string> = {
          "No Instagram account found for the current user.":
            "Facebookを連携することで表示されます。",
          "No media found for the current Instagram account.":
            "該当するデータがありません。",
        }

        if (error.response && error.response.data.error) {
          setErrorMessage(errorMessages[error.response.data.error])
        }
      })
  }, [startDate, endDate, mediaProductType, isInitialVelocityAnalysis])

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    )
  }
  if (errorMessage) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "80%" }}
      >
        <p>{errorMessage}</p>
      </Grid>
    )
  }
  return (
    <>
      <div>
        <div css={styles.DatePickerContainer}>
          <DashboardDateRangePicker
            selectablePeriod={selectablePeriod}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>
        {instagramAccountMedias && (
          <>
            <Box mt={1}>
              <TableContainer>
                <Table>
                  <TableHead>
                    {mediaProductType === "FEED" && (
                      <FeedTableHeaderRowComponent />
                    )}
                    {mediaProductType === "REELS" && (
                      <ReelsTableHeaderRowComponent />
                    )}
                    {mediaProductType === "STORY" && (
                      <StoryTableHeaderRowComponent />
                    )}
                  </TableHead>
                  {instagramAccountMedias &&
                  instagramAccountMedias.length > 0 ? (
                    <TableBody>
                      {instagramAccountMedias.map((instagramAccountMedia) => {
                        return (
                          <PostListCollectionItem
                            instagramAccountMedia={instagramAccountMedia}
                            isInitialVelocityAnalysis={
                              isInitialVelocityAnalysis
                            }
                            mediaProductType={mediaProductType}
                          />
                        )
                      })}
                    </TableBody>
                  ) : null}
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </div>
    </>
  )
}

const styles = {
  DatePickerContainer: css({
    paddingTop: 10,
    paddingRight: 30,
    marginRight: "auto",
    textAlign: "right",
  }),
  noData: css({
    textAlign: "center",
  }),
}

export default PostListCollectionContainer
