import { Bar } from "react-chartjs-2"
import { TooltipItem } from "chart.js"
import "chart.js/auto" // Chart.jsの自動登録に必要
import { css } from "@emotion/react"
import { InstagramAccountFollowerDemographicsType } from "@/types/InstagramAccountFollowerDemographicsType"

interface Props {
  latestInstagramAccountFollowerDemographic: InstagramAccountFollowerDemographicsType
}

export const DashboardBarChart = ({
  latestInstagramAccountFollowerDemographic,
}: Props) => {
  const data = {
    labels: ["13-17", "18-24", "25-34", "35-44", "45-54", "55-64", "65+"],
    datasets: [
      {
        label: "年齢層",
        data: [
          latestInstagramAccountFollowerDemographic.age_13_17Count,
          latestInstagramAccountFollowerDemographic.age_18_24Count,
          latestInstagramAccountFollowerDemographic.age_25_34Count,
          latestInstagramAccountFollowerDemographic.age_35_44Count,
          latestInstagramAccountFollowerDemographic.age_45_54Count,
          latestInstagramAccountFollowerDemographic.age_55_64Count,
          latestInstagramAccountFollowerDemographic.ageOver_65Count,
        ],
        backgroundColor: ["#1C98C5"],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false, // グラフのアスペクト比を維持しない
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: TooltipItem<"bar">) {
            let label = context.dataset.label || ""
            if (label) {
              label += ": "
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y}人`
            }
            return label
          },
        },
      },
    },
  }
  return (
    <div css={styles.container}>
      <div css={styles.title}>フォロワー(年齢)</div>
      <Bar data={data} options={options} />
    </div>
  )
}

const styles = {
  container: css({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }),
  title: css({
    color: "#6D777A",
    fontSize: "var(--font-size-label-large)",
    fontWeight: "bold",
  }),
}
