import { css } from "@emotion/react"
import LlmInteractionListContainer from "@/components/LlmInteractionListContainer"

const LlmInteractionPage = () => {
  return (
    <>
      <div css={styles.container}>
        <LlmInteractionListContainer />
      </div>
    </>
  )
}

export default LlmInteractionPage

const styles = {
  container: css({}),
}
