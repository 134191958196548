import PostListCollectionContainer from "@/components/PostListCollectionContainer"

interface Props {
  tabValue: string
  isInitialVelocityAnalysis: boolean
}

export const PostListContents = ({
  tabValue,
  isInitialVelocityAnalysis,
}: Props) => {
  switch (tabValue) {
    case "feeds":
      return (
        <PostListCollectionContainer
          mediaProductType="FEED"
          isInitialVelocityAnalysis={isInitialVelocityAnalysis}
        />
      )
    case "reels":
      return (
        <PostListCollectionContainer
          mediaProductType="REELS"
          isInitialVelocityAnalysis={isInitialVelocityAnalysis}
        />
      )
    case "stories":
      return (
        <PostListCollectionContainer
          mediaProductType="STORY"
          isInitialVelocityAnalysis={isInitialVelocityAnalysis}
        />
      )
    default:
      return (
        <PostListCollectionContainer
          mediaProductType="FEED"
          isInitialVelocityAnalysis={isInitialVelocityAnalysis}
        />
      )
  }
}
