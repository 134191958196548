import client from "@/api/client"
import { InstagramAccountType } from "@/types/InstagramAccountType"
import { css } from "@emotion/react"
import { useContext, useEffect, useState } from "react"
import Avatar from "@mui/material/Avatar"
import { Card, Typography, Button } from "@mui/material"
import { LargeLabel } from "@/components/typography/LargeLabel"
import { SettingIntegrationExplanation } from "@/components/SettingIntegrationExplanation"
import AlertContext from "./AlertProvider"

export const SettingIntegration = () => {
  const alert = useContext(AlertContext)
  const [instagramAccounts, setInstagramAccounts] = useState<
    InstagramAccountType[]
  >([])

  const handleClick = () => {
    client.post("/integrations/facebook").then((response) => {
      if (response.status === 200) {
        window.location.href = response.data.url
      }
    })
  }

  const handleCancelClick = () => {
    client
      .delete(`/instagram_accounts/${instagramAccounts[0].id}`)
      .then((_response) => {
        alert?.success("連携を解除しました")
        setInstagramAccounts([])
      })
  }

  useEffect(() => {
    client.get("/instagram_accounts").then((response) => {
      if (response.status === 200) {
        setInstagramAccounts(response.data.instagramAccounts.instagramAccounts)
      }
    })
  }, [])

  return (
    <div css={styles.container}>
      <div css={styles.integrationArea}>
        {instagramAccounts.length > 0 ? (
          <>
            <div css={styles.accountKind}>
              <LargeLabel>Instagramアカウント</LargeLabel>
            </div>
            {instagramAccounts.map((instagramAccount) => (
              <>
                <Card css={cardStyle} key={instagramAccount.id}>
                  <Avatar
                    css={avatarStyle}
                    src={instagramAccount.profilePictureUrl}
                  />
                  <div css={infoContainerStyle}>
                    <Typography
                      variant="subtitle2"
                      css={insightsItemStyle.userName}
                    >
                      {instagramAccount.username}
                    </Typography>
                    <Typography variant="body2" css={insightsItemStyle.name}>
                      {instagramAccount.name}
                    </Typography>
                    {instagramAccount.instagramAccountInsights &&
                      instagramAccount.instagramAccountInsights.length > 0 && (
                        <>
                          <div css={insightsStyle}>
                            {/* <Typography variant="body2">
                              <span css={insightsItemStyle.number}>
                                {
                                  instagramAccount.instagramAccountInsights[
                                    instagramAccount.instagramAccountInsights
                                      .length - 1
                                  ].mediaCount
                                }
                              </span>
                              <span css={insightsItemStyle.text}>投稿</span>
                            </Typography> */}
                            <Typography variant="body2">
                              <span css={insightsItemStyle.number}>
                                {
                                  instagramAccount.instagramAccountInsights[
                                    instagramAccount.instagramAccountInsights
                                      .length - 1
                                  ].followersCount
                                }
                              </span>
                              <span css={insightsItemStyle.text}>
                                フォロワー
                              </span>
                            </Typography>
                            {/* <Typography variant="body2">
                              <span css={insightsItemStyle.number}>
                                {
                                  instagramAccount.instagramAccountInsights[
                                    instagramAccount.instagramAccountInsights
                                      .length - 1
                                  ].followsCount
                                }
                              </span>
                              <span css={insightsItemStyle.text}>
                                フォロー中
                              </span>
                            </Typography> */}
                          </div>
                        </>
                      )}
                  </div>
                </Card>
                {instagramAccounts.length > 0 && (
                  <Button
                    variant="contained"
                    size="large"
                    color="error"
                    onClick={handleCancelClick}
                    css={styles.discardButton}
                  >
                    連携を解除する
                  </Button>
                )}
              </>
            ))}
          </>
        ) : (
          <>
            <div css={styles.accountKind}>
              <LargeLabel>Facebookアカウント</LargeLabel>
            </div>
            <div css={styles.notIntegrated}>
              Facebookアカウントが連携されていません
            </div>
            <Button
              variant="contained"
              size="large"
              onClick={handleClick}
              css={styles.integrationButton}
            >
              連携する
            </Button>
          </>
        )}
      </div>
      <div css={styles.integrationExplanationArea}>
        <SettingIntegrationExplanation />
      </div>
    </div>
  )
}

const styles = {
  container: css({
    display: "flex",
    height: "100%",
    width: "100%",
  }),
  integrationArea: css({
    flex: 5,
    padding: "20px 30px",
  }),
  accountKind: css({
    marginBottom: "10px",
  }),
  notIntegrated: css({
    fontSize: "var(--font-size-body-small)",
    marginBottom: "10px",
  }),
  integrationButton: css({
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "var(--font-size-body-small)",
    cursor: "pointer",
    backgroundColor: "var(--color-primary)",
    height: "25px",
    width: "100px",
  }),
  discardButton: css({
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "var(--font-size-body-small)",
    cursor: "pointer",
    backgroundColor: "#F0585D",
    height: "25px",
    width: "130px",
  }),
  integrationExplanationArea: css({
    flex: 5,
  }),
}

const cardStyle = css({
  display: "flex",
  alignItems: "center",
  padding: 16,
  marginBottom: 20,
  gap: 20,
  boxShadow: "none",
  border: "1px solid #D4DFE6",
  borderRadius: "16px",
})

const avatarStyle = css({
  width: 40,
  height: 40,
  border: "1px solid #D4DFE6",
})

const infoContainerStyle = css({
  display: "flex",
  flexDirection: "column",
  gap: 5,
})

const insightsStyle = css({
  display: "flex",
  alignItems: "center",
  gap: 20,
})

const insightsItemStyle = {
  userName: css({
    fontSize: "var(--font-size-body-xsmall)",
    fontWeight: "bold",
    color: "#1C98C5",
  }),
  name: css({
    fontSize: "var(--font-size-body-small)",
    color: "#6D777A",
  }),
  number: css({
    fontWeight: "bold",
    color: "#6D777A",
  }),
  text: css({
    paddingLeft: 3,
    fontSize: "var(--font-size-body-xsmall)",
    color: "#6D777A",
  }),
}
