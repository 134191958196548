import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import Cookies from 'js-cookie'

const AuthFacebookCallbackPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const params = queryString.parse(location.search)

    if (params['access-token'] && params.client && params.uid) {
      Cookies.set("access_token", params['access-token'] as string)
      Cookies.set("client", params.client as string)
      Cookies.set("uid", params.uid as string)
      navigate('/setting')
    } else {
      alert('認証に失敗しました。')
      navigate('/signin')
    }
  }, [location, navigate])

  return null
}

export default AuthFacebookCallbackPage
