import React from "react"
import { Modal, Box, Typography, Button } from "@mui/material"

interface DeleteConfirmationModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          この操作は戻すことができません。本当に削除しますか？
        </Typography>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "space-between",
            gap: 3,
          }}
        >
          <Button variant="outlined" onClick={onClose} fullWidth>
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            fullWidth
            autoFocus
            color="error"
          >
            削除
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteConfirmationModal
