import { createContext, useContext } from "react"
import { UserType } from "@/types/UserType"

type CurrentUserContextType = {
  currentUser?: UserType
  setCurrentUser: (user: UserType) => void
  reload: () => void
}

const defaultContext: CurrentUserContextType = {
  currentUser: undefined,
  setCurrentUser: () => null,
  reload: () => null,
}

export const CurrentUserContext =
  createContext<CurrentUserContextType>(defaultContext)

export const useCurrentUser = (): CurrentUserContextType =>
  useContext(CurrentUserContext)
