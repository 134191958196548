import React from "react"
import { css } from "@emotion/react"
import ExplanationImage from "@/images/setting_integration_explanation.png"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

export const SettingIntegrationExplanation = () => {
  return (
    <>
      <div css={styles.container}>
        <div css={styles.title}>アカウント連携の方法</div>
        <img
          src={ExplanationImage}
          css={styles.explanationImage}
          alt="ExplanationImage"
        />
        <div css={styles.explanationTextContainer}>
          <div css={styles.explanationTitleText}>
            バズコネをご利用いただくには、ユーザーの皆様に事前に設定いただく項目が3つあります。
          </div>
          <div css={styles.explanationText}>
            1.バズコネと連携したいInstagramのアカウントをプロアカウント(ビジネスアカウントもしくはクリエイターアカウント)に変更する。
          </div>
          <div css={styles.explanationText}>2.Facebookページを設定する</div>
          <div css={styles.explanationText}>
            3.作成したFacebookページにInstagramプロアカウントを連携する。
          </div>
        </div>
        <div onClick={() => window.open("https://buzzconne.jp", "_blank")}>
          <span css={styles.helpLink}>
            ヘルプ情報1
            <OpenInNewIcon fontSize="small" css={styles.openInNewIcon} />
          </span>
        </div>
        <div onClick={() => window.open("https://buzzconne.jp", "_blank")}>
          <span css={styles.helpLink}>
            ヘルプ情報2
            <OpenInNewIcon fontSize="small" css={styles.openInNewIcon} />
          </span>
        </div>
        <div onClick={() => window.open("https://buzzconne.jp", "_blank")}>
          <span css={styles.helpLink}>
            ヘルプ情報3
            <OpenInNewIcon fontSize="small" css={styles.openInNewIcon} />
          </span>
        </div>
      </div>
    </>
  )
}

const styles = {
  container: css({
    height: "100%",
    width: "100%",
    backgroundColor: "var(--color-bg-header)",
    padding: "20px 30px",
  }),
  title: css({
    fontSize: "var(--font-size-heading-small)",
    fontWeight: "bold",
    marginBottom: "10px",
  }),
  explanationImage: css({
    width: "100%",
    marginBottom: "20px",
  }),
  explanationTextContainer: css({
    marginBottom: "15px",
  }),
  explanationTitleText: css({
    fontSize: "var(--font-size-body-medium)",
    fontWeight: "bold",
    marginBottom: "10px",
  }),
  explanationText: css({
    fontSize: "var(--font-size-body-small)",
    paddingLeft: "5px",
    marginBottom: "2px",
    color: "var(--color-text-secondary)",
  }),
  helpLink: css({
    fontSize: "var(--font-size-body-small)",
    paddingLeft: "5px",
    marginBottom: "8px",
    fontWeight: "bold",
    color: "#1C98C5",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  }),
  openInNewIcon: css({
    fontSize: "var(--font-size-body-medium)",
  }),
}
