import { useState } from "react"
import { css } from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { PageMainTitle } from "@/components/typography/PageMainTitle"
import { HEADER_HEIGHT } from "@/components/GlobalHeader"
import { SettingsContents } from "@/components/SettingsContents"

const SettingContainer: React.FC = () => {
  const [tabValue, setTabValue] = useState("profile")

  return (
    <>
      <div css={styles.pageTitleContainer}>
        <div css={styles.pageTitle}>
          <PageMainTitle>設定</PageMainTitle>
        </div>
      </div>
      <div css={styles.pageBodyContainer}>
        <div css={styles.tabContainer}>
          <div
            onClick={() => setTabValue("profile")}
            className={`${
              tabValue === "profile" ? "tabActive" : "tabInactive"
            }`}
            css={
              tabValue === "profile"
                ? [styles.tab, styles.tabActive]
                : [styles.tab, styles.tabInactive]
            }
          >
            ユーザー情報
          </div>
          <div
            onClick={() => setTabValue("integration")}
            className={`${
              tabValue === "integration" ? "tabActive" : "tabInactive"
            }`}
            css={
              tabValue === "integration"
                ? [styles.tab, styles.tabActive]
                : [styles.tab, styles.tabInactive]
            }
          >
            アカウント連携
          </div>
          <div
            onClick={() => setTabValue("updatePassword")}
            className={`${
              tabValue === "updatePassword" ? "tabActive" : "tabInactive"
            }`}
            css={
              tabValue === "updatePassword"
                ? [styles.tab, styles.tabActive]
                : [styles.tab, styles.tabInactive]
            }
          >
            パスワード変更
          </div>
          <div
            onClick={() =>
              window.open("https://buzzconne.jp/contact", "_blank")
            }
            css={[styles.tabWithIcon, styles.tabInactive]}
          >
            <span>お問い合わせ</span>
            <OpenInNewIcon fontSize="small" css={styles.tabIcon} />
          </div>
          <div
            onClick={() =>
              window.open("https://buzzconne.jp/termsofservice", "_blank")
            }
            css={[styles.tabWithIcon, styles.tabInactive]}
          >
            <span>利用規約</span>
            <OpenInNewIcon fontSize="small" css={styles.tabIcon} />
          </div>
          <div
            onClick={() =>
              window.open("https://buzzconne.jp/privacypolicy", "_blank")
            }
            css={[styles.tabWithIcon, styles.tabInactive]}
          >
            <span>プライバシーポリシー</span>
            <OpenInNewIcon fontSize="small" css={styles.tabIcon} />
          </div>
        </div>
        <div style={{ flex: 8.7 }}>
          <SettingsContents tabValue={tabValue} />
        </div>
      </div>
    </>
  )
}

const styles = {
  pageTitleContainer: css({
    position: "relative",
    borderBottom: "1px solid var(--color-border-primary)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 30px",
    height: HEADER_HEIGHT,
  }),
  pageTitle: css({
    overflow: "visible",
  }),
  pageBodyContainer: css({ display: "flex", height: "100vh" }),
  pageSubTitleWithCreateButtonContainer: css({
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  button: css({
    paddingX: 7,
    backgroundColor: "var(--color-button-primary)",
    borderRadius: 5,
    color: "var(--color-white))",
    fontWeight: "bold",
    fontSize: "var(--font-size-label-medium)",
  }),
  companyInformationContainer: css({
    paddingTop: 20,
    paddingBottom: 10,
    position: "relative",
  }),
  companyInformation: css({
    width: "95%",
    margin: "0 auto",
    overflow: "visible",
  }),
  nameLabel: css({
    minWidth: 120,
  }),
  pagination: css({
    borderTop: "1px solid var(--color-gray-lightest)",
  }),
  noData: css({
    textAlign: "center",
  }),
  loading: css({
    textAlign: "center",
  }),
  tabContainer: css({
    flex: 1.3,
    borderRight: "1px solid var(--color-border-primary)",
  }),
  tab: css({
    fontSize: "var(--font-size-label-medium)",
    fontWeight: "var(--font-weight-medium)",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "20px",
    marginBottom: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "10px",
    borderRadius: "10px",
    cursor: "pointer",
  }),
  tabWithIcon: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "var(--font-size-label-medium)",
    fontWeight: "var(--font-weight-medium)",
    marginLeft: "10px",
    marginRight: "4px",
    marginTop: "20px",
    marginBottom: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "10px",
    borderRadius: "10px",
    cursor: "pointer",
  }),
  tabIcon: css({
    color: "var(--color-tab-icon)",
  }),
  tabActive: css({
    backgroundColor: "var(--color-primary)",
    color: "var(--color-text-white)",
  }),
  tabInactive: css({
    backgroundColor: "transparent",
    color: "var(--color-text-black)",
  }),
}

export default SettingContainer
