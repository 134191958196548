import { css } from "@emotion/react"
import AuthConfirmationCompleteContainer from "@/components/AuthConfirmationCompleteContainer"

const AuthConfirmationCompletePage = () => {
  return (
    <>
      <div css={styles.container}>
        <AuthConfirmationCompleteContainer />
      </div>
    </>
  )
}

export default AuthConfirmationCompletePage

const styles = {
  container: css({}),
}
