import { css } from "@emotion/react"

const styles = {
  passwordRules: css({
    color: "var(--color-text-password-rule)",
    fontSize: 12,
  }),
}

const PasswordRules = () => (
  <div css={styles.passwordRules}>
    *パスワードは英数字と記号6文字以上が使用できます
  </div>
)

export default PasswordRules
