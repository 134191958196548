import { ThanksForRegistrationContainer } from "@/components/ThanksForRegistrationContainer"

const ThanksForRegistrationPage = () => {
  return (
    <>
      <ThanksForRegistrationContainer />
    </>
  )
}

export default ThanksForRegistrationPage
