import "@/styles/_variables.scss"
import { useLocation } from "react-router-dom"
import { useCallback, useMemo, useState } from "react"
import { CssBaseline } from "@mui/material"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { UserType } from "@/types/UserType"
import { AppProvider } from "@/components/AppProvider"
import { CheckLogin } from "@/components/CheckLogin"
import { isPublicPath } from "@/utils/auth"
import GlobalAlert from "@/components/GlobalAlert"
import { RecoilRoot } from "recoil"
import { IntlProvider } from "react-intl"
import { AppBaseContainer } from "@/components/AppBaseContainer"

function App() {
  const theme = createTheme()
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<UserType | undefined>()
  const location = useLocation()

  const isPublic = useMemo(() => {
    return isPublicPath(location.pathname)
  }, [location.pathname])

  const handleCheckedLogin = useCallback(
    (checked: boolean, currentUser?: UserType) => {
      setIsSignedIn(checked)
      if (currentUser) setCurrentUser(currentUser)
    },
    [setCurrentUser, setIsSignedIn]
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RecoilRoot>
        <AppProvider currentUser={currentUser}>
          <IntlProvider locale="ja">
            <GlobalAlert />
            {!isPublic && (
              <CheckLogin
                isSignedIn={isSignedIn}
                onCheck={handleCheckedLogin}
              />
            )}
            <AppBaseContainer
              isSignedIn={isSignedIn}
              isPublic={isPublic}
              setIsSignedIn={setIsSignedIn}
            />
          </IntlProvider>
        </AppProvider>
      </RecoilRoot>
    </ThemeProvider>
  )
}

export default App
