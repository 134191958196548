import { useCallback, useContext, useEffect, useState } from "react"
import client from "@/api/client"
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  css,
  Box,
  Button,
} from "@mui/material"
import CompanyDatabaseListItem from "@/components/CompanyDatabaseListItem"
import WidgetsIcon from "@mui/icons-material/Widgets"
import { useRecoilValue } from "recoil"
import { PageMainTitle } from "@/components/typography/PageMainTitle"
import { HEADER_HEIGHT } from "@/components/GlobalHeader"
import { currentUserAtom } from "@/atoms"
import { MediumLabel } from "@/components/typography/MediumLabel"
import { UserType } from "@/types/UserType"
import { TeamType } from "@/types/TeamType"
import { ProductCollectionType } from "@/types/ProductCollectionType"
import CompanyDatabaseCompanyInformation from "@/components/CompanyDatabaseCompanyInformation"
import { getTeamApiPath } from "@/utils/paths"
import { paths } from "@/utils/paths"
import AlertContext from "@/components/AlertProvider"
import NoDataImage from "@/images/no_data.png"

const CompanyDatabaseContainer: React.FC = () => {
  const currentUser = useRecoilValue<UserType>(currentUserAtom)
  const [isLoading] = useState(false)
  const [tabValue, setTabValue] = useState("itemInformation")
  const [teamObject, setTeamObject] = useState<TeamType>()
  const [productCollection, setProductCollection] =
    useState<ProductCollectionType>()
  const alert = useContext(AlertContext)
  const [newProductId, setNewProductId] = useState("")
  const [lastDataFetchTime, setLastDataFetchTime] = useState<number>(0)

  useEffect(() => {
    if (tabValue !== "companyInformation") return
    setNewProductId("")
    const getTeam = async () => {
      await client
        .get(getTeamApiPath(currentUser?.team.id))
        .then((response) => {
          setTeamObject(response.data.team)
          setLastDataFetchTime(Date.now())
        })
        .catch((error) => {
          alert?.error(error.response.data.errors.message)
        })
    }

    getTeam()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue])

  useEffect(() => {
    if (tabValue !== "itemInformation") return
    const getProducts = async () => {
      await client
        .get(paths.productsPath)
        .then((response) => {
          setProductCollection(response.data.products)
          setLastDataFetchTime(Date.now())
        })
        .catch((error) => {
          alert?.error(error.response.data.errors.message)
        })
    }

    getProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, newProductId])

  const handleProductCreate = useCallback(async () => {
    await client
      .post(paths.productsPath, {
        name: "商品",
      })
      .then((response) => {
        setNewProductId(response.data.product.id)

        alert?.success(`商品を作成しました`)
      })
      .catch((error) => {
        alert?.error(error.response.data.errors.message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div css={styles.pageTitleContainer}>
        <div css={styles.pageTitle}>
          <PageMainTitle>自社データベース</PageMainTitle>
        </div>
      </div>
      <div css={styles.pageBodyContainer}>
        <div css={styles.tabContainer}>
          <div
            onClick={() => setTabValue("itemInformation")}
            className={`${
              tabValue === "itemInformation" ? "tabActive" : "tabInactive"
            }`}
            css={
              tabValue === "itemInformation"
                ? [styles.tab, styles.tabActive]
                : [styles.tab, styles.tabInactive]
            }
          >
            商品情報
          </div>
          <div
            onClick={() => setTabValue("companyInformation")}
            className={`${
              tabValue === "companyInformation" ? "tabActive" : "tabInactive"
            }`}
            css={
              tabValue === "companyInformation"
                ? [styles.tab, styles.tabActive]
                : [styles.tab, styles.tabInactive]
            }
          >
            自社情報
          </div>
        </div>
        <div css={styles.tabContent}>
          {tabValue === "companyInformation" && (
            <>
              {teamObject && (
                <CompanyDatabaseCompanyInformation
                  key={`${lastDataFetchTime}`}
                  team={teamObject}
                />
              )}
            </>
          )}
          {tabValue === "itemInformation" && (
            <>
              {productCollection ? (
                <>
                  <div css={styles.pageSubTitleWithCreateButtonContainer}>
                    <MediumLabel>商品一覧</MediumLabel>
                    <Button
                      variant="contained"
                      onClick={handleProductCreate}
                      css={styles.button}
                    >
                      ＋ 新規作成
                    </Button>
                  </div>
                  <Box mt={2}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="left"
                              css={styles.nameLabel}
                              padding="none"
                              style={{
                                paddingLeft: 30,
                                width: "35%",
                                color: "var(--color-border-gray)",
                              }}
                            >
                              商品名
                            </TableCell>
                            <TableCell
                              align="left"
                              padding="none"
                              style={{
                                width: "45%",
                                color: "var(--color-border-gray)",
                                paddingLeft: 30,
                              }}
                              css={css`
                                &.MuiTableCell-root {
                                  border-left: 1px solid rgba(224, 224, 224, 1);
                                }
                              `}
                            >
                              商品説明
                            </TableCell>
                            <TableCell
                              align="left"
                              padding="none"
                              style={{
                                width: "20%",
                                color: "var(--color-border-gray)",
                                paddingLeft: 30,
                              }}
                              css={css`
                                &.MuiTableCell-root {
                                  border-left: 1px solid rgba(224, 224, 224, 1);
                                }
                              `}
                            >
                              更新日
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {productCollection.products &&
                        productCollection.products.length > 0 ? (
                          <TableBody key={`${lastDataFetchTime}`}>
                            {productCollection.products.map((product) => {
                              return (
                                <CompanyDatabaseListItem
                                  key={product.id}
                                  product={product}
                                  newProductId={newProductId}
                                />
                              )
                            })}
                          </TableBody>
                        ) : null}
                      </Table>
                    </TableContainer>
                    {productCollection.products &&
                    productCollection.products.length > 0 ? null : (
                      <div css={styles.noData}>
                        <img src={NoDataImage} alt="" />
                      </div>
                    )}
                  </Box>
                </>
              ) : (
                <>
                  {isLoading ? (
                    <div css={styles.loading}>
                      <WidgetsIcon fontSize="large" />
                      <p>Loading...</p>
                    </div>
                  ) : (
                    <div css={styles.noData}>
                      <img src={NoDataImage} alt="" />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

const styles = {
  pageTitleContainer: css({
    position: "relative",
    borderBottom: "1px solid var(--color-border-primary)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 30px",
    height: HEADER_HEIGHT,
  }),
  pageTitle: css({
    overflow: "visible",
  }),
  pageBodyContainer: css({
    display: "flex",
    height: `calc(100vh - ${HEADER_HEIGHT}px`,
  }),
  pageSubTitleWithCreateButtonContainer: css({
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  button: css({
    paddingX: 7,
    backgroundColor: "var(--color-button-primary)",
    borderRadius: 5,
    color: "var(--color-white))",
    fontWeight: "bold",
    fontSize: "var(--font-size-label-medium)",
  }),
  companyInformationContainer: css({
    paddingTop: 20,
    paddingBottom: 10,
    position: "relative",
  }),
  companyInformation: css({
    width: "95%",
    margin: "0 auto",
    overflow: "visible",
  }),
  nameLabel: css({
    minWidth: 120,
  }),
  pagination: css({
    borderTop: "1px solid var(--color-gray-lightest)",
  }),
  noData: css({
    textAlign: "center",
  }),
  loading: css({
    textAlign: "center",
  }),
  tabContainer: css({
    flex: 1.3,
    borderRight: "1px solid var(--color-border-primary)",
  }),
  tab: css({
    fontSize: "var(--font-size-label-medium)",
    fontWeight: "var(--font-weight-medium)",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "20px",
    marginBottom: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "10px",
    borderRadius: "10px",
    cursor: "pointer",
  }),
  tabActive: css({
    backgroundColor: "var(--color-primary)",
    color: "var(--color-text-white)",
  }),
  tabInactive: css({
    backgroundColor: "transparent",
    color: "var(--color-text-black)",
  }),
  tabContent: css({
    flex: 8.7,
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${HEADER_HEIGHT}px)`,
    overflowY: "auto",
  }),
}

export default CompanyDatabaseContainer
