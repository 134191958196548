import { TableCell } from "@mui/material"
import { css } from "@emotion/react"
import { InstagramAccountMediumType } from "@/types/InstagramAccountMediumType"
import { formatTimestamp } from "@/utils/helpers/postList"
import LlmNoDataImage from "@/images/llm_no_data.png"
import { checkMetricValue } from "@/utils/helpers/postList"

interface Props {
  instagramAccountMedia: InstagramAccountMediumType
}

export const FeedItemTableCellsComponent = ({
  instagramAccountMedia,
}: Props) => (
  <>
    <TableCell
      align="center"
      padding="none"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: 30,
        paddingTop: 6,
        paddingBottom: 6,
      }}
      css={styles.formattedTableCell}
    >
      {instagramAccountMedia.thumbnailImageUrl?.endsWith(".mp4") ? (
        <video
          src={instagramAccountMedia.thumbnailImageUrl || LlmNoDataImage}
          css={styles.image}
        />
      ) : (
        <img
          src={instagramAccountMedia.thumbnailImageUrl || LlmNoDataImage}
          alt=""
          css={styles.image}
        />
      )}
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{ fontSize: "var(--font-size-body-small)" }}
      css={styles.formattedTableCell}
    >
      <div>
        {formatTimestamp(instagramAccountMedia?.timestamp).split(" ")[0]}
      </div>
      <div>
        {formatTimestamp(instagramAccountMedia?.timestamp).split(" ")[1]}
      </div>
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{ fontSize: "var(--font-size-body-small)" }}
      css={styles.formattedTableCell}
    >
      {instagramAccountMedia.caption &&
        `${instagramAccountMedia.caption
          .replace(/\n/g, " ")
          .substring(0, 35)}...`}
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{ fontSize: "var(--font-size-body-medium)" }}
      css={styles.formattedTableCell}
    >
      {checkMetricValue(instagramAccountMedia.latestInsight?.reachCount)}
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{ fontSize: "var(--font-size-body-medium)" }}
      css={styles.formattedTableCell}
    >
      {checkMetricValue(instagramAccountMedia.latestInsight?.impressionCount)}
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{ fontSize: "var(--font-size-body-medium)" }}
      css={styles.formattedTableCell}
    >
      {checkMetricValue(instagramAccountMedia.latestInsight?.likeCount)}
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{ fontSize: "var(--font-size-body-medium)" }}
      css={styles.formattedTableCell}
    >
      {checkMetricValue(instagramAccountMedia.latestInsight?.commentCount)}
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{ fontSize: "var(--font-size-body-medium)" }}
      css={styles.formattedTableCell}
    >
      {checkMetricValue(instagramAccountMedia.latestInsight?.saveCount)}
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{ fontSize: "var(--font-size-body-medium)" }}
      css={styles.formattedTableCell}
    >
      {checkMetricValue(instagramAccountMedia.latestInsight?.engagementCount)}
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{ fontSize: "var(--font-size-body-medium)" }}
      css={styles.formattedTableCell}
    >
      {`${checkMetricValue(
        instagramAccountMedia.latestInsight?.engagementRate
      )}%`}
    </TableCell>
  </>
)

const styles = {
  tableRow: css({
    cursor: "pointer",
  }),
  formattedTableCell: css({
    whiteSpace: "pre-wrap",
    height: "76px",
  }),
  title: css({
    fontSize: "var(--font-size-body-large)",
    fontWeight: "var(--font-weight-medium)",
    color: "var(--color-text-black)",
  }),
  image: css({
    flexShrink: 0,
    marginRight: "10px",
    width: "60px",
    height: "60px",
    border: "1px solid #D4DFE6",
    objectFit: "contain",
  }),
}

export default FeedItemTableCellsComponent
