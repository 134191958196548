import { css } from "@emotion/react"
import PostListContainer from "@/components/PostListContainer"

const PostListReelsPage = () => {
  return (
    <>
      <div css={styles.container}>
        <PostListContainer />
      </div>
    </>
  )
}

export default PostListReelsPage

const styles = {
  container: css({}),
}
