import { useState, useContext } from "react"
import {
  css,
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
} from "@mui/material"
import { HEADER_HEIGHT } from "@/components/GlobalHeader"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import EditIcon from "@mui/icons-material/Edit"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import DownloadIcon from "@mui/icons-material/Download"
import { GenerativeContentResponseType } from "@/types/GenerativeContentResponseType"
import { EscapeText } from "./EscapeText"
import LoadingIcon from "@/images/loading.gif"
import AlertContext from "@/components/AlertProvider"
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal"
import { downloadImage } from "@/utils/imageUtils"

interface Props {
  generativeContentResponse: GenerativeContentResponseType
  onDelete: (id: string) => void
  onDeleteImages: (id: string) => void
  onEdit: (id: string, editedResponse: string) => void
}

export const LlmInteractionListItemEditDialogLlmReply = ({
  generativeContentResponse,
  onDelete,
  onDeleteImages,
  onEdit,
}: Props) => {
  const alert = useContext(AlertContext)
  const [isEditing, setIsEditing] = useState(false)
  const [editedResponse, setEditedResponse] = useState(
    generativeContentResponse.response
  )
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isDeleteImagesModalOpen, setIsDeleteImagesModalOpen] = useState(false)

  const handleCopy = async () => {
    await navigator.clipboard.writeText(generativeContentResponse.response)
    alert?.success("コピーしました")
  }

  const handleDelete = () => {
    onDelete(generativeContentResponse.id)
    setIsDeleteModalOpen(false)
  }

  const handleDeleteImages = () => {
    onDeleteImages(generativeContentResponse.id)
    setIsDeleteImagesModalOpen(false)
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    onEdit(generativeContentResponse.id, editedResponse)
    setIsEditing(false)
  }

  const handleCancel = () => {
    setIsEditing(false)
    setEditedResponse(generativeContentResponse.response)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedResponse(event.target.value)
  }

  return (
    <>
      <div css={styles.container}>
        <div css={styles.updatedAtContainer}>
          <div css={styles.createdAt}>
            {generativeContentResponse.createdAt}
          </div>
        </div>
        {generativeContentResponse.itemType !== "images" && (
          <>
            <Card css={styles.card}>
              <CardContent>
                <Typography css={styles.typography}>
                  {generativeContentResponse.response ? (
                    <EscapeText text={generativeContentResponse.response} />
                  ) : (
                    <>
                      <img css={styles.loadingIcon} src={LoadingIcon} alt="" />
                      <p>投稿生成中...</p>
                    </>
                  )}
                </Typography>
              </CardContent>
            </Card>
            {generativeContentResponse.response && (
              <div css={styles.actionIconsAndUpdatedAtTimeContainer}>
                <div css={styles.actionIconsContainer}>
                  <ContentCopyIcon
                    onClick={handleCopy}
                    css={styles.actionIcon}
                  />
                  <EditIcon onClick={handleEdit} css={styles.actionIcon} />
                  <DeleteOutlineIcon
                    onClick={() => setIsDeleteModalOpen(true)}
                    css={styles.actionIcon}
                  />
                </div>
                <div css={styles.updatedAtTime}>
                  {generativeContentResponse.createdTime}
                </div>
              </div>
            )}
          </>
        )}
        {generativeContentResponse.itemType !== "posts" && (
          <>
            {generativeContentResponse.responseImages &&
            generativeContentResponse.responseImages.length > 0 ? (
              <>
                <div css={styles.imagesContainer}>
                  <Grid
                    container
                    spacing={1}
                    sx={{ marginTop: 2, justifyContent: "center" }}
                  >
                    {generativeContentResponse.responseImages.map(
                      (imageUrl, index) => (
                        <Grid
                          item
                          xs={
                            generativeContentResponse.responseImages.length > 1
                              ? 6
                              : 12
                          }
                          key={index}
                        >
                          <div css={styles.imageContainer}>
                            <img
                              src={imageUrl}
                              alt={`LLM_Response ${index}`}
                              css={styles.image}
                            />
                            <DownloadIcon
                              css={styles.downloadIconOnImage}
                              className="download-icon"
                              onClick={() =>
                                downloadImage(
                                  imageUrl,
                                  `LLM_RESPONSE_IMAGE_${index + 1}.png`
                                )
                              }
                            />
                          </div>
                        </Grid>
                      )
                    )}
                  </Grid>
                </div>
                <div css={styles.actionIconsAndUpdatedAtTimeContainer}>
                  <div css={styles.actionIconsContainer}>
                    <DownloadIcon
                      css={styles.actionIcon}
                      onClick={() => {
                        generativeContentResponse.responseImages.forEach(
                          (imageUrl: string, index: number) => {
                            downloadImage(
                              imageUrl,
                              `LLM_RESPONSE_IMAGE_${index + 1}.png`
                            )
                          }
                        )
                      }}
                    />
                    <DeleteOutlineIcon
                      onClick={() => setIsDeleteImagesModalOpen(true)}
                      css={styles.actionIcon}
                    />
                  </div>
                  <div css={styles.updatedAtTime}>
                    {generativeContentResponse.createdTime}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div css={styles.imageLoadingContainer}>
                  <Card css={styles.card}>
                    <CardContent>
                      <Typography css={styles.typography}>
                        <img
                          css={styles.loadingIcon}
                          src={LoadingIcon}
                          alt=""
                        />
                        <p>画像生成中...</p>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <Dialog
        open={isEditing}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
        PaperProps={materialUiStyles.dialog}
      >
        <DialogTitle id="form-dialog-title" css={styles.dialogTitle}>
          レスポンスを編集
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            multiline
            rows={22}
            variant="outlined"
            value={editedResponse}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions css={styles.dialogActions}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                size="large"
                onClick={handleCancel}
                fullWidth
                sx={{
                  height: "40px",
                }}
              >
                キャンセル
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                size="large"
                onClick={handleSave}
                fullWidth
                sx={{
                  backgroundColor: "var(--color-primary)",
                  height: "40px",
                }}
              >
                保存
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteImagesModalOpen}
        onClose={() => setIsDeleteImagesModalOpen(false)}
        onConfirm={handleDeleteImages}
      />
    </>
  )
}

const materialUiStyles = {
  dialog: {
    sx: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      m: 0,
      width: 900,
      maxWidth: "95vw",
      height: `calc(100% - ${HEADER_HEIGHT}px)`,
      boxShadow: "none",
    },
  },
}

const styles = {
  container: css({
    marginBottom: "20px",
  }),
  updatedAtContainer: css({
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  }),
  createdAt: css({
    display: "inline-block",
    padding: "5px 15px",
    fontSize: "var(--font-size-label-xsmall)",
    borderRadius: "20px",
    backgroundColor: "var(--color-bg-llm-reply-area)",
    color: "var(--color-white)",
  }),
  card: css({
    padding: "10px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  typography: css({
    color: "var(--color-text-black)",
    fontSize: "var(--font-size-body-medium)",
  }),
  actionIconsAndUpdatedAtTimeContainer: css({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  }),
  actionIconsContainer: css({
    display: "flex",
    gap: "12px",
  }),
  actionIcon: css({
    fontSize: "30px",
    padding: "3px",
    border: "1px solid var(--color-clear-blue)",
    borderRadius: "5px",
    fontWeight: "bold",
    color: "var(--color-clear-blue)",
    cursor: "pointer",
    ":hover": {
      boxShadow: "0 0 8px rgba(28, 152, 197, 0.3)",
    },
    ":active": {
      transform: "scale(0.95)",
    },
  }),
  updatedAtTime: css({
    fontSize: "var(--font-size-label-small)",
    color: "#45484B",
    marginRight: "5px",
  }),
  loadingIcon: css({
    width: 50,
  }),
  dialogTitle: css({
    fontSize: "var(--font-size-heading-medium)",
    fontWeight: "var(--font-weight-medium)",
    textAlign: "center",
  }),
  dialogActions: css({
    padding: "20px 24px",
  }),
  imagesContainer: css({
    width: "75%",
    paddingTop: "20px",
    marginLeft: "auto",
    marginRight: "auto",
  }),
  imageContainer: css({
    position: "relative",
    width: "100%",
    height: "auto",
    "&:hover img": {
      filter: "brightness(0.4)",
    },
    "&:hover .download-icon": {
      display: "flex",
    },
  }),
  image: css({
    width: "100%",
    height: "auto",
    display: "block",
  }),
  imageLoadingContainer: css({
    paddingTop: "20px",
  }),
  downloadIconOnImage: css({
    position: "absolute",
    top: 10,
    right: 10,
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    padding: "3px",
    border: "1px solid var(--color-clear-blue)",
    borderRadius: "5px",
    fontWeight: "bold",
    color: "var(--color-clear-blue)",
    cursor: "pointer",
    ":hover": {
      boxShadow: "0 0 8px rgba(28, 152, 197, 0.3)",
    },
    ":active": {
      transform: "scale(0.95)",
    },
    margin: "8px",
  }),
}
