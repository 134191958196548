import { Line } from "react-chartjs-2"
import { TooltipItem } from "chart.js"
import "chart.js/auto" // Chart.jsの自動登録に必要
import { css } from "@mui/material"

interface DataSets {
  reachCounts: number[]
  impressionCounts: number[]
  engagementCounts: number[]
  readRates: number[]
  navigationCounts: number[]
  followsRedirectsCounts: number[]
  profileActivityCounts: number[]
  profileVisitsCounts: number[]
  [key: string]: number[]
}

interface Props {
  chartDataSets: DataSets
  chartLabels: string[]
  selectedMetric: string
  selectedMetricLabel: string
}

export const InstagramAccountMediaStoryInsightsLineChart = ({
  chartDataSets,
  chartLabels,
  selectedMetric,
  selectedMetricLabel,
}: Props) => {
  const datasetLabel = selectedMetricLabel

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: datasetLabel,
        data: chartDataSets[selectedMetric],
        fill: true,
        backgroundColor: "#EFF4F6",
        borderColor: "#1C98C5",
        pointBackgroundColor: ["#1C98C5"],
      },
    ],
  }

  const filteredData = chartDataSets[selectedMetric].filter(
    (value) => value > 0
  )
  const minDataValue = Math.min(...filteredData)
  const maxDataValue = Math.max(...filteredData)
  const maxYAxisValue = Math.ceil(maxDataValue * 1.1)
  const minYAxisValue = Math.floor(minDataValue * 0.9)

  const calculateYAxisTicks = () => {
    let maxYValue = maxYAxisValue
    let minYValue = minYAxisValue
    let stepSize = Math.ceil(maxYValue * 0.1)
    return {
      beginAtZero: false,
      suggestedMax: maxYValue,
      suggestedMin: minYValue,
      stepSize: stepSize,
    }
  }

  const options = {
    responsive: true, // レスポンシブ対応
    maintainAspectRatio: false, // アスペクト比を維持
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          // ツールチップのタイトルをカスタマイズ
          title: function (context: TooltipItem<"line">[]) {
            // context[0].labelは、x軸のラベルです
            return `${context[0].label}`
          },
          // ツールチップの本文をカスタマイズ
          label: function (context: TooltipItem<"line">) {
            // context.parsed.yは、y軸の値
            const value = context.parsed.y
            return `${value}`
          },
        },
        backgroundColor: "rgba(0,0,0,0.8)", // 背景色のカスタマイズ
        bodySpacing: 5, // 本文のスペーシング
      },
    },
    scales: {
      y: calculateYAxisTicks(),
    },
  }

  return (
    <>
      <div css={styles.container}>
        <Line data={data} options={options} />
      </div>
    </>
  )
}

const styles = {
  container: css({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }),
}
