import { css } from "@emotion/react"

const styles = {
  llmImageFootnote: css({
    color: "var(--color-text-password-rule)",
    fontSize: 12,
  }),
}

const LlmImageFootnote = () => (
  <div css={styles.llmImageFootnote}>
    画像はPNGファイル(4MG以下)が使用できます。
  </div>
)

export default LlmImageFootnote
