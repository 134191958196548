import { css } from "@emotion/react"
import { PropsWithChildren, ReactNode } from "react"

interface Props {
  children: ReactNode
}

export const PageMainTitle = ({ children }: PropsWithChildren<Props>) => {
  return <h2 css={styles.container}>{children}</h2>
}

const styles = {
  container: css({
    fontWeight: "bold",
    fontSize: "var(--font-size-heading-large)",
    color: "#101111",
  }),
}
