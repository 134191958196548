import { css } from "@emotion/react"
import { HEADER_HEIGHT } from "@/components/GlobalHeader"
import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import { paths } from "@/utils/paths"

const AuthConfirmationCompleteContainer = () => {
  return (
    <div css={styles.contailer}>
      <p>アカウント認証が完了しました。</p>
      <p>以下からログインをしてバズコネクンションを開始しましょう！</p>
      <Link to={paths.signInPath}>
        <Button variant="contained">ログインページへ</Button>
      </Link>
    </div>
  )
}

const styles = {
  contailer: css({
    textAlign: "center",
    marginTop: HEADER_HEIGHT,
    padding: 50,
    fontWeight: "bold",
  }),
}

export default AuthConfirmationCompleteContainer
