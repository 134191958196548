import React from "react"
import { Grid, Card } from "@mui/material"
import { css } from "@emotion/react"
import { InstagramAccountInsightType } from "@/types/InstagramAccountInsightType"

interface Props {
  latestInstagramAccountInsight: InstagramAccountInsightType
}

export const DashboardInsightCards = ({
  latestInstagramAccountInsight,
}: Props) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <Card css={styles.insightCard}>
            <div css={styles.insightCardLabel}>フォロワー</div>
            <div css={styles.insightCardValue}>
              {Intl.NumberFormat().format(
                latestInstagramAccountInsight.followersCount
              )}
            </div>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card css={styles.insightCard}>
            <div css={styles.insightCardLabel}>リーチ</div>
            <div css={styles.insightCardValue}>
              {Intl.NumberFormat().format(
                latestInstagramAccountInsight.reachCount
              )}
            </div>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card css={styles.insightCard}>
            <div css={styles.insightCardLabel}>いいね</div>
            <div css={styles.insightCardValue}>
              {Intl.NumberFormat().format(
                latestInstagramAccountInsight.likeCount
              )}
            </div>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card css={styles.insightCard}>
            <div css={styles.insightCardLabel}>保存</div>
            <div css={styles.insightCardValue}>
              {Intl.NumberFormat().format(
                latestInstagramAccountInsight.saveCount
              )}
            </div>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card css={styles.insightCard}>
            <div css={styles.insightCardLabel}>プロフィールレビュー</div>
            <div css={styles.insightCardValue}>
              {Intl.NumberFormat().format(
                latestInstagramAccountInsight.profileViewsCount
              )}
            </div>
          </Card>
        </Grid>
        <Grid item xs={2}>
          <Card css={styles.insightCard}>
            <div css={styles.insightCardLabel}>URLクリック</div>
            <div css={styles.insightCardValue}>
              {Intl.NumberFormat().format(
                latestInstagramAccountInsight.urlClicksCount
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

const styles = {
  insightCard: css({
    textAlign: "center",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "none",
    border: "1px solid #D4DFE6",
    borderRadius: "16px",
  }),
  insightCardLabel: css({
    color: "#6D777A",
    fontSize: "var(--font-size-label-large)",
    fontWeight: "bold",
  }),
  insightCardValue: css({
    fontSize: "var(--font-size-heading-large)",
    fontWeight: "bold",
  }),
}
