import { InstagramAccountMediumType } from "@/types/InstagramAccountMediumType"

export type LineChartDataSets = {
  reachCounts: number[]
  playCounts: number[]
  likeCounts: number[]
  saveCounts: number[]
  shareCounts: number[]
  engagementCounts: number[]
  engagementRates: number[]
  commentCounts: number[]
  impressionCounts: number[]
  [key: string]: number[]
}

export type StoryLineChartDataSets = {
  reachCounts: number[]
  impressionCounts: number[]
  engagementCounts: number[]
  readRates: number[]
  navigationCounts: number[]
  followsRedirectsCounts: number[]
  profileActivityCounts: number[]
  profileVisitsCounts: number[]
  [key: string]: number[]
}

export const formatTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp)
  const dateFormatter = new Intl.DateTimeFormat("ja-JP", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  })
  // コンマを削除して日付と時刻を結合
  return dateFormatter.format(date).replace(",", "")
}

export const checkMetricValue = (metric: number | null | undefined) => {
  return metric !== null && metric !== undefined ? metric : "No Data"
}

export const getMetricCards = (media: InstagramAccountMediumType) => {
  if (media.mediaProductType === "REELS") {
    return [
      {
        label: "リーチ数",
        value: "reachCounts",
        metrics: checkMetricValue(media.latestInsight?.reachCount),
      },
      {
        label: "再生数",
        value: "playCounts",
        metrics: checkMetricValue(media.latestInsight?.playCount),
      },
      {
        label: "ENG数",
        value: "engagementCounts",
        metrics: checkMetricValue(media.latestInsight?.engagementCount),
      },
      {
        label: "ENG率(リーチ)",
        value: "engagementRates",
        metrics: `${checkMetricValue(media.latestInsight?.engagementRate)}%`,
      },
      {
        label: "いいね数",
        value: "likeCounts",
        metrics: checkMetricValue(media.latestInsight?.likeCount),
      },
      {
        label: "コメント数",
        value: "commentCounts",
        metrics: checkMetricValue(media.latestInsight?.commentCount),
      },
      {
        label: "保存数",
        value: "saveCounts",
        metrics: checkMetricValue(media.latestInsight?.saveCount),
      },
      {
        label: "シェア数",
        value: "shareCounts",
        metrics: checkMetricValue(media.latestInsight?.shareCount),
      },
    ]
  } else if (media.mediaProductType === "FEED") {
    return [
      {
        label: "リーチ数",
        value: "reachCounts",
        metrics: checkMetricValue(media.latestInsight?.reachCount),
      },
      {
        label: "IMP数",
        value: "impressionCounts",
        metrics: checkMetricValue(media.latestInsight?.impressionCount),
      },
      {
        label: "ENG数",
        value: "engagementCounts",
        metrics: checkMetricValue(media.latestInsight?.engagementCount),
      },
      {
        label: "ENG率(リーチ)",
        value: "engagementRates",
        metrics: `${checkMetricValue(media.latestInsight?.engagementRate)}%`,
      },
      {
        label: "いいね数",
        value: "likeCounts",
        metrics: checkMetricValue(media.latestInsight?.likeCount),
      },
      {
        label: "コメント数",
        value: "commentCounts",
        metrics: checkMetricValue(media.latestInsight?.commentCount),
      },
      {
        label: "保存数",
        value: "saveCounts",
        metrics: checkMetricValue(media.latestInsight?.saveCount),
      },
      {
        label: "VIDEO視聴回数",
        value: "playCounts",
        metrics: checkMetricValue(media.latestInsight?.playCount),
      },
    ]
  } else if (media.mediaProductType === "STORY") {
    return [
      {
        label: "リーチ数",
        value: "reachCounts",
        metrics: checkMetricValue(media.latestStoryInsight?.reachCount),
      },
      {
        label: "IMP数",
        value: "impressionCounts",
        metrics: checkMetricValue(media.latestStoryInsight?.impressionCount),
      },
      {
        label: "エンゲージメント数",
        value: "engagementCounts",
        metrics: checkMetricValue(media.latestStoryInsight?.engagementCount),
      },
      {
        label: "既読率",
        value: "readRates",
        metrics: `${checkMetricValue(media.latestStoryInsight?.readRate)}%`,
      },
      {
        label: "ナビゲーション数",
        value: "navigationCounts",
        metrics: checkMetricValue(media.latestStoryInsight?.navigationCount),
      },
      {
        label: "フォロー数",
        value: "followsRedirectsCounts",
        metrics: checkMetricValue(
          media.latestStoryInsight?.followsRedirectsCount
        ),
      },
      {
        label: "PFアクション数",
        value: "profileActivityCounts",
        metrics: checkMetricValue(
          media.latestStoryInsight?.profileActivityCount
        ),
      },
      {
        label: "PF訪問数",
        value: "profileVisitsCounts",
        metrics: checkMetricValue(media.latestStoryInsight?.profileVisitsCount),
      },
    ]
  }
}
