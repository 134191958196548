import SettingProfile from "@/components/SettingProfile"
import SettingUpdatePassword from "@/components/SettingUpdatePassword"
import { SettingIntegration } from "@/components/SettingIntegration"

interface Props {
  tabValue: string
}

export const SettingsContents = ({ tabValue }: Props) => {
  switch (tabValue) {
    case "profile":
      return <SettingProfile />
    case "integration":
      return <SettingIntegration />
    case "updatePassword":
      return <SettingUpdatePassword />
    default:
      return <SettingProfile />
  }
}
