export const occupations = [
  { id: 1, name: "営業" },
  { id: 2, name: "企画・経営" },
  { id: 3, name: "管理・事務" },
  { id: 4, name: "販売・フード・アミューズメント" },
  { id: 5, name: "美容・ブライダル・ホテル・交通" },
  { id: 6, name: "医療・福祉" },
  { id: 7, name: "保育・教育・通訳" },
  { id: 8, name: "クリエイティブ" },
  { id: 9, name: "WEB・インターネット・ゲーム" },
  { id: 10, name: "コンサルタント・金融・不動産専門職" },
  { id: 11, name: "公共サービス" },
  { id: 12, name: "Webマーケティング" },
  { id: 13, name: "ITエンジニア" },
  { id: 14, name: "電気・電子・機械・半導体" },
  { id: 15, name: "建築・土木" },
  { id: 16, name: "医薬・食品・化学・素材" },
  { id: 17, name: "技能工・設備・配送・農林水産" },
]
