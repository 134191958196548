import CompanyDatabaseContainer from "@/components/CompanyDatabaseContainer"

const CompanyDatabasePage = () => {
  return (
    <>
      <CompanyDatabaseContainer />
    </>
  )
}

export default CompanyDatabasePage
