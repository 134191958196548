import { css } from "@emotion/react"
import { PropsWithChildren, ReactNode } from "react"
import { XsmallRequiredLabel } from "./XsmallRequiredLabel"

interface Props {
  children: ReactNode
}

export const MediumLabelWithRequired = ({
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div css={styles.container}>
      {children}
      <XsmallRequiredLabel>必須</XsmallRequiredLabel>
    </div>
  )
}

const styles = {
  container: css({
    fontWeight: "bold",
    fontSize: "var(--font-size-label-medium)",
    color: "#101111",
  }),
}
