import React from "react"
import { css } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { PageMainTitle } from "@/components/typography/PageMainTitle"
import { HEADER_HEIGHT } from "@/components/GlobalHeader"
import { PostListContents } from "@/components/PostListContents"
import { CustomTooltip } from "@/components/CustomTooltip"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { IconButton, Typography } from "@mui/material"

interface Props {
  isInitialVelocityAnalysis?: boolean
}

const PostListContainer = ({ isInitialVelocityAnalysis = false }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const lastPath = location.pathname.split("/").pop()
  const tabValue = lastPath === "post-list" ? "feeds" : lastPath || "feeds"

  const handleTabChange = (newTab: string) => {
    isInitialVelocityAnalysis
      ? navigate(`/initial-velocity-analysis/${newTab}`)
      : navigate(`/post-list/${newTab}`)
  }

  return (
    <>
      <div css={styles.pageTitleContainer}>
        <div css={styles.pageTitle}>
          <PageMainTitle>
            {isInitialVelocityAnalysis ? "初速分析" : "投稿一覧"}
            {isInitialVelocityAnalysis ? (
              <CustomTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit" css={styles.tooltipTypography}>
                      Instagramアカウントの連携日以降に投稿されたコンテンツについて、24時間以内の初速を分析します。
                    </Typography>
                  </React.Fragment>
                }
              >
                <IconButton>
                  <HelpOutlineIcon css={styles.tooltipIcon} fontSize="small" />
                </IconButton>
              </CustomTooltip>
            ) : null}
          </PageMainTitle>
        </div>
      </div>
      <div css={styles.pageBodyContainer}>
        <div css={styles.tabContainer}>
          <div
            onClick={() => handleTabChange("feeds")}
            className={`${tabValue === "feeds" ? "tabActive" : "tabInactive"}`}
            css={
              tabValue === "feeds"
                ? [styles.tab, styles.tabActive]
                : [styles.tab, styles.tabInactive]
            }
          >
            フィード投稿
          </div>
          <div
            onClick={() => handleTabChange("reels")}
            className={`${tabValue === "reels" ? "tabActive" : "tabInactive"}`}
            css={
              tabValue === "reels"
                ? [styles.tab, styles.tabActive]
                : [styles.tab, styles.tabInactive]
            }
          >
            リール投稿
          </div>
          {isInitialVelocityAnalysis && (
            <>
              <div
                onClick={() => handleTabChange("stories")}
                className={`${
                  tabValue === "reels" ? "tabActive" : "tabInactive"
                }`}
                css={
                  tabValue === "stories"
                    ? [styles.tab, styles.tabActive]
                    : [styles.tab, styles.tabInactive]
                }
              >
                ストーリーズ
              </div>
            </>
          )}
        </div>
        <div style={{ flex: 8.7 }}>
          <PostListContents
            tabValue={tabValue}
            isInitialVelocityAnalysis={isInitialVelocityAnalysis}
          />
        </div>
      </div>
    </>
  )
}

const styles = {
  pageTitleContainer: css({
    position: "relative",
    borderBottom: "1px solid var(--color-border-primary)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 30px",
    height: HEADER_HEIGHT,
  }),
  pageTitle: css({
    overflow: "visible",
  }),
  pageBodyContainer: css({ display: "flex", height: "100vh" }),
  pageSubTitleWithCreateButtonContainer: css({
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  button: css({
    paddingX: 7,
    backgroundColor: "var(--color-button-primary)",
    borderRadius: 5,
    color: "var(--color-white))",
    fontWeight: "bold",
    fontSize: "var(--font-size-label-medium)",
  }),
  companyInformationContainer: css({
    paddingTop: 20,
    paddingBottom: 10,
    position: "relative",
  }),
  companyInformation: css({
    width: "95%",
    margin: "0 auto",
    overflow: "visible",
  }),
  nameLabel: css({
    minWidth: 120,
  }),
  pagination: css({
    borderTop: "1px solid var(--color-gray-lightest)",
  }),
  noData: css({
    textAlign: "center",
  }),
  loading: css({
    textAlign: "center",
  }),
  tabContainer: css({
    flex: 1.3,
    borderRight: "1px solid var(--color-border-primary)",
  }),
  tab: css({
    fontSize: "var(--font-size-label-medium)",
    fontWeight: "var(--font-weight-medium)",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "20px",
    marginBottom: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "10px",
    borderRadius: "10px",
    cursor: "pointer",
  }),
  tabWithIcon: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "var(--font-size-label-medium)",
    fontWeight: "var(--font-weight-medium)",
    marginLeft: "10px",
    marginRight: "4px",
    marginTop: "20px",
    marginBottom: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "10px",
    borderRadius: "10px",
    cursor: "pointer",
  }),
  tabIcon: css({
    color: "var(--color-tab-icon)",
  }),
  tabActive: css({
    backgroundColor: "var(--color-primary)",
    color: "var(--color-text-white)",
  }),
  tabInactive: css({
    backgroundColor: "transparent",
    color: "var(--color-text-black)",
  }),
  tooltipIcon: css({
    color: "var(--color-primary)",
    paddingBottom: "0px",
  }),
  tooltipTypography: css({
    fontSize: "var(--font-size-body-small)",
  }),
}

export default PostListContainer
