import { CircularProgress } from "@mui/material"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

import { css } from "@emotion/react"

interface Props {
  saveStatus: string
}

const SaveStatusLabel = ({ saveStatus }: Props) => {
  switch (saveStatus) {
    case "saving":
      return (
        <div css={styles.savingStatusLabel}>
          <span css={styles.savingStatusLText}>保存中</span>
          <CircularProgress
            size={14}
            css={{ ...styles.savingStatusIcon, fontSize: 14 }}
          />
        </div>
      )
    case "failed":
      return (
        <div css={styles.failedStatusLabel}>
          <span css={styles.failedStatusLText}>保存されていません。</span>
          <ErrorOutlineIcon fontSize="small" css={styles.failedStatusIcon} />
        </div>
      )
    case "saved":
    default:
      return (
        <div css={styles.savedStatusLabel}>
          <span css={styles.savedStatusLText}>保存済み</span>
          <CheckCircleOutlineIcon
            fontSize="small"
            css={styles.savedStatusIcon}
          />
        </div>
      )
  }
}

const commonStatusLabel = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
  height: "35px",
  padding: "5px 10px",
  borderRadius: "20px",
})

const commonStatusText = css({
  fontWeight: "bold",
  fontSize: "var(--font-size-label-xsmall)",
  paddingRight: "2px",
})

const commonStatusIcon = css({
  fontSize: "20px",
})

const styles = {
  savedStatusLabel: css(commonStatusLabel, {
    border: "2px solid var(--color-saved-contact-status)",
  }),
  savedStatusLText: css(commonStatusText, {
    color: "var(--color-saved-contact-status)",
  }),
  savedStatusIcon: css(commonStatusIcon, {
    color: "var(--color-saved-contact-status)",
  }),
  failedStatusLabel: css(commonStatusLabel, {
    border: "2px solid var(--color-failed-contact-status)",
  }),
  failedStatusLText: css(commonStatusText, {
    color: "var(--color-failed-contact-status)",
  }),
  failedStatusIcon: css(commonStatusIcon, {
    color: "var(--color-failed-contact-status)",
  }),
  savingStatusLabel: css(commonStatusLabel, {
    border: "2px solid var(--color-saving-contact-status)",
  }),
  savingStatusLText: css(commonStatusText, {
    color: "var(--color-saving-contact-status)",
  }),
  savingStatusIcon: css(commonStatusIcon, {
    color: "var(--color-saving-contact-status)",
  }),
}

export default SaveStatusLabel
