import { css } from "@emotion/react"
import { HEADER_HEIGHT } from "@/components/GlobalHeader"

export const ThanksForRegistrationContainer = () => {
  return (
    <div css={styles.container}>
      <p>バズコネクションへのご登録ありがとうございます！</p>
      <p>
        ご登録いただいたメールアドレス宛てにアカウント認証のメールをお送りしました。
      </p>
      <p>メールボックスをご確認ください。</p>
    </div>
  )
}

const styles = {
  container: css({
    textAlign: "center",
    marginTop: HEADER_HEIGHT,
    padding: 50,
    fontWeight: "bold",
  }),
}
