import { css } from "@emotion/react"
import PostListContainer from "@/components/PostListContainer"

const InitialVelocityAnalysisStoriesPage = () => {
  return (
    <>
      <div css={styles.container}>
        <PostListContainer isInitialVelocityAnalysis={true} />
      </div>
    </>
  )
}

export default InitialVelocityAnalysisStoriesPage

const styles = {
  container: css({}),
}
