import { PropsWithChildren, useCallback, useEffect, useState } from "react"
import { CurrentUserContext } from "@/hooks/useCurrentUser"
import { UserType } from "@/types/UserType"

export const CurrentUserProvider = ({
  children,
  currentUser,
}: PropsWithChildren<{ currentUser?: UserType }>) => {
  const [user, setUser] = useState<UserType | undefined>(currentUser)
  const [r, setReload] = useState<boolean>(false)

  useEffect(() => {
    setUser(currentUser)
  }, [currentUser])

  const reload = useCallback(() => {
    setReload(!r)
  }, [r, setReload])

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: user,
        setCurrentUser: setUser,
        reload,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}
