import React, { useState } from "react"
import { css } from "@emotion/react"
import Button from "@mui/material/Button"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { GlobalHeaderItems } from "@/utils/helpers/GlobalHeaderList"
import BuzzConnelogo from "@/images/logo.png"
import { signOut } from "@/api/auth"
import { useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"
import AlertContext from "./AlertProvider"
import { useRecoilValue } from "recoil"
import { UserType } from "@/types/UserType"
import { currentUserAtom } from "@/atoms"

interface Props {
  setIsSignedIn: (isSignedIn: boolean) => void
}

interface GlobalHeaderItem {
  title: string
  link: string
}

export const HEADER_HEIGHT = 56

export const GlobalHeader = ({ setIsSignedIn }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const alert = useContext(AlertContext)
  const navigate = useNavigate()
  const currentUser = useRecoilValue<UserType>(currentUserAtom)

  const handleSignoutClick = useCallback(async () => {
    await signOut()
      .then((_response) => {
        navigate("/signin")
        alert?.success("ログアウトしました")
        Cookies.remove("access-token")
        Cookies.remove("client")
        Cookies.remove("uid")
        setIsSignedIn(false)
      })
      .catch((error) => {
        alert?.error(error.response.data.errors.message)
      })
  }, [alert, navigate, setIsSignedIn])

  const handleSuperAdminPageClick = () => {
    window.location.href = `${process.env.REACT_APP_API_HOST_NAME}/admin`
  }

  return (
    <div css={styles.container}>
      <div>
        <img src={BuzzConnelogo} css={styles.logoContainer} alt="logo" />
      </div>
      <ul css={styles.headerListContainer}>
        {GlobalHeaderItems.map((value: GlobalHeaderItem, key: number) => {
          const firstPartOfLink = value.link.split(/[/?]/)[1]
          const isActive = window.location.pathname.includes(firstPartOfLink)
          return (
            <li
              key={key}
              id={isActive ? "active" : ""}
              css={isActive ? activeHeaderListRow : headerListRow}
              onClick={() => {
                window.location.pathname = value.link
              }}
            >
              <div id="title">{value.title}</div>
            </li>
          )
        })}
        {currentUser && (
          <li>
            <Button onClick={handleClick} css={styles.headerMailAddress}>
              {currentUser?.email}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem onClick={handleSignoutClick}>ログアウト</MenuItem>
              {currentUser?.userRole.kind === "super_admin" && (
                <MenuItem onClick={handleSuperAdminPageClick}>
                  管理画面
                </MenuItem>
              )}
            </Menu>
          </li>
        )}
      </ul>
    </div>
  )
}

export default GlobalHeader

const styles = {
  container: css({
    width: "100%",
    height: HEADER_HEIGHT,
    backgroundColor: "var(--color-bg-header)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
    boxSizing: "border-box",
  }),

  logoContainer: css({ width: "125px", height: "36px" }),

  headerListContainer: css({
    listStyle: "none",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-end",
    width: "70%",
  }),

  headerMailAddress: css({
    marginLeft: "20px",
    textTransform: "none",
    color: "var(--color-text-mail-address-header-list)",
    fontSize: "var(--font-size-label-small)",
  }),
}

const headerListRow = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "fit-content",
  color: "var(--color-text-header-list)",
  fontWeight: "var(--font-weight-medium)",
  fontSize: "var(--font-size-label-medium)",
  margin: "0 5px",
  flex: "1 1 auto",
  whiteSpace: "nowrap",
  position: "relative",
  ":hover": {
    cursor: "pointer",
    color: "var(--color-primary)",
    ":after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "3px",
      backgroundColor: "var(--color-primary)",
    },
  },
})

const activeHeaderListRow = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "var(--color-primary)",
  fontWeight: "var(--font-weight-medium)",
  fontSize: "var(--font-size-label-medium)",
  margin: "0 5px",
  flex: "1 1 auto",
  whiteSpace: "nowrap",
  position: "relative",
  ":after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "3px",
    backgroundColor: "var(--color-primary)",
  },
  ":hover": {
    cursor: "pointer",
  },
})
