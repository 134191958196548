import React, { ReactNode, useState } from "react"
import { AlertProviderType } from "@/interfaces"
import { AlertEnum } from "@/utils/helpers/enums"

const AlertContext = React.createContext<AlertProviderType | null>(null)
AlertContext.displayName = "AlertContext"

interface Props {
  children: ReactNode
}

const AlertProvider = ({ children }: Props) => {
  const [alert, setAlert] = useState<string>(AlertEnum.NONE)
  const [alertText, setAlertText] = useState<string | null>(null)

  return (
    <AlertContext.Provider
      value={{
        alert: alert,
        alertText: alertText,
        success: (text: string, timeout?: number) => {
          setAlertText(text)
          setAlert(AlertEnum.SUCCESS)
          setTimeout(
            () => {
              setAlert(AlertEnum.NONE)
            },
            timeout ? timeout * 1000 : 10000
          )
        },
        error: (text: string, timeout?: number) => {
          setAlertText(text)
          setAlert(AlertEnum.ERROR)
          setTimeout(
            () => {
              setAlert(AlertEnum.NONE)
            },
            timeout ? timeout * 1000 : 10000
          )
        },
        clear: () => setAlert(AlertEnum.NONE),
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}

export { AlertProvider }
export default AlertContext
