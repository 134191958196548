import { css, TableCell, TableRow, IconButton } from "@mui/material"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import LlmNoDataImage from "@/images/llm_no_data.png"
import { GenerativeContentType } from "@/types/GenerativeContentType"
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal"

interface Props {
  generativeContent: GenerativeContentType
  onDelete: (id: string) => void
}

const LlmInteractionListItem = ({ generativeContent, onDelete }: Props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const navigate = useNavigate()

  const openDetailPage = () => {
    navigate(`/llm-interaction/${generativeContent.id}`)
  }
  const handleDelete = () => {
    onDelete(generativeContent.id)
  }

  const metaData = useCallback(() => {
    let array = []
    if (generativeContent.gender) {
      array.push(generativeContent.gender)
    }
    if (generativeContent.ageRange) {
      array.push(generativeContent.ageRange)
    }
    if (generativeContent.prefecture) {
      array.push(generativeContent.prefecture)
    }
    if (generativeContent.annualIncome) {
      array.push(generativeContent.annualIncome)
    }

    return array.join("/")
  }, [
    generativeContent.ageRange,
    generativeContent.annualIncome,
    generativeContent.gender,
    generativeContent.prefecture,
  ])

  return (
    <>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        onClick={openDetailPage}
        css={styles.tableRow}
      >
        <TableCell
          align="left"
          padding="none"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 30,
            paddingTop: 6,
            paddingBottom: 6,
          }}
          css={styles.formattedTableCell}
        >
          <img
            src={
              generativeContent.referenceImage
                ? generativeContent.referenceImage
                : LlmNoDataImage
            }
            alt=""
            css={styles.image}
          />
          <span css={styles.title}>{generativeContent.title}</span>
        </TableCell>
        <TableCell
          align="left"
          padding="none"
          style={{ paddingLeft: 30, fontSize: "var(--font-size-body-small)" }}
          css={styles.formattedTableCell}
        >
          {metaData()}
        </TableCell>
        <TableCell
          align="left"
          padding="none"
          style={{ paddingLeft: 30, fontSize: "var(--font-size-body-medium)" }}
          css={styles.formattedTableCell}
        >
          {generativeContent.updatedAt}
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={(event) => {
              event.stopPropagation()
              setIsDeleteModalOpen(true)
            }}
            style={{ marginLeft: "10px" }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}

const styles = {
  tableRow: css({
    cursor: "pointer",
  }),
  formattedTableCell: css({
    whiteSpace: "pre-wrap",
    minWidth: 300,
    height: "76px",
  }),
  title: css({
    fontSize: "var(--font-size-body-large)",
    fontWeight: "var(--font-weight-medium)",
    color: "var(--color-text-black)",
  }),
  image: css({
    flexShrink: 0,
    marginRight: "10px",
    width: "60px",
    height: "60px",
    border: "1px solid #D4DFE6",
    objectFit: "contain",
  }),
}

export default LlmInteractionListItem
