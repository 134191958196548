import React from "react"
import { TableRow, TableCell } from "@mui/material"
import { css } from "@emotion/react"

export const StoryTableHeaderRowComponent = () => (
  <TableRow>
    <TableCell
      align="center"
      padding="none"
      style={{
        width: "11.11%",
        color: "var(--color-border-gray)",
      }}
    />
    <TableCell
      align="center"
      padding="none"
      style={{
        width: "11.11%",
        color: "var(--color-border-gray)",
      }}
      css={css`
        &.MuiTableCell-root {
          border-left: 1px solid rgba(224, 224, 224, 1);
        }
      `}
    >
      投稿日時
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{
        width: "11.11%",
        color: "var(--color-border-gray)",
      }}
      css={css`
        &.MuiTableCell-root {
          border-left: 1px solid rgba(224, 224, 224, 1);
        }
      `}
    >
      投稿文
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{
        width: "11.11%",
        color: "var(--color-border-gray)",
      }}
      css={css`
        &.MuiTableCell-root {
          border-left: 1px solid rgba(224, 224, 224, 1);
        }
      `}
    >
      リーチ
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{
        width: "11.11%",
        color: "var(--color-border-gray)",
      }}
      css={css`
        &.MuiTableCell-root {
          border-left: 1px solid rgba(224, 224, 224, 1);
        }
      `}
    >
      IMP
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{
        width: "11.11%",
        color: "var(--color-border-gray)",
      }}
      css={css`
        &.MuiTableCell-root {
          border-left: 1px solid rgba(224, 224, 224, 1);
        }
      `}
    >
      ENG
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{
        width: "11.11%",
        color: "var(--color-border-gray)",
      }}
      css={css`
        &.MuiTableCell-root {
          border-left: 1px solid rgba(224, 224, 224, 1);
        }
      `}
    >
      既読率
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{
        width: "11.11%",
        color: "var(--color-border-gray)",
      }}
      css={css`
        &.MuiTableCell-root {
          border-left: 1px solid rgba(224, 224, 224, 1);
        }
      `}
    >
      ナビゲーション
    </TableCell>
    <TableCell
      align="center"
      padding="none"
      style={{
        width: "11.11%",
        color: "var(--color-border-gray)",
      }}
      css={css`
        &.MuiTableCell-root {
          border-left: 1px solid rgba(224, 224, 224, 1);
        }
      `}
    >
      PF訪問
    </TableCell>
  </TableRow>
)

export default StoryTableHeaderRowComponent
