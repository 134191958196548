import Tooltip, { TooltipProps } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-end" />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: "#EFF4F6",
    color: "black",
    maxWidth: 450,
    padding: theme.spacing(2),
    border: "1px solid #dadde9",
  },
}))
