import React, { useContext, useState } from "react"
import Cookies from "js-cookie"
import { paths } from "@/utils/paths"
import { css } from "@mui/system"
import { SocialSignInButton } from "@/components/SocialSignInButton"
import { TextField, Button, Box, Typography, Divider } from "@mui/material"
import { signIn } from "@/api/auth"
import { SignInParams } from "interfaces/index"
import AlertContext from "./AlertProvider"
import { useNavigate } from "react-router-dom"
import { PageMainTitle } from "@/components/typography/PageMainTitle"
import { MediumLabel } from "@/components/typography/MediumLabel"

const SignInContainer: React.FC = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const alert = useContext(AlertContext)

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    const params: SignInParams = {
      email: email.replace(/\s/g, ""),
      password: password.replace(/\s/g, ""),
    }

    await signIn(params)
      .then((response) => {
        Cookies.set("access_token", response.headers["access-token"])
        Cookies.set("client", response.headers["client"])
        Cookies.set("uid", response.headers["uid"])
        navigate(paths.afterSignUpAndSignInPath)

        alert?.success("ログインが完了しました")
      })
      .catch((error) => {
        alert?.error(error.response.data.errors.message)
      })
  }

  return (
    <div css={styles.container}>
      <form noValidate autoComplete="off" css={styles.form}>
        <div css={styles.header}>
          <PageMainTitle>ログイン</PageMainTitle>
        </div>
        <div css={styles.formContent}>
          <MediumLabel>メールアドレス</MediumLabel>
          <TextField
            variant="outlined"
            required
            fullWidth
            value={email}
            margin="dense"
            onChange={(event) => setEmail(event.target.value)}
          />
          <MediumLabel>パスワード</MediumLabel>
          <TextField
            variant="outlined"
            type="password"
            required
            fullWidth
            value={password}
            margin="dense"
            autoComplete="current-password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <div css={styles.passwordRules}>
            パスワードは英数字と記号6文字以上が使用できます
          </div>
          <Button
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            disabled={!email || !password ? true : false}
            css={styles.submitBtn}
            onClick={handleSubmit}
            sx={{ backgroundColor: "var(--color-primary)" }}
          >
            ログイン
          </Button>
          <Box textAlign="center" css={styles.box}>
            <Typography variant="body2">
              ご登録がまだの方は
              <a href="/signup" css={styles.link}>
                サインアップ
              </a>
            </Typography>
          </Box>
          <Divider>
            <div css={styles.driverContent}>または</div>
          </Divider>
          <SocialSignInButton provider="facebook" state="signIn"/>
        </div>
      </form>
      <Box css={styles.footer}>
        <Typography variant="body2">
          <a
            href="https://buzzconne.jp/termsofservice"
            target="_blank"
            rel="noopener noreferrer"
          >
            利用規約
          </a>
          {"　/　"}
          <a
            href="https://buzzconne.jp/privacypolicy"
            target="_blank"
            rel="noopener noreferrer"
          >
            プライバシーポリシー
          </a>
          {"　/　"}
          <a
            href="https://buzzconne.jp/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            お問い合わせ
          </a>
          <span>　BuzzConnection Inc.</span>
        </Typography>
      </Box>
    </div>
  )
}

export default SignInContainer

const styles = {
  container: css({
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  }),
  form: css({
    padding: 16,
    maxWidth: 500,
    width: "100%",
    boxSizing: "border-box",
  }),
  submitBtn: css({
    fontSize: 16,
    marginTop: 16,
    flexGrow: 1,
    textTransform: "none",
  }),
  header: css({
    textAlign: "center",
    marginBottom: 14,
  }),
  formContent: css({
    "& *": {
      marginBottom: 7,
    },
  }),
  box: css({
    marginTop: "2rem",
  }),
  passwordRules: css({ color: "#6D777A", fontSize: 12 }),
  link: css({
    textDecoration: "underline",
    color: "var(--color-link)",
    cursor: "pointer",
  }),
  driverContent: css({
    fontSize: 11,
    marginTop: 15,
    color: "#6D777A",
  }),
  socialAuthenticationContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: 20,
  }),
  socialAuthenticationButton: css({
    fontSize: 14,
    color: "#101111",
    fontWeight: "bold",
    border: "1px solid #6D777A",
    flexGrow: 1,
    textTransform: "none",
  }),
  socialLogo: css({ marginBottom: 0 }),
  footer: css({
    width: "100%",
    textAlign: "center",
    color: "#6D777A",
    "& a": {
      color: "#6D777A",
      display: "inline",
      textDecoration: "none",
    },
    position: "relative",
    marginTop: "auto",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  }),
}
