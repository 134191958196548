import { useState } from "react"
import { css, TableRow } from "@mui/material"
import { InstagramAccountMediumType } from "@/types/InstagramAccountMediumType"
import InstagramAccountMediaModal from "@/components/InstagramAccountMediaModal"
import InstagramAccountMediaStoryModal from "@/components/InstagramAccountMediaStoryModal"
import { FeedItemTableCellsComponent } from "@/components/ui/PostList/feeds/FeedItemTableCellsComponent"
import { ReelsItemTableCellsComponent } from "@/components/ui/PostList/reels/ReelsItemTableCellsComponent"
import { StoryItemTableCellsComponent } from "@/components/ui/PostList/stories/StoryItemTableCellsComponent"

interface Props {
  instagramAccountMedia: InstagramAccountMediumType
  isInitialVelocityAnalysis: boolean
  mediaProductType: string
}

const PostListCollectionItem = ({
  instagramAccountMedia,
  isInitialVelocityAnalysis,
  mediaProductType,
}: Props) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <TableRow
        onClick={handleOpen}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        css={styles.tableRow}
      >
        {mediaProductType === "FEED" && (
          <FeedItemTableCellsComponent
            instagramAccountMedia={instagramAccountMedia}
          />
        )}
        {mediaProductType === "REELS" && (
          <ReelsItemTableCellsComponent
            instagramAccountMedia={instagramAccountMedia}
          />
        )}
        {mediaProductType === "STORY" && (
          <StoryItemTableCellsComponent
            instagramAccountMedia={instagramAccountMedia}
          />
        )}
      </TableRow>
      {(mediaProductType === "FEED" || mediaProductType === "REELS") && (
        <InstagramAccountMediaModal
          open={open}
          onClose={handleClose}
          media={instagramAccountMedia}
          isInitialVelocityAnalysis={isInitialVelocityAnalysis}
        />
      )}
      {mediaProductType === "STORY" && (
        <InstagramAccountMediaStoryModal
          open={open}
          onClose={handleClose}
          media={instagramAccountMedia}
          isInitialVelocityAnalysis={isInitialVelocityAnalysis}
        />
      )}
    </>
  )
}

const styles = {
  tableRow: css({
    cursor: "pointer",
  }),
  formattedTableCell: css({
    whiteSpace: "pre-wrap",
    height: "76px",
  }),
  title: css({
    fontSize: "var(--font-size-body-large)",
    fontWeight: "var(--font-weight-medium)",
    color: "var(--color-text-black)",
  }),
  image: css({
    flexShrink: 0,
    marginRight: "10px",
    width: "60px",
    height: "60px",
    border: "1px solid #D4DFE6",
    objectFit: "contain",
  }),
}

export default PostListCollectionItem
