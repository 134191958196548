export const isLoginPage = (pathname: string): boolean => {
  return pathname === "/" || pathname === "/signin"
}

const AUTH_PATH_REGEX = /(login|sign-up|auth-confirmation)/
export const isAuthPath = (pathname: string): boolean => {
  return AUTH_PATH_REGEX.test(pathname)
}

const PUBLIC_PATHS = [
  "/signin",
  "/signup",
  "/join_team",
  "/thanks_for_registration",
  "/auth_confirmation_complete",
  "/admin_signup",
  "/terms_of_service",
  "/prices",
  "/auth_facebook_callback",
]

export const isPublicPath = (pathname: string): boolean => {
  return PUBLIC_PATHS.includes(pathname)
}
