import { css } from "@emotion/react"
import SignInContainer from "@/components/SignInContainer"
import { SignUpAndSignInLeftLogoArea } from "@/components/SignUpAndSignInLeftLogoArea"

const SigninPage = () => {
  return (
    <>
      <div css={styles.container}>
        <div css={styles.leftColumn}>
          <SignUpAndSignInLeftLogoArea />
        </div>
        <div css={styles.rightColumn}>
          <SignInContainer />
        </div>
      </div>
    </>
  )
}

export default SigninPage

const styles = {
  container: css({
    display: "flex",
  }),
  leftColumn: css({
    flex: 1,
  }),
  rightColumn: css({
    flex: 1,
  }),
}
