import React, { useContext, useState } from "react"
import { css } from "@emotion/react"
import { TextField, Button, Grid, Select, FormControl } from "@mui/material"
import { StyledMenuItem } from "@/components/StyledMenuItem"
import { SettingUnselectedInputLabel } from "@/components/SettingUnselectedInputLabel"
import { LargeLabel } from "@/components/typography/LargeLabel"
import { MediumLabelWithRequired } from "@/components/typography/MediumLabelWithRequired"
import { industries } from "@/utils/helpers/industries"
import { occupations } from "@/utils/helpers/occupations"
import AlertContext from "@/components/AlertProvider"
import { useRecoilValue } from "recoil"
import { currentUserAtom } from "@/atoms"
import { UserType } from "@/types/UserType"
import client from "@/api/client"
import { useCurrentUser } from "@/hooks/useCurrentUser"
import { updateProfileApiPath } from "@/utils/paths"

const SettingProfile: React.FC = () => {
  const { reload } = useCurrentUser()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const alert = useContext(AlertContext)
  const currentUser = useRecoilValue<UserType>(currentUserAtom)
  const profileId = currentUser?.id
  const genderList = { male: "男性", female: "女性" }
  const [givenName, setGivenName] = useState<string>(
    currentUser?.givenName || ""
  )
  const [familyName, setFamilyName] = useState<string>(
    currentUser?.familyName || ""
  )
  const [gender, setGender] = useState<string>(currentUser?.gender || "")
  const [tel, setTel] = useState<string>(currentUser?.tel || "")
  const [birthdayDay, setBirthdayDay] = useState<string>(
    currentUser?.birthdayDay || ""
  )
  const [birthdayMonth, setBirthdayMonth] = useState<string>(
    currentUser?.birthdayMonth || ""
  )
  const [birthdayYear, setBirthdayYear] = useState<string>(
    currentUser?.birthdayYear || ""
  )
  const [companyName, setCompanyName] = useState<string>(
    currentUser?.team.companyName || ""
  )
  const [industryId, setIndustryId] = useState<string>(
    currentUser?.team.industryId || ""
  )
  const [occupationId, setOccupationId] = useState<string>(
    currentUser?.team.occupationId || ""
  )

  const days = Array.from({ length: 31 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  )
  const months = Array.from({ length: 12 }, (_, i) =>
    (i + 1).toString().padStart(2, "0")
  )
  const years = Array.from({ length: 100 }, (_, i) =>
    (new Date().getFullYear() - i).toString()
  )

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsSubmitting(true)
    alert?.success(`プロフィールを更新しています。しばらくお待ちください。`)

    const formData = new FormData()

    formData.append("familyName", familyName)
    formData.append("givenName", givenName)
    formData.append("gender", gender)
    formData.append("tel", tel)
    formData.append("birthdayDay", birthdayDay)
    formData.append("birthdayMonth", birthdayMonth)
    formData.append("birthdayYear", birthdayYear)
    formData.append("companyName", companyName)
    formData.append("industryId", industryId)
    formData.append("occupationId", occupationId)

    await client
      .put(updateProfileApiPath(profileId), formData)
      .then((response) => {
        alert?.success(`プロフィールを更新しました。`)
      })
      .catch((error) => {
        alert?.error(`プロフィールの更新に失敗しました。`)
      })

    setIsSubmitting(false)
    reload()
  }

  const handleResetInputField = () => {
    setGivenName(currentUser?.givenName)
    setFamilyName(currentUser?.familyName)
    setGender(currentUser?.gender)
    setTel(currentUser?.tel)
    setBirthdayDay(currentUser?.birthdayDay)
    setBirthdayMonth(currentUser?.birthdayMonth)
    setBirthdayYear(currentUser?.birthdayYear)
    setCompanyName(currentUser?.team.companyName)
    setIndustryId(currentUser?.team.industryId)
    setOccupationId(currentUser?.team.occupationId)
  }

  return (
    <>
      <div css={styles.container}>
        <div css={styles.basicInfo}>
          <div css={styles.largeLabel}>
            <LargeLabel>基本情報</LargeLabel>
          </div>
          <div css={styles.rowInputField}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div css={styles.mediumLabelWithRequired}>
                  <MediumLabelWithRequired>姓</MediumLabelWithRequired>
                </div>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={familyName}
                  placeholder="山田"
                  margin="dense"
                  onChange={(event) => setFamilyName(event.target.value)}
                  css={styles.textField}
                  InputProps={{
                    style: textFieldInputPropsStyle,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <div css={styles.mediumLabelWithRequired}>
                  <MediumLabelWithRequired>名</MediumLabelWithRequired>
                </div>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={givenName}
                  placeholder="太郎"
                  margin="dense"
                  onChange={(event) => setGivenName(event.target.value)}
                  css={styles.textField}
                  InputProps={{
                    style: textFieldInputPropsStyle,
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div css={styles.rowInputField}>
            <div css={styles.mediumLabelWithRequired}>
              <MediumLabelWithRequired>生年月日</MediumLabelWithRequired>
            </div>
          </div>
          <div css={styles.rowInputField}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  {birthdayYear === "" ? (
                    <SettingUnselectedInputLabel>
                      年
                    </SettingUnselectedInputLabel>
                  ) : null}
                  <Select
                    value={birthdayYear}
                    onChange={(event) => {
                      setBirthdayYear(event.target.value)
                    }}
                    css={styles.selectBox}
                  >
                    {years.map((year) => (
                      <StyledMenuItem key={year} value={year}>
                        {year}
                      </StyledMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  {birthdayMonth === "" ? (
                    <SettingUnselectedInputLabel>
                      月
                    </SettingUnselectedInputLabel>
                  ) : null}
                  <Select
                    value={birthdayMonth}
                    onChange={(event) => {
                      setBirthdayMonth(event.target.value)
                    }}
                    css={styles.selectBox}
                  >
                    {months.map((month) => (
                      <StyledMenuItem key={month} value={month}>
                        {month}
                      </StyledMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  {birthdayDay === "" ? (
                    <SettingUnselectedInputLabel>
                      日
                    </SettingUnselectedInputLabel>
                  ) : null}
                  <Select
                    value={birthdayDay}
                    onChange={(event) => {
                      setBirthdayDay(event.target.value)
                    }}
                    css={styles.selectBox}
                  >
                    {days.map((day) => (
                      <StyledMenuItem key={day} value={day}>
                        {day}
                      </StyledMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div css={styles.rowInputField}>
            <div css={styles.mediumLabelWithRequired}>
              <MediumLabelWithRequired>性別</MediumLabelWithRequired>
            </div>
            <FormControl fullWidth>
              {gender === "" ? (
                <SettingUnselectedInputLabel>
                  選択なし
                </SettingUnselectedInputLabel>
              ) : null}
              <Select
                value={gender}
                onChange={(event) => {
                  setGender(event.target.value)
                }}
                css={styles.selectBox}
              >
                {Object.entries(genderList).map(([key, value]) => (
                  <StyledMenuItem key={key} value={key}>
                    {value}
                  </StyledMenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div css={styles.rowInputField}>
            <div css={styles.mediumLabelWithRequired}>
              <MediumLabelWithRequired>電話番号</MediumLabelWithRequired>
            </div>
            <TextField
              size="small"
              variant="outlined"
              required
              fullWidth
              value={tel}
              placeholder="09000000000"
              margin="dense"
              onChange={(event) => setTel(event.target.value)}
              css={styles.textField}
              InputProps={{
                style: textFieldInputPropsStyle,
              }}
            />
          </div>
        </div>
        <div css={styles.detailedInfo}>
          <div css={styles.largeLabel}>
            <LargeLabel>詳細情報</LargeLabel>
          </div>
          <div css={styles.rowInputField}>
            <div css={styles.mediumLabelWithRequired}>
              <MediumLabelWithRequired>会社名</MediumLabelWithRequired>
            </div>
            <TextField
              size="small"
              variant="outlined"
              required
              fullWidth
              value={companyName}
              placeholder="株式会社BuzzConnection"
              margin="dense"
              onChange={(event) => setCompanyName(event.target.value)}
              css={styles.textField}
              InputProps={{
                style: textFieldInputPropsStyle,
              }}
            />
          </div>
          <div css={styles.rowInputField}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div css={styles.mediumLabelWithRequired}>
                  <MediumLabelWithRequired>業種</MediumLabelWithRequired>
                </div>
                <FormControl fullWidth>
                  {industryId === "" ? (
                    <SettingUnselectedInputLabel>
                      業種を選択
                    </SettingUnselectedInputLabel>
                  ) : null}
                  <Select
                    value={industryId}
                    onChange={(event) => {
                      setIndustryId(event.target.value)
                    }}
                    css={styles.selectBox}
                  >
                    {industries.map((industry) => (
                      <StyledMenuItem key={industry.id} value={industry.id}>
                        {industry.name}
                      </StyledMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div css={styles.mediumLabelWithRequired}>
                  <MediumLabelWithRequired>職種</MediumLabelWithRequired>
                </div>
                <FormControl fullWidth>
                  {occupationId === "" ? (
                    <SettingUnselectedInputLabel>
                      職種を選択
                    </SettingUnselectedInputLabel>
                  ) : null}
                  <Select
                    value={occupationId}
                    onChange={(event) => {
                      setOccupationId(event.target.value)
                    }}
                    css={styles.selectBox}
                  >
                    {occupations.map((occupation) => (
                      <StyledMenuItem key={occupation.id} value={occupation.id}>
                        {occupation.name}
                      </StyledMenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </div>
        <div css={styles.buttonsContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                size="large"
                onClick={handleSubmit}
                disabled={isSubmitting}
                fullWidth
                sx={{
                  backgroundColor: "var(--color-primary)",
                  height: "40px",
                }}
              >
                保存
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="outlined"
                size="large"
                onClick={handleResetInputField}
                disabled={isSubmitting}
                fullWidth
                sx={{
                  height: "40px",
                }}
              >
                キャンセル
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

const textFieldInputPropsStyle = {
  height: "43px",
  fontSize: "12px",
}

const styles = {
  container: css({
    width: "40%",
    padding: "20px 30px",
  }),
  selectBox: css({
    height: "43px",
    fontSize: "12px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--color-border-primary)",
    },
  }),
  textField: css({
    marginTop: "0px",
    "& .MuiInputBase-input::placeholder": {
      color: "var(--color-text-placeholder)",
      fontSize: "var(--font-size-label-small)",
      opacity: 1,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--color-border-primary)",
    },
  }),
  largeLabel: css({
    marginBottom: 15,
  }),
  basicInfo: css({
    marginBottom: 20,
  }),
  detailedInfo: css({
    marginBottom: 30,
  }),
  mediumLabelWithRequired: css({
    marginBottom: 3,
  }),
  rowInputField: css({
    marginBottom: 10,
  }),
  buttonsContainer: css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
}

export default SettingProfile
