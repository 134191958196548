import { css } from "@emotion/react"
import { FC } from "react"
import GoogleLogo from "@/images/google_logo.png"
import FacebookLogo from "@/images/facebook_logo.png"

type Props = {
  state: "signIn" | "signUp"
  provider: "google" | "facebook"
}

export const SocialSignInButton: FC<Props> = function ({ provider, state }) {
  const signInOrSignUpText = state === "signIn" ? "サインイン" : "サインアップ"
  const data =
    provider === "google"
      ? {
          href: `${process.env.REACT_APP_API_HOST_NAME}/auth/google`,
          logo: GoogleLogo,
          text: `Googleで${signInOrSignUpText}`,
        }
      : {
          href: `${process.env.REACT_APP_API_HOST_NAME}/auth/facebook`,
          logo: FacebookLogo,
          text: `Facebookで${signInOrSignUpText}`,
        }

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    try {
      window.location.href = data.href
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div css={styles.socialAuthenticationButton} onClick={handleSubmit}>
      <img src={data.logo} alt="my" width={"25px"} css={styles.socialLogo} />
      {data.text}
    </div>
  )
}

const styles = {
  socialAuthenticationButton: css({
    padding: "6px 8px",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    color: "#101111",
    fontWeight: "bold",
    border: "1px solid #6D777A",
    flexGrow: 1,
    textTransform: "none",
  }),
  socialLogo: css({
    margin: "0 8px 0 0",
  }),
}
