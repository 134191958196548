import { css } from "@emotion/react"
import SignUpContainer from "@/components/SignUpContainer"
import { SignUpAndSignInLeftLogoArea } from "@/components/SignUpAndSignInLeftLogoArea"

const SignupPage = () => {
  return (
    <>
      <div css={styles.container}>
        <div css={styles.leftColumn}>
          <SignUpAndSignInLeftLogoArea />
        </div>
        <div css={styles.rightColumn}>
          <SignUpContainer />
        </div>
      </div>
    </>
  )
}

export default SignupPage

const styles = {
  container: css({
    display: "flex",
  }),
  leftColumn: css({
    flex: 1,
  }),
  rightColumn: css({
    flex: 1,
  }),
}
