import { useState } from "react"
import { css } from "@emotion/react"
import { WeeklyHourlyOnlineFollowersDataType } from "@/utils/helpers/dashboard"

interface Props {
  weeklyHourlyOnlineFollowersData: WeeklyHourlyOnlineFollowersDataType
}

export const DashboardHourlyOnlineFollowersGridCells = ({
  weeklyHourlyOnlineFollowersData,
}: Props) => {
  const days_for_label = ["月", "火", "水", "木", "金", "土", "日"]
  const hours = ["0", "6", "12", "18"]
  const days: (keyof WeeklyHourlyOnlineFollowersDataType)[] = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ]

  const maxValue = Math.max(
    ...Object.values(weeklyHourlyOnlineFollowersData).flat()
  )
  const getColorForValue = (value: number) => {
    const percentage = value / maxValue
    if (percentage < 0.2) return "#EFF4F6"
    if (percentage < 0.4) return "#B5DCEA"
    if (percentage < 0.6) return "#49ACD0"
    if (percentage < 0.8) return "#388AA9"
    if (percentage <= 1) return "#094D66"
    return "#EFF4F6"
  }

  const [hoverInfo, setHoverInfo] = useState<string | null>(null)

  const handleMouseEnter = (
    day: keyof WeeklyHourlyOnlineFollowersDataType,
    hour: number,
    value: number
  ) => {
    const newHoverInfo = `${
      days_for_label[days.indexOf(day)]
    } ${hour}時: ${value}人`
    if (hoverInfo !== newHoverInfo) {
      setHoverInfo(newHoverInfo)
    }
  }

  const handleMouseLeave = () => {
    if (hoverInfo) {
      setHoverInfo(null)
    }
  }

  return (
    <>
      <div css={styles.title}>フォロワーの活動時間帯</div>
      <div css={styles.gridContainer}>
        {days_for_label.map((day, index) => (
          <div
            key={day}
            css={styles.dayLabel}
            style={{ gridColumn: index + 2 }}
          >
            {day}
          </div>
        ))}
        {hours.map((hour, index) => (
          <div
            key={hour}
            css={styles.hourLabel}
            style={{ gridRow: index * 6 + 2 }}
          >
            {hour}
          </div>
        ))}
        {days.map((day, dayIndex) =>
          weeklyHourlyOnlineFollowersData[day].map((value, hourIndex) => (
            <div
              key={`${day}-${hourIndex}`}
              css={css(styles.cell, {
                backgroundColor: getColorForValue(value),
              })}
              style={{ gridColumn: dayIndex + 2, gridRow: hourIndex + 2 }}
              onMouseEnter={() => handleMouseEnter(day, hourIndex, value)}
              onMouseLeave={handleMouseLeave}
            />
          ))
        )}
        {hoverInfo && <div css={styles.hoverInfo}>{hoverInfo}</div>}
      </div>
    </>
  )
}

const styles = {
  title: css({
    color: "#6D777A",
    fontSize: "var(--font-size-label-large)",
    fontWeight: "bold",
    textAlign: "center",
  }),
  gridContainer: css({
    display: "grid",
    gridTemplateColumns: "50px repeat(7, 1fr)", // ラベル含めた列数を指定
    gridTemplateRows: "30px repeat(24, 6.5px)", // ラベル含めた行数を指定
    gap: "2px",
  }),
  cell: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ecf0f1",
  }),
  dayLabel: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "var(--font-size-label-small)",
    color: "#6D777A",
  }),
  hourLabel: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "var(--font-size-label-small)",
    color: "#6D777A",
  }),
  hoverInfo: css({
    position: "absolute",
    background: "rgba(0, 0, 0, 0.8)",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "var(--font-size-label-small)",
    padding: 6,
    borderColor: "rgba(0, 0, 0, 0)",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    zIndex: 10,
  }),
}
