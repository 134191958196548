import { css } from "@emotion/react"
import BuzzConnelogo from "@/images/logo.png"

export const SignUpAndSignInLeftLogoArea = () => {
  return (
    <div css={styles.container}>
      <div css={styles.centered}>
        <img src={BuzzConnelogo} css={styles.logoContainer} alt="logo" />
      </div>
    </div>
  )
}

export default SignUpAndSignInLeftLogoArea

const styles = {
  container: css({
    backgroundColor: "var(--color-bg-signin-and-signup-left-area)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  }),
  centered: css({
    textAlign: "center",
  }),
  logoContainer: css({ width: "163px", height: "47px" }),
}
