import React from "react"
import { css } from "@emotion/react"
import { HashtagType } from "@/types/HashtagType"

interface Props {
  hashtags: HashtagType[]
}

export const DashboardHashTags = ({ hashtags }: Props) => {
  return (
    <div css={styles.container}>
      <div css={styles.title}>よく使うハッシュタグ</div>
      <div css={styles.hashtagContainer}>
        {hashtags.map((hashtag, index) => (
          <div key={index} css={styles.hashtag}>
            {hashtag.name}
          </div>
        ))}
      </div>
    </div>
  )
}

const styles = {
  container: css({
    width: "100%",
    height: "100%",
  }),
  hashtagContainer: css({
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    overflowY: "auto",
    maxHeight: "100%",
    paddingBottom: 10,
  }),
  hashtag: css({
    padding: "3px",
    border: "1px solid #3498db",
    borderRadius: "5px",
    backgroundColor: "#fff",
    color: "#3498db",
    textAlign: "center",
    fontSize: "12px",
    fontWeight: "bold",
  }),
  title: css({
    textAlign: "center",
    color: "#6D777A",
    fontSize: "var(--font-size-label-large)",
    fontWeight: "bold",
    marginTop: "-10px",
    paddingBottom: "10px",
  }),
}

export default DashboardHashTags
