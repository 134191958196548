export const downloadImage = async (
  url: string,
  filename: string
): Promise<void> => {
  const response = await fetch(url)
  const blob = await response.blob()
  const blobUrl = URL.createObjectURL(blob)
  const element: HTMLAnchorElement = document.createElement("a")
  element.href = blobUrl
  element.download = filename
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
  URL.revokeObjectURL(blobUrl)
}
