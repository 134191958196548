import {
  Button,
  css,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { useCallback, useContext, useEffect, useState } from "react"
import { ProductType } from "@/types/ProductType"
import { HEADER_HEIGHT } from "@/components/GlobalHeader"
import client from "@/api/client"
import { putProductApiPath } from "@/utils/paths"
import AlertContext from "@/components/AlertProvider"
import { MediumLabel } from "@/components/typography/MediumLabel"
import { MediumLabelWithRequired } from "./typography/MediumLabelWithRequired"
import TextFieldWithCounter from "@/components/TextFieldWithCounter"
import _ from "lodash"
import { useDropzone, FileWithPath } from "react-dropzone"
import SaveStatusLabel from "@/components/SaveStatusLabel"
interface Props {
  product: ProductType
  isOpen: boolean
  onChange: (product: ProductType) => void
  onRequestClose: () => void
}

type ProductPutPropsType = {
  id: string
  name?: string
  description?: string
  new_image_url?: string
  image_url_to_discard?: string
}

export const CompanyDatabaseListItemEditDialog = ({
  product,
  isOpen,
  onChange,
  onRequestClose,
}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const alert = useContext(AlertContext)
  const [productDetail, setProductDetail] = useState<ProductPutPropsType>({
    id: product.id,
  })
  const [imagePreviews, setImagePreviews] = useState(product.productImages)
  const [isInitialRender, setIsInitialRender] = useState(true)
  const [saveStatus, setSaveStatus] = useState("saved")
  const MAX_IMAGE_SIZE = 2 * 1024 * 1024
  const borderColor = "var(--color-border-primary)"

  const handleSubmit = useCallback(async () => {
    setSaveStatus("saving")
    const formData = new FormData()
    formData.append("data", JSON.stringify(productDetail))

    await client
      .put(putProductApiPath(product.id), formData)
      .then((response) => {
        onChange(response.data.product)
        setImagePreviews(response.data.product.productImages)
        setSaveStatus("saved")
      })
      .catch((error) => {
        setSaveStatus("failed")
      })
  }, [product.id, productDetail, onChange])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    _.debounce(() => handleSubmit(), 1000),
    [handleSubmit]
  )

  const handleChange = useCallback(
    (hash: { [key: string]: string | string[] }) => {
      setProductDetail((prevDetails) => {
        const keys = Object.keys(hash)
        if (keys.length !== 1 || keys[0] !== "new_image_url") {
          return {
            ...prevDetails,
            new_image_url: "",
            ...hash,
          }
        }

        return {
          ...prevDetails,
          ...hash,
        }
      })
    },
    [setProductDetail]
  )

  useEffect(() => {
    if (isInitialRender) {
      // 初回のレンダリング時に更新しないようにする処理
      setIsInitialRender(false)
    } else {
      debouncedSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail])

  const materialUiStyles = {
    dialog: {
      sx: {
        position: "fixed",
        top: HEADER_HEIGHT,
        right: 0,
        m: 0,
        width: isMobile ? "90%" : "40%",
        height: `calc(100% - ${HEADER_HEIGHT}px)`,
      },
    },
  }

  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    if (product.productImages.length >= 4) {
      alert?.error("画像は4枚までしか保存できません")
      return
    }
    acceptedFiles.forEach((file: FileWithPath) => {
      if (file.size > MAX_IMAGE_SIZE) {
        alert?.error("画像は2MB以下にしてください")
        return
      }
      const reader = new FileReader()

      reader.onabort = () => console.log("file reading was aborted")
      reader.onerror = () => console.log("file reading has failed")
      reader.onload = () => {
        // Do whatever you want with the file contents
        const base64String = reader.result as string
        handleChange({ new_image_url: base64String })
      }
      reader.readAsDataURL(file)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <Dialog
      open={isOpen}
      onClose={onRequestClose}
      disableScrollLock
      PaperProps={materialUiStyles.dialog}
    >
      <DialogContent>
        <Grid container spacing={3} alignItems="center">
          <Grid item css={styles.inputItem}>
            <div css={styles.saveStatusContainer}>
              <div css={styles.saveStatus}>
                <SaveStatusLabel saveStatus={saveStatus} />
              </div>
              <div css={styles.updatedAtText}>最終保存 {product.updatedAt}</div>
            </div>
            <MediumLabelWithRequired>商品名</MediumLabelWithRequired>
            <TextField
              variant="outlined"
              required
              fullWidth
              defaultValue={product.name}
              placeholder="商品名"
              margin="dense"
              InputProps={{
                style: {
                  fontSize: "var(--font-size-body-small)",
                },
              }}
              css={css`
                & .MuiOutlinedInput-root {
                  fieldset {
                    border-color: ${borderColor};
                  }
                  &:hover fieldset {
                    border-color: ${borderColor};
                  }
                  &.Mui-focused fieldset {
                    border-color: ${borderColor};
                  }
                }
              `}
              onChange={(event) => handleChange({ name: event.target.value })}
            />
          </Grid>
          <Grid item css={styles.inputItem}>
            <MediumLabel>商品説明</MediumLabel>
            <TextFieldWithCounter
              initialValue={product.description || ""}
              onChange={(value) => handleChange({ description: value })}
            />
          </Grid>
          <Grid item css={styles.inputItem}>
            <MediumLabel>商品画像</MediumLabel>
            {imagePreviews.map((imagePreview, index) => (
              <div css={styles.imageContainer} key={imagePreview.imageUrl}>
                <img
                  css={styles.image}
                  className="image"
                  src={imagePreview.imageUrl}
                  alt=""
                />
                <Button
                  css={styles.closeButton}
                  data-close-button
                  onClick={(event) =>
                    handleChange({
                      image_url_to_discard: imagePreview.imageUrl,
                    })
                  }
                >
                  <HighlightOffIcon css={styles.highlightOffIcon} />
                </Button>
              </div>
            ))}
            <div {...getRootProps()} css={styles.uploadBoxStyle}>
              <input {...getInputProps()} />
              <CloudUploadIcon css={styles.cloudUploadIcon} />
              <p css={styles.uploadBoxTextStyle}>
                画像をアップロードしてください
              </p>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

const styles = {
  dialogContentText: css({
    margin: "30px 0",
  }),
  inputItem: css({
    width: "100%",
  }),
  imageContainer: css({
    position: "relative",
    display: "inline-block",
    margin: "10px",
    "&:hover": {
      "& .image": {
        filter: "brightness(0.4)",
      },
      "& [data-close-button]": {
        display: "block",
      },
    },
  }),
  image: css({
    display: "block",
    width: "100px",
    height: "100px",
    border: "1px solid var(--color-border-primary)",
  }),
  closeButton: css({
    position: "absolute",
    top: "0px",
    right: "-10px",
    display: "none",
    justifyContent: "center",
    alignItems: "center",
  }),
  highlightOffIcon: css({
    color: "var(--color-high-light-off-icon)",
  }),
  uploadBoxStyle: css({
    border: "1px dashed var(--color-border-gray)",
    borderRadius: "8px",
    padding: "10px",
    textAlign: "center",
    cursor: "pointer",
  }),
  uploadBoxTextStyle: css({
    marginTop: "8px",
    color: "var(--color-border-gray)",
  }),
  cloudUploadIcon: css({
    color: "var(--color-border-gray)",
  }),
  saveStatusContainer: css({
    display: "flex",
    alignItems: "center",
    gap: "10px",
  }),
  saveStatus: css({
    marginBottom: "10px",
  }),
  updatedAtText: css({
    fontSize: "var(--font-size-body-small)",
    color: "var(--color-border-gray)",
  }),
}
