import { css } from "@emotion/react"
import { PropsWithChildren, ReactNode } from "react"

interface Props {
  children: ReactNode
}

export const XsmallRequiredLabel = ({ children }: PropsWithChildren<Props>) => {
  return <span css={styles.container}>{children}</span>
}

const styles = {
  container: css({
    marginLeft: "8px",
    fontWeight: "bold",
    fontSize: "var(--font-size-label-xsmall)",
    color: "#1C98C5",
  }),
}
