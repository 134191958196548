import { RelayEnvironmentProvider } from "react-relay"
import { RelayEnvironment } from "@/RelayEnvironment"
import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "@/pages/App"
import reportWebVitals from "@/reportWebVitals"
import { BrowserRouter as Router } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <RelayEnvironmentProvider environment={RelayEnvironment}>
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </RelayEnvironmentProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
