import { css } from "@emotion/react"
import SettingContainer from "@/components/SettingContainer"

const SettingPage = () => {
  return (
    <>
      <div css={styles.container}>
        <SettingContainer />
      </div>
    </>
  )
}

export default SettingPage

const styles = {
  container: css({}),
}
